.container-tab-bar-mobile {
	display: flex;
	flex-direction: column;
	padding-top: 65px;
	max-height: calc(100vh - 44px);  
	background-color: #1c1c1c;
	width: 100%;
	z-index: 100;
  }
  
  .tab-bar {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	height: 70px;
	background: #ddd;
	display: flex;
	justify-content: center;
	align-items: center;
  }
  
  .overlay-panel, .overlay-details {
	position: fixed;
	bottom: 70px;
	left: 0;
	right: 0;
	background: #1c1c1c;
	padding: 20px;
	transition: all 0.3s ease-in-out;
	height:100%;
	max-height: 200px;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }
  
  .button-container-tab-panel-mobile {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 29px;
    right: 20px;
	border:0px !important;
}
  
  .content-area {
	overflow-y: auto;
	margin-top: 55px;
	font-family: quicksand !important;
  }
  
  .toggle-button, .ellipsis-button {
	background: none;
	border: none;
	cursor: pointer;
	font-size: 24px;
	z-index: 1050;
  }
  
  .toggle-button {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #4FD975;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1px;
    margin-left: 8px;
}
  
  .ellipsis-button {
	font-size: 24px;

		color: white;
	
  }
  
  .overlay-list {
	transition: all 0.3s ease-in-out;
  }
  
  ul {
	list-style-type: none;
	padding: 0;
  }
  @media (min-width: 769px) {
	.container-tab-bar-mobile{
	  display: none;
	}
  }