/*------------------------- DOM --------------------- */
/* * {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
} */

/* body {
  width: 100%;
}
* {
  box-sizing: border-box;
}

body {
  margin: 0;
} */



#ai-create-credits-feedback {
  display: flex;
  flex-direction: row;
}
#credits-remaining {
  display: -webkit-flex;
  display: flex;
  padding-left: 0px;
  margin-bottom: 0px;
}
#not-working-feedback {
  display: flex;
  flex-direction: row;
  padding-left: 0px;
  position: relative;
  margin-right: 30px;
}
#credits-remaining {
  display: flex;
}
#credits-remaining-wrap {
  display: flex;
  padding-left: 30px;
  bottom: 40px;
  position: relative;
}
#progressBar {
  width: 20rem;
  height: 4rem;
  border: 0.3rem solid #f06263;
  margin-top: 1.5rem;
  background-color: #2d2a2b;
}

#progressBarFull {
  height: 3.4rem;
  background-color: #f06263;
  width: 0%;
}

.select-code-wizard {
  background: #6371f6;
  border-radius: 20px;
  height: 27px;
  text-align: center;
  padding: 0px 10px !important;
  color: #222 !important;
}

.react-tabs__tab {
  color: #f06263;
}

.answer-text {
  color: white;
  font-family: quicksand;
  font-size: 17px;
  /* width: calc(100% - 300px); */
  margin-top: 10px;
  max-width: 397px;
}

.code-snippet {
  color: white;
  background: #222222;
  font-family: quicksand;
  max-width: 100%;
  font-size: 17px;
  width: calc(100% - 42px);
  padding: 20px;
  margin-top: 10px;
  word-break: break-word;
}
#direct-message {
  /* height:100%; */
  /* padding-bottom:155px !important; */
  /* max-height:300px; */
  /* max-height: calc(100vh - 300px); */
  /* height: 59vh; */
}

/* #mobile-details {
  display: none !important;
} */

@font-face {
  font-family: "quicksandbold";
  src: url("/public/fonts/quicksand-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: "quicksandlight";
  src: url("/public/fonts/quicksand-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "quicksandmedium";
  src: url("/public/fonts/quicksand-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "quicksandregular";
  src: url("/public/fonts/quicksand-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: "quicksandbold";
  src: url(/public/fonts/quicksand-bold-webfont.woff);
}
@font-face {
  font-family: "quicksandlight";
  src: url(/public/fonts/quicksand-light-webfont.woff);
}
@font-face {
  font-family: "quicksandmedium";
  src: url(/public/fonts/quicksand-medium-webfont.woff);
}

@font-face {
  font-family: "quicksandregular";
  src: url(/public/fonts/quicksand-regular-webfont.woff);
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: none;
}
button:focus {
  outline: none;
}

html {
  font-family: "quicksandregular" !important;
  font-weight: bold !important;
  font-size: 10px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* overflow: hidden; */
  -webkit-font-smoothing: antialiased;
  /* height: 100vh; */
  background: #323232;
  /* overflow-y: hidden; */
  /* height: -webkit-fill-available !important; */
}

body {
  font-family: "quicksandregular" !important;
  font-weight: bold !important;
  font-size: 10px;
  margin: 0;
  /* background-image: url("/images/Flowroom_Background_Tiled.png"); */
  -webkit-font-smoothing: antialiased;

  /* min-height: 100vh; */
  /* mobile viewport bug fix */
  /* min-height: -webkit-fill-available !important;
  height: -webkit-fill-available !important; */
  /* min-height: 100vh !important;
  /* mobile viewport bug fix */
  /* min-height: -webkit-fill-available !important; */
}

.icon-stats {
  filter: brightness(0) invert(1);
}

.icon-hightlighted {
  filter: invert(98%) sepia(32%) saturate(3719%) hue-rotate(142deg)
    brightness(95%) contrast(116%) !important;
}

.editor-container {
  display: flex;
  height: 100%;
  overflow: hidden;
}
.html-box {
  grid-area: html;
}
.css-box {
  grid-area: css;
}
.js-box {
  grid-area: javascript;
}

.outer {
  display: flex;
  flex-direction: row;
}

.block {
  height: 100%;
  width: 50%; /* 50% would suffice*/
}

.block-1 {
  width: 33%;
  padding-bottom: 44px;
  display: block !important;
  min-width: 148px;
  padding-bottom: 57px !important;
  margin-right: 3px !important;
  position:relative;
  overflow: hidden;
}

.transition {
  transition: 1s ease width;
}

.block-2 {
  flex: 1; /* adjust automatically */
  min-width: 0; /* allow flexing beyond auto width */
  overflow: hidden; /* hide overflow on small width */
  width: 30%;
  padding-bottom: 44px;
  display: block;
  min-width: 148px;
  padding-bottom: 57px !important;
  margin-right: 3px !important;
  position: relative;
}

.block-3 {
  width: 33%;
  padding-bottom: 44px;
  display: block;
  min-width: 148px;
  padding-bottom: 57px !important;
  margin-right: 3px !important;
  overflow: hidden;
  position:relative;
}

.slider-handle {
  line-height: 100%;
  width: 5px;
  background-color: transparent !important;
  border: none;
  cursor: col-resize;
  user-select: none; /* disable selection */
  text-align: center;
}

.slider-handle2 {
  line-height: 100%;
  width: 5px;
  background-color: transparent !important;
  border: none;
  cursor: col-resize;
  user-select: none; /* disable selection */
  text-align: center;
}


.resize-handle {
  position: absolute;
  bottom: 5px;
  width: 20px;
  height: 20px;
  cursor: se-resize; /* Change as needed */
  background: url('/public/images/resize-handle.svg') no-repeat center;
  background-size: cover;
  z-index: 100;
  right:0;
}

.resize-handle2 {
  position: absolute;
  bottom: 5px;
  width: 20px;
  height: 20px;
  cursor: se-resize; /* Change as needed */
  background: url('/public/images/resize-handle.svg') no-repeat center;
  background-size: cover;
  z-index: 100;
  right:0;
}

.resize-handle3 {
  position: absolute;
  bottom: 5px;
  width: 20px;
  height: 20px;
  cursor: se-resize; /* Change as needed */
  background: url('/public/images/resize-handle.svg') no-repeat center;
  background-size: cover;
  z-index: 100;
  right:0;
}

.slider-handle1 { right: 0; }
.slider-handle2 { right: 0; }
.slider-handle3 { right: 0; }


.monaco-editor,
.monaco-editor-background,
.monaco-editor .inputarea.ime-input {
  background-color: #2d2a2b !important;
}

.monaco-editor .margin {
  background-color: transparent !important;
}

.rwt__tabs[data-rwt-vertical="true"] {
  background-color: #1c1c1c !important;
}

#mobile-tab {
  display: none !important;
}

#vertical-tab-post-tab.rwt__tab::after {
  border-right: none !important;
  /* background-color: rgb(23, 23, 23) !important; */
}

#vertical-tab-code-tab.rwt__tab::after {
  border-right: none !important;
}

#vertical-tab-remix-tab.rwt__tab::after {
  border-right: none !important;
}

#vertical-tab-remix {
  height: 100%;
}

#vertical-tab-share-tab.rwt__tab::after {
  border-right: none !important;
}

button {
  cursor: pointer !important;
}

.MuiInputBase-input {
  font-family: "Quicksand" !important;
  font-weight: bolder !important;
}

.mail-notification-dot {
  height: 12px;
  width: 12px;
  background-color: #f06263;
  border-radius: 50%;
  position: absolute;
  color: "white";
  font-family: quicksand;
  justify-content: center;
  align-items: center;
  display: flex;
  top: 0px;
  right: 24px;
  border: 2px solid #3f3f3e;
  font-weight: bold;
}

.notification-bell-dot {
  height: 12px;
  width: 12px;
  background-color: #f06263;
  border-radius: 50%;
  position: absolute;
  color: white;
  font-family: quicksand;
  justify-content: center;
  align-items: center;
  display: flex;
  top: 0px;
  right: 22px;
  border: 2px solid #3f3f3e;
  font-weight: bold;
}

/* textarea:focus,
input:focus {
  outline: none !important;
} */

/* .makeStyles-root-20 {
  background: #222222 !important;
}

.makeStyles-root-18 {
  background: #222222 !important;
} */

.rrs__label {
  background-color: rgb(37, 37, 37) !important;
  font-family: Helvetica, Arial, sans-serif !important;
}
.rrs--options-visible {
  font-size: 14px;
  font-family: Helvetica, Arial, sans-serif !important;
}
.rrs--options-visible .rrs__label,
.rrs__button:focus .rrs__label {
  outline: 0;
  border: 0px solid #999 !important ;
}

.rrs__label {
  color: #a59c9c !important;
  font-size: 14px !important;
}

.rrs__button + .rrs__options {
  border: 0px solid #999 !important;
  border-top: 0px solid #eee !important;
}
.rrs__button {
  background: transparent !important;
  height: 42px !important;
}

.rrs__option.rrs__option--selected {
  color: #0273b5 !important;
  background: blue;
}
.rrs__option.rrs__option--next-selection {
  background: #f1f8fb !important;
  color: #0273b5 !important;
}
.rrs__option.rrs__option--selected {
  color: #0273b5;
  background: rgb(31, 31, 31) !important;
  color: white !important;
}

.rrs__option:focus {
  outline: 0px !important;
  background: red;
}

#rrs-make-menu li {
  background: rgb(31, 31, 31) !important;
  color: #a59c9c !important;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.hide-scroll {
  -ms-overflow-style: none;
}

.MuiLinearProgress-barColorSecondary {
  background-color: rgb(21, 21, 21) !important;
}

.open-boxes {
  height: 300px !important;
  transition: height 0.15s ease-in !important;
  position: relative;
}
/* 
.open-arrows {
  transition: transform 0.15s ease-in !important;
} */

.content {
  height: 100% !important;
  /* min-height: 100% !important; */
  /* min-height: -webkit-fill-available !important; */
}

/* .rwt__tab[aria-selected="true"]:after {
  bottom: 3px !important;
  left: 0;
  width: 100%;
  border-bottom: 2px solid #40ffe8 !important;
} */
.rwt__tablist:not([aria-orientation="vertical"])
  .rwt__tab[aria-selected="true"]:after {
  /* bottom: 3px !important; */
}
.dropdown > [type="checkbox"]:checked + label:after,
.dropdown > ul:target ~ a:after {
  content: "#FFF" !important;
}
.grid {
  margin: 0 auto !important;
}

.masonryContainer {
  width: 80%;
  margin: auto;
  margin-top: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  /* will-change: transform; */
}

.react-grid-item.react-grid-placeholder {
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=");
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}
body {
  /* padding: 20px; */
}
.dragHandle {
}
#content {
  width: 100%;
}
.react-grid-layout {
  background: #eee;
}
.layoutJSON {
  background: #ddd;
  border: 1px solid black;
  margin-top: 10px;
  padding: 10px;
}
.layout {
  height: 100%;
  width: 100%;
  background: radial-gradient(circle, #36383f, #202125);
}
.columns {
  -moz-columns: 120px;
  -webkit-columns: 120px;
  columns: 120px;
}
.react-grid-item {
  box-sizing: border-box;
}
.react-grid-item:not(.react-grid-placeholder) {
  background: #ccc;
  border: 1px solid black;
}
.react-grid-item.resizing {
  opacity: 0.9;
}
.react-grid-item.static {
  background: #cce;
}
.react-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 24px;
}
.react-grid-item .minMax {
  font-size: 12px;
}
.react-grid-item .add {
  cursor: pointer;
}
.react-grid-dragHandleExample {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
li b {
  font-size: 19px;
  line-height: 14px;
}

.roomPostContainer {
  margin: 8px;
  position: relative;
  display: inline-block;
  text-align: center;
  vertical-align: top;
}
#description {
  /* padding-top: 40px !important; */
}

.rwt__tablist[aria-orientation="vertical"] {
  margin-right: 1rem !important;
  width: 78px;
}

.rwt__tab {
  padding: 1rem 1rem !important;
}

.activeClass {
  background: #181818 !important;
}

.rwt__tablist[aria-orientation="vertical"] {
  border-right: 0px solid #ddd !important;
  margin-right: 0rem !important;
}

.snap-container {
  scroll-snap-type: y mandatory;
}

.snap-child {
  scroll-snap-align: start;
}

.content {
  /* overflow-y: scroll; */
}
/*body {*/
/*overflow: auto;*/
/* -webkit-overflow-scrolling: touch; */
/*}*.
canvas {
  border: 0px !important;
}
/* .rwt__tablist:not([aria-orientation="vertical"]), .rwt__tab[aria-selected="true"]:after {

  border-bottom: 0px !important;
} */
.MuiTypography-root,
.MuiCardHeader-title,
.MuiTypography-body2,
.MuiTypography-displayBlock {
  font-size: 17px !important;
  font-weight: bold !important;
  font-family: "Quicksand" !important;
}

/* .MuiSwitch-thumb {
  background-color: rgb(79, 217, 117) !important;
} */
.rwt__tablist:not([aria-orientation="vertical"]) {
  border-bottom: 0px !important;
}

.carousel-item-wrap {
  height: 0px !important;
  width: 327px !important;
  background-color: transparent !important;
  min-height: 0px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
}

.carousel-item-c {
  height: 283px !important;
  width: 327px !important;
  background-color: transparent !important;
  min-height: 0px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
}

.carousel-item-image {
  background-color: #ffffff !important;
  border-radius: 10px !important;
  width: 100% !important;
  /* max-width:287px !important; */
  height: 191px !important;
}

.carousel-item-info {
  display: flex !important ;
  width: 100% !important ;
  display: flex !important;
  flex-direction: column !important;
  margin: 15px 1px;
  pointer-events: none !important;
}

.carousel-item-title {
  color: #ffff !important;
  font-size: 18px !important;
  font-weight: 600 !important;

  margin-bottom: 3px !important;
  text-align: left !important;
}

.carousel-item-description {
  color: #ffff !important;
  font-size: 14px !important;
  font-weight: 500 !important;

  text-align: left !important;
}

.indicators .indicator-item.active {
  background-color: #40ffe8 !important;
  border-radius: 100% !important;
  width: 9px !important;
  height: 9px !important;
}

.indicators .indicator-item {
  background-color: #fff !important;
  border-radius: 100% !important;
  width: 9px !important;
  height: 9px !important;
}

.carousel-item-wrap-mobile {
  height: 0px !important;
  width: 327px !important;
  background-color: transparent !important;
  min-height: 0px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
}

.carousel-item-c-mobile {
  height: 245px !important;
  width: 189px !important;
  background-color: transparent !important;
  min-height: 0px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
}

.carousel-item-image-mobile {
  background-color: #ffffff !important;
  border-radius: 10px !important;
  width: 100% !important;
  /* max-width:287px !important; */
  height: 170px !important;
}

.carousel-item-info-mobile {
  display: flex !important ;
  width: 100% !important ;
  display: flex !important;
  flex-direction: column !important;
  margin: 15px 1px;
  pointer-events: none !important;
  justify-content: center;
  align-items: center;
}

.carousel-item-title-mobile {
  color: #ffff !important;
  font-size: 14px !important;
  font-weight: 600 !important;

  margin-bottom: 3px !important;
  text-align: left !important;
}

.carousel-item-description-mobile {
  color: #ffff !important;
  font-size: 11px !important;
  font-weight: 500 !important;

  text-align: left !important;
}

.indicators .indicator-item.active-mobile {
  background-color: #40ffe8 !important;
  border-radius: 100% !important;
  width: 9px !important;
  height: 9px !important;
}

.indicators .indicator-item-mobile {
  background-color: #fff !important;
  border-radius: 100% !important;
  width: 9px !important;
  height: 9px !important;
}

#create-tags {
  display: flex !important;
}

.elementbg {
  color: rgb(64, 255, 232) !important;
}

.search-placeholder::-webkit-input-placeholder {
  color: rgb(198, 198, 198);
  font-size: 14px !important;
}

.scrollbar {
  background-color: #f5f5f5;
  float: left;
}

.output-container {
  flex-grow: 1;
  position: relative;
  display: flex;
  z-index: 9999;
  padding: 0px 40px 30px;
  background: transparent;
}

/* .main-menu::-webkit-scrollbar {
  background-color: black;
}  */

/* .main-menu::-webkit-scrollbar-thumb {
  background-color: #40ffe8;
  border-radius: 10px;
  width: 3px;
  height: 10px;
} */

/* .main-menu::-webkit-scrollbar-track {
  background-color: rgb(24, 24, 24);
} */
#create-button {
  width: 98px;
  height: 35px;
}

/*-----------------END OF DOM --------------------*/
#infinity-icon {
  border-radius: 50%;
  height: 24px;
  width: 24px;
  margin-left: 0px;
  background-image: url(/public/images/infinity-header.svg);
  background-size: 100%;
  /* border: 1px solid rgb(99, 113, 246); */
  background-repeat: no-repeat;
  background-position: center center;
  display: none;
}
.header-help-icon {
  font-size: 4em;
}
.search-icon-main {
  position: absolute;
  left: 22px;
  bottom: 18px;
}
#notification-main {
  height:calc(100vh - 137px);
    display: flex;
}
#notification-wrap-wrap {
  padding-top: 0px;
  align-items: center;

}


.mobile-tab-menu-container {
  position: relative;
  height: 135px;
  background: rgb(28, 28, 28);
  border-top: 0px solid rgb(57, 57, 57);
  display:none;
}

 #notification-wrap {
  height: 400px;
  }

  


@media only screen and (max-width: 817px) {

  #notification-wrap {
    height: calc(var(--vh, 1vh) * 100 - 140px) !important;
    }
  #notification-main {
    height: calc(var(--vh, 1vh) * 100 - 140px) !important;
  }
  .main-nav {
    display: -webkit-flex;
    display: flex;
    /* position: relative; */
    /* width: calc(100% - 50px) !important; */
    -webkit-justify-content: space-between;
    justify-content: space-between;
    margin-top: 0px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    -webkit-flex-direction: column !important;
    flex-direction: column !important;
    max-width: 785px !important;
  }
  .search-icon-main {
    position: absolute;
    left: 22px;
    bottom: 9px !important;
  }
  .room-content {
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    max-width: 1003px;
    width: 100%;
    padding-top: 8px;
  }
  .room-post {
    width: 100%;
    max-width: 479px !important;
    height: 657px !important;
  }
  /* #credits-remaining {
    display: -webkit-flex;
    display: flex;
    padding-left: 30px;
    margin-bottom: 20px;
  } */
}

@media only screen and (max-width: 838px) {
.room-post {
    width: 100%;
    max-width: 483px !important;
}
}




#ai-create-credits-feedback {
  display: flex;
  flex-direction: row;
}
#not-working-feedback {
  display: flex;
  flex-direction: row !important;
  padding-left: 0px !important;
  position: relative;
}
/* #notification-wrap {
  height: 400px;
} */

#credits-remaining-wrap {
  display: flex;
  padding-left: 30px;
  bottom: 40px;
  position: relative;
  width: 100%;
}
/* 
#main-notification-box {
  height: calc(100vh - 480px);
  min-height: calc(100vh - 480px);
} */

@media only screen and (max-width: 700px) {
  /* .fr-logo {
    display: none !important;
  } */
  .main-nav {
    display: -webkit-flex;
    display: flex;
    /* position: relative; */
    width: 100%;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    margin-bottom: -2px !important;
    padding-left: 0px;
    padding-right: 0px;
  }
  .create-button-header {
    display: none !important;
  }

  .signup-button-wrap {
    margin-left: 8px !important;
    margin-right: 4px !important;
  }
  /* .header {
    height: 45px !important;
  } */
  #flow-wrap- {
    padding-top: 0px !important;
    padding-bottom: 55px !important;
  }

  #preview-top {
    display: none !important;
  }

  #ai-create-credits-feedback {
    display: flex;
  }
  #not-working-feedback {
    display: flex;
    flex-direction: column !important;
    width: 100%;
    padding-left: 0px !important;
    position: relative;
  }

 
  /* #code-wizard-convo {
    max-height: 100% !important;
    height: calc(100vh - 700px) !important;
    margin-bottom: 80px !important;
  } */

  
  #credits-remaining {
    display: -webkit-flex;
    display: flex;
    padding-left: 0px;
  }

  /* #main-notification-box {
    height: calc(100vh - 235px);
  } */
  
}

@media only screen and (max-width: 320px) {
  .masonryContainer {
    width: 288px;
  }

  .roomPostContainer {
    margin-bottom: 20px;
  }

  #fr-logo {
    height: 27px !important;
    width: 118px !important;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 17px;
  }
}

@media (max-width: 991px) {
}

/* @media only screen and (min-width: 321px) and (max-width: 767px) {

  .masonryContainer{
    width: 320px;
  }

  .roomPostContainer {
    margin: 20px 0;
  }

} */

/* @media only screen and (min-width: 768px) and (max-width: 1023px) {

  .masonryContainer{
    width: 722px;
  }

  .roomPostContainer {
    margin: 20px 20px;
  }

}

@media only screen and (min-width: 1024px) {

  .masonryContainer {
    width: 1024px;
  }

  .roomPostContainer {
    margin: 10px 10px;
  }

} */
/* .CodeMirror-sizer {
margin-left: 30px !important;
margin-bottom: 0px !important;
border-right-width: 30px !important;
min-height: 140px !important;
padding-right: 0px !important;
padding-bottom: 0px !important;
font-family:'Source Sans Pro' !important;
font-size: 13px !important;
}

.CodeMirror-linenumber {
  padding: 0 3px 0 5px !important;
  min-width: 20px !important;
  text-align: center !important;
  color: #999 !important;
  white-space: nowrap !important;
}

.CodeMirror-scroll, .CodeMirror-sizer, .CodeMirror-gutter, .CodeMirror-gutters, .CodeMirror-linenumber {
  box-sizing: content-box;
  background-color:white;
} */
#message {

  background-color:#1c1c1c;
  margin-bottom: 10px;
}
#body-padding {
  padding: 0 60px;
}
/* #full_wrap {
  overflow-y: scroll !important;
  position: relative;
} */

@media (max-width: 505px) {
  #body-padding {
    padding: 0 0px !important;
  }
}
/*hack for resizing*/
@media (max-width: 947px) {
  #full_wrap {
    padding-top: 70px !important;
  }
}
/* 
.create-wrap {
  flex-direction: row;
  overflow-y: scroll;
  width: 100%;
} */

/* .create-desktop {
  display: flex;
  flex-direction: row;
}

.create-mobile {
  display: none;
  flex-direction: row;
} */
.create-from-scratch {
  margin-right: 20px;
}

.create-from-template {
  margin-right: 20px;
}

.create-wrap {
  background-color: rgb(34, 34, 34);
  width: 100%;
  max-width: 900px;
  /* justify-content: center; */
  -webkit-align-items: center;
  align-items: center;
  height: calc(100% - 74px);
  margin: auto;
  overflow-y: hidden;
  display: -webkit-flex;
  display: flex;
  top: 70px;
  padding: 35px;
}
/* @media (max-width: 768px) {
  .output-container {
    padding: 0px 0px 0px !important;
  }
} */
#create-btn-mobile-wrap {
  display: none ;
}
#help-circle {

}
#more-mobile {
  display: none;
}




@media (max-width: 769px) {
  /* #flow-wrap- {
    padding-top: 61px;
    padding-bottom: 1px !important;
  } */

  .create-wrap {
    background-color: rgb(34, 34, 34);
    width: 100%;
    max-width: 900px;
    /* justify-content: center; */
    -webkit-align-items: center;
    align-items: center;
    height: calc(100% - 74px);
    margin: auto;
    position: absolute !important;
    overflow-y: scroll !important;
    display: -webkit-flex;
    display: flex;
    top: 70px;
  }

  .create-from-scratch {
    margin-right: 0px !important;
    width: 300px;
  }

  .create-from-template {
    margin-right: 0px !important;
    width: 300px;
  }

  .create-from-community {
    margin-right: 0px !important;
    width: 300px;
  }
  .create-with-ai {
    height: 30px !important;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex !important;
    padding: 10px;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
  }


  #create-btn-mobile-wrap {
    display: block !important;
    margin-top:4px !important; 
  }

  #create-btn-desktop-wrap {
    display: none !important;
  }


}

@media (max-width: 1000px) {
  /* .create-mobile {
    display: flex !important;
    flex-direction: column !important;
  }
  .create-desktop {
    display: none !important;
    flex-direction: row;
  } */
  /* .create-wrap {
    flex-direction: column !important;
    position: absolute;
    height: 100%;
    width: 100%;
  } */
  .create-from-scratch {
    width: 300px;
  }

  .create-from-template {
    width: 300px;
  }

  .create-from-community {
    width: 300px;
  }

  .create-from-scratch {
    margin-left: 0px !important;
  }

  .create-from-template {
    margin-left: 0px !important;
  }

  .create-from-template {
    margin-left: 0px !important;
  }
}

@media (max-width: 769px) {
  .create-wrap {
    flex-direction: column !important;
  }
}

#filtersMenu {
  right: 70px;
}

@media (max-width: 505px) {
  #filtersMenu {
    right: 10px;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a:link {
  color: #80848c;

  text-decoration: none;
}

a:visited {
  color: #80848c;

  text-decoration: none;
}

a:hover {
  color: #80848c;

  text-decoration: none;
}

a:active {
  color: #80848c;

  text-decoration: none;
}

p {
  font-size: 1.6rem;
  line-height: 1.5;
  font-family: "Arial", sans-serif;
}
.hide {
  display: none;
}
h1 {
  font-family: billabong, "billabongregular";
  text-align: center;
  font-weight: 100;
  font-size: 13rem;
  margin: 2rem 0;
  letter-spacing: -1px;
  color: black;
  text-decoration: none;
}
h1 a {
  color: black;
  text-decoration: none;
}
h1 a:focus {
  outline: 0;
}
.dg.main.taller-than-window .close-button {
  border-top: 1px solid #ddd !important;
}
.dg.main .close-button {
  background-color: #ccc !important;
}
.dg.main .close-button:hover {
  background-color: #ddd !important;
}

.dg {
  color: #555;
  text-shadow: none !important;
}
/* .dg.main::-webkit-scrollbar {
  background: #fafafa !important;
}
.dg.main::-webkit-scrollbar-thumb {
  background: #bbb !important;
} */

.dg li:not(.folder) {
  background: #fafafa;
  border-bottom: 1px solid #ddd !important;
}

.dg li.save-row .button {
  text-shadow: none !important;
}

.dg li.title {
  background: #e8e8e8
    url(data:image/gif;base64,R0lGODlhBQAFAJEAAP////Pz8////////yH5BAEAAAIALAAAAAAFAAUAAAIIlI+hKgFxoCgAOw==)
    6px 10px no-repeat !important;
}

.dg .cr.function:hover,
.dg .cr.boolean:hover {
  background: #fff !important;
}

.dg .c input[type="text"] {
  background: #e9e9e9 !important;
}

.dg .c input[type="text"]:hover {
  background: #eee !important;
}

.dg .c input[type="text"]:focus {
  background: #eee !important;
  color: #555 !important;
}

.dg .c .slider {
  background: #e9e9e9 !important;
}

.dg .c .slider:hover {
  background: #eee !important;
}

/* #root {
  background: transparent !important;
} */

.main-nav {
  display: flex;
  /* position: relative; */
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
    max-width: 1015px;
    margin: auto;
    margin-bottom: 20px;

}

.main-nav ul {
  /* list-style-type: none; */
  display: inline-block;
  display: flex;
  display: flex;
  justify-content: space-between;
  width: 220px;
}

.room-filter-down-arrow {
}

.main-nav ul li {
  text-decoration: none;
  color: "#80848C";
}

.pagination-buttons-wrap {
  display: flex;
  justify-content: space-between;
  width: 350px;
  margin: 20px auto;
  padding: 0px 50px;
  width: 397px;
}

.pagination-button {
  position: relative;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: "Source Sans Pro";
  border: 1px solid rgb(221, 224, 235);
  height: 37px;
  width: 135px;
  font-weight: 600;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  text-decoration: none;
}

.message-container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Adjust this value based on your actual layout requirements */
  justify-content: space-between;
}


.message-wrap-wrap {
  flex: 1;
  overflow-y: auto; /* Allows scrolling */
  padding-top: 48px; /* Adjust based on your header size */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Aligns content to the top */
}


#header-user-circle {
  display: none !important;
}

#create-box {
  top: 70px !important;
}

.jiaktl {
  background-color: transparent !important;
  color: white !important;
}
.isnPmm {
  background-color: transparent !important;
  color: transparent !important;
}

.title {
  font-family: "Carter One";
}
/* .container {
  margin-top: 50px;
  text-align: center;
} */

.title {
  font-family: "Carter One";
}

/* .container {
  margin-top: 50px;
  text-align: center;
} */

.image-grid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: minmax(50px, auto);
}

.image-grid .image-item:nth-child(5n) {
  grid-column-end: span 2;
}

.image-grid img {
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body {
}
.main {
  padding: 0px 0px;
}
.top-boxes {
  padding: 5px 40px;
}

#preview-bar {
  display: none;
}

@media (max-width: 505px) {
  .main {
    padding: 0px 0px;
  }
}


.content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;

}

.header {
  align-items: center;
  display: flex;
  background-color: #1c1c1c !important;
  height: 64px;
  position: relative;
  left: 0;
  border-bottom: 0.1px solid #323232;
}

.header-profile-pic {
  border-radius: 40px;
  background-color: black;
  height: 32px;
}

.menu-s {
  color: #44ffe8 !important;
  font-weight: 800;
  font-size: 20px;
}

.tui-image-editor-container .tui-image-editor {
  width: 100% !important;
  height: 100% !important;
  top: 0px !important;
}

.message-icon-profile-pic-wrap-mobile {
  display: none;
  /* margin-left: 14px; */
}

#logged-in-profile {
  height: 100%;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message-icon-profile-pic-wrap {
  display: flex;
  /* margin-left: 14px; */
}
#create-btn-wrap-left {
  display: block;
  left: 31px !important;
  top: 1px !important;
  position: relative;
}
#create-btn-wrap-right {
  display: block !important;
  margin-right: 30px;
}
@media (max-width: 830px) {
  .message-icon-profile-pic-wrap-mobile {
    display: flex;
    /* margin-left: 14px; */
  }

  .message-icon-profile-pic-wrap {
    display: flex;
    /* margin-left: 14px; */
  }
}

@media (min-width: 400px) {
  .message-icon-profile-pic-wrap {
    display: -webkit-flex;

    display: flex;

    /* margin-left: 14px; */

    padding: relative;

    float: right;

    align-items: flex-end;

    right: 0px;
  }
}

.content-container {
  margin: 0 auto;
  padding: 7px;
  /* max-width: 111rem ; */
  width: 100%;
  border-bottom: 0px solid #323232;
}

.description-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 12px 15px 0 15px;
  background: transparent;
  display: none;
  cursor: pointer;
  z-index: 100;
  color: white;
}

.forgot-password {
  position: relative;
  font-size: 11px;
  margin-left: 100px;
  bottom: 10px;
}

.email-address-label {
  display: none;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 6px;
}

.password-label {
  display: block;

  font-size: 14px;
  margin-bottom: 6px;
}

.forgot-button {
  flex: 1;
  height: 38px;
  border-radius: 0px 6px 6px 0px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
  display: none;
  border-top: 1px solid #dde0eb;
  border-bottom: 1px solid #dde0eb;
  border-right: 1px solid #dde0eb;
}

.create-hide {
  position: absolute;
  display: none !important;
}

.create-show {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  height: 200px;
  width: 250px;
  display: flex !important;
  background-color: white;
  z-index: 9999999;
  /* bottom: 10px; */
  border: 1px solid rgb(221, 224, 235);
  top: 150px;
  flex-direction: column;
}
.dg,
.ac {
  display: none;
}
.dg .cr.string {
  border-left: 0px !important;
  height: 70px;
  border-bottom: 0px !important;
}

.dg .cr.string > div:nth-child(1) {
  display: flex;
  flex-direction: column;
}

.dg .c {
  float: left;
  width: 100% !important;
  position: relative;
}

.dg,
.c > input[type="text"]:nth-child(1) {
  border-radius: 4px !important;
  background-color: #252525 !important;
  height: 25px !important;
  color: white !important;
}

.search-box-wrap-header {
  width: 100% !important;
}

#search-field-wrap {
  height: 50px;
  display: none !important;
  padding-top: 10px !important;
  align-items: center;
  justify-content: center;
}

.ReactTags__tagInputField::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #a59c9c;
  font-size: 14px;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #474747;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #474747;
  font-size: 30px;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #474747;
  font-size: 30px;
}

.description-textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #a59c9c;
  font-size: 15px;
}

.title-area::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #a59c9c !important;
  font-size: 15px;
}

.selected {
  display: flex !important;
  width: "100%";
}

.not-selected-dropdown {
  /* display: none; */
}

.selectedRM {
  border-bottom: 1px solid;
  padding-bottom: 1px;
}

.notSelectedRM {
  color: #a59c9c;
}

.selectO {
  color: rgb(54, 255, 233) !important;
}

.fa-search {
  margin: 10px;
}

.react-grid-item.react-grid-placeholder {
  background: #40ffe8 !important;
  opacity: 0.3 !important;
}

#mobile-user-info-wrap {
  display: none;
}



@media (max-width: 472px) {
  .search-box {
    width: 100% !important;
  }

  .sign-up-log-in-wrap {
  }

  .fa-bars {
    display: block !important;
  }
}

#fa-search {
  display: none;
}

.sign-up-log-in-wrap {
  display: block;
}

@media (max-width: 698px) {
  #header-user-circle {
    display: block !important;
  }

  .logo-wrap {
    left: 7px !important;
  }

  #fr-logo {
    height: 27px !important;
    width: 118px !important;
  }

  .fa-bars {
    display: block !important;
  }

  #fa-search {
    display: block;
  }

  .search-box-wrap-header {
    display: none;
  }

  .sign-up-log-in-wrap {
  }

  #create-btn-wrap-left {
    display: none !important;
  }

  #create-btn-wrap-right {
    display: block !important;
  }

  #search-field-wrap {
    height: 50px;
    display: none !important;
    padding-top: 0px;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 8px;
  }

  .header-content {
    display: none;
    justify-content: space-between;
    padding: 0px 0px !important;
    width: 100%;
    align-items: center !important;
  }

  .search-box {
    margin: 0px 0px !important;
    width: 100%;
    height: 42px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    background-color: #202020;
    border: 0;
    outline: 0;
    color: #474747;
    font-family: ArialMT;
    font-size: 15px;
    font-weight: 400;
    line-height: 29px;
    text-align: left;
  }

  .header {
    padding: 0px 0px;
  }

  #create-box {
    top: 70px !important;
  }

  .page-wrap {
    padding: 0px 5px 50px 5px;
  }

  #mobile-user-info-wrap {
    height: 55px;
    width: 100%;
    position: absolute;
    top: 70px;
    background: #252525;
    padding: 5px;
    left: 0px;
  }

  #mobile-user-info {
    height: 100%;
    width: 100%;
    background: rgb(15, 15, 15);
    border-radius: 5px;
  }

  #mobile-tab-bar {
    display: flex !important;
  }

  #mobile-user-info-wrap {
    display: flex;
  }

  #create-button {
    width: 95px !important;
    height: 26px !important;
    margin-right: 20px;
  }

  #chooseTemplate {
    color: white !important;
    font-size: 17px !important;
    font-weight: bold !important;
    margin-top: 20px !important;
    margin-left: 16px !important;
  }

  #coders {
    margin-left: 16px !important;
    margin-top: 2px !important;
    font-size: 14px !important;
  }

  #namelbl-drop-arrow {
    display: none !important;
  }
}

#search-field-wrap {
  height: 50px;
  display: none;
}

#logged-in-profile-wrap {
  margin-right: 92px !important;
}

.rwt__tab {
  padding: 0.35rem 1rem !important;
}
.chev-down {
  display: none !important;
}

.top-box-wrap {
  display: flex;
  flex-direction: "column";
  /* width: "100%"; */
  padding-top: 0px;
}

.top-boxes {
  padding: 5px 40px;
}

.output_frame {
  border: 0;
  background: #3e3e3e !important;
  position: absolute;
  left: 0;
  z-index: 1;
  margin: auto;
  border-radius: 0px;
}

.decorationsOverviewRuler {
  width: 0px !important;
  height: 0px !important;
}

.minimap-decorations-layer {
  width: 0px !important;
}

.invisible .scrollbar .vertical .fade {
}

#menu-wrap-mobile {
  display: none;
}

.flow-wrap-wrap {
  /* padding-top: 62px !important; */
  padding-bottom: 55px !important;
}

.device-compatible-room {
  border-radius: 17px;
}
.browser-compatible-room {
  border-radius: 17px;
}

@media (max-width: 900px) {

  .rwt__tabs vertical-tabs {
    z-index: 10000 !important;
  }
  .remix-menu {
    z-index: 10000 !important;
  }
}

@media (max-width: 915px) {
  /* .top-boxes {
    visibility: hidden !important;
  } */
}

@media (max-width: 768px) {
  .device-compatible-room {
    border-radius: 0px !important;
  }
  .browser-compatible-room {
    border-radius: 0px !important;
  }
  .flow-wrap-wrap {
    padding-bottom: 0px !important;
  }
  #menu-wrap-mobile {
    display: flex !important;
  }

  /* .top-boxes {
    display: none !important;
  } */
  .output_frame {
    border-radius: 0px !important;
    padding-bottom: 0px !important;
  }
  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .editor {
    position:fixed;
  }

  /* .top-boxes {
    padding: 0px 40px !important;
  } */

  /* .block-2 {
    display: none;
  }
  .block-3 {
    display: none;
  } */
  .chev-down {
    display: none !important;
  }

  #mobile-menu-wrap {
    bottom: 177px !important;
  }

  /* #remix-desktop {
    display: none !important;
  } */

  /* #remix-desktop {
    display: none !important;
  } */

  #logged-in-profile {
  }

  #logged-in-profile-wrap {
    margin-right: 0px !important;
  }

  /* .output-container {
    flex-grow: 1;
    position: relative;
    display: flex;
    z-index: 1000 !important;
    padding: 0px 0px 0px !important;
  } */

  /* .block {
    position: absolute !important;
    width: 100% !important;
  } */

  .top-boxes {
    padding: 5px 0px !important;
  }

  /* .publish-wrap-wrap {
    height: calc(100vh - 91px) !important;
  } */
  .mobile-tab-menu-container {
    position: relative;
    height: 135px;
    background: rgb(28, 28, 28);
    border-top: 0px solid rgb(57, 57, 57);
    display:block !important;
  }

  #preview-bar {
      display: none !important;
  }
}
/* 
.menu-wrapper--inner {
  transform: translate3d(0px, 0px) !important;
} */
#top-section-of-messages {
  display: flex;
  height: 59px;
  background: rgb(28, 28, 28);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom:2px solid rgb(62, 62, 62);
}

/* .message-wrap {
  max-height: 100%;
  background-color: rgb(28, 28, 28);
  border-radius: 20px;
} */
#msg-lbl {
  display: flex;
}

.other-profile-pic {
  height: 34px;
  width: 34px;
  /* position: absolute; */
  /* right: 123px; */
  top: 10px;
  /* overflow: hidden; */
  border-radius: 50%;
  margin-right:7px;
}

#msg-user-name {
  position: absolute;
  display: flex;
  flex-direction: row;
}

#msg-send {
  display: block;
}

#msg-send {
  width: 100%;
}

#message {
  position: relative;
  margin-right: 14px;
  height: 100px;
}

#msg-send-btn {
  width: 100%;
  margin-bottom: 15px;
  height: 40px;
}

.message-wrap-wrap {
  padding-left: 20px !important;
  padding-right: 20px !important;
  
}
#add-collaborator-btn {
  margin-left: 15px;
}

#username-card {
  padding-bottom: 17px;
}
#message {
  background-color:#323232;
}

#msg-background {
  background-color:#1C1C1C;
}

.container {
  display: flex;
  flex-direction: column;
  padding-top:0px;
  max-height: 500px;
  height:100%;
  background: #1c1c1c;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: rgb(0, 0, 0) 0px 0px 27px -7px;
  width:calc(100% - 30px);
}

#top-msg-section {
  display:none;
}

#msg-lbl {
  width:calc(100% - 100px);
}
.bottom-section {
  height: auto; /* Adjust based on your input area's height */
  background-color: #1c1c1c; /* Just for visibility */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right:10px;
}

#msg-wrap {
  background-color:#323232;

}
.down-arrow-wrap {
  width:100%; 
  display:flex;
  align-items:center;
  justify-content:center;
  height:50px;
  bottom:200px;
  position:absolute;
}
.down-msg-arrow {
  position: absolute;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #1c1c1c;
  color: white;
  border: none;
  border: 1px solid rgba(255, 255, 255, 0.5); /* White with 50% opacity */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}


#msg-wrap-wrap {
  background-color:#323232;
  color: #FEFFFE;
}

#msg-wrap-wrap-margin {
  margin-left:43px;
}

@media (max-width: 570px) {
  #msg-wrap-wrap {
    background-color:#4FD975 !important;
    color: #1c1c1c !important;
  }

  #msg-wrap-wrap-margin {
    margin-left:0px !important;
  }
  
  .header-icons {
    width: 100% !important;
  }

  #top-msg-section {
    display: flex !important;
  }

  #msg-lbl {
    width:auto !important;
  }
  .bottom-section {
    height: 100px !important; /* Adjust based on your input area's height */
    background-color: #323232; /* Just for visibility */
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right:10px;
  }
  
  
  
  .right-section {
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    display: flex;
    width: 100% !important;
    padding-right: 15px !important;
  }
  #infinity-icon {
    display: block !important;
    height: 24px !important;
    width: 24px !important;
  }
  /* .message-wrap {
    max-height: 100% !important;
    background-color: #323232 !important;
    border-radius: 0px !important;
  } */

  #msg-lbl {
    display: none !important;
  }
  #top-section-of-messages {
    background-color: #323232 !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;

  }
  .other-profile-pic {
    left: 150px !important;
    right: 0px !important;
  }
  #msg-user-name {
    position: absolute;
    left: 59px !important;
    right: 0px !important;
    display: flex;
    flex-direction: row !important;
  }
  #msg-send-btn {
    display: flex !important;
    width: 105px !important;
    height: 50px !important;
  }
  #msg-send {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  #msg-wrap {
    background-color:rgb(79, 217, 117) !important;

  }
  #message {
    position: relative !important;
    margin-right: 14px !important;
    height: 50px !important;
    bottom:0px !important;
    background-color:#323232 !important;

  }
  #msg-background {
    background-color:#323232 !important;
  }
  
  .message-wrap-wrap {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  #add-collaborator-btn {
    margin-left: 14px;
  }
  #direct-message {
    /* height: -webkit-fill-available !important; */
    /* padding-bottom:155px !important; */
    /* height: -webkit-fill-available !important; */
    /* height: calc(var(--vh, 1vh) * 100); */
    /* position: absolute; */

  }

  #username-card {
    padding-bottom: 0px !important;
  }
  #name-user-name-fullname {
    margin-bottom: 5px !important;
  }
  #message {
    background-color:#1C1C1C !important;
  }

/* .container {
  display: flex;
  flex-direction: column;
  padding-top:65px !important;
  max-height: calc(var(--vh, 1vh) * 100) !important;
  background-color: #323232 !important;
  width:calc(100% - 0px) !important;
} */

.container {
  padding-top: 65px !important;
  max-height: calc(var(--vh, 1vh) * 100) !important;
  background-color: #323232 !important;
  width: 100% !important; /* Ensures it takes full width */
  overflow-y: auto; /* Ensures scrolling is enabled */
  border-radius: 0px !important;
}

.down-arrow-wrap {
  width:100%; 
  display:flex;
  align-items:center;
  justify-content:center;
  height:50px;
  bottom:100px;
  position:absolute;
  z-index: 1000;
}

.down-msg-arrow {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #1c1c1c;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

  
}

.right-section {
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-around;
  display: flex;
  padding-right: 0px !important;
  width: 311px;
}

.firebaseui-idp-text-long {
  font-weight: 800px !important;
}
.rwt__tablist[aria-orientation="vertical"]
  .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
}

#name-user-name {
  display: none !important;
  flex-direction: column;
  margin-left: 35px;
}

#username-card {
  padding-bottom: 17px;
}

@media (max-width: 570px) {
  /* .profile-wrap {
    padding: 30px 10px !important;
  } */
  #profile-card {
    display: flex;
    flex-direction: column !important;
    padding-bottom: 44px;
  }

  #name-user-name {
    display: flex !important;
    flex-direction: column;
    margin-left: 0px !important;
  }

  #username-card {
    padding-bottom: 0px !important;
  }

  #name-user-name-card {
    display: none !important;
    flex-direction: column;
    margin-left: 35px;
    margin-bottom: 0px !important;
  }
  #name-user-name-card-wrapper {
    margin-bottom: 0px !important;
  }
  #name-user {
    display: flex !important;
    justify-content: center !important;
    margin-top: 18px !important;
    align-items: center !important;
    flex-direction: column !important;
  }
  #name-card-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px !important;
    padding: 0px 40px !important;
    left: 0px;
    position: relative;
  }
  #profile-pic {
    width: 100px;
    height: 100px;
    background-size: cover;
    background-position: center center;
    border: 0px solid rgb(79, 217, 117);
    left:0px !important;
    top: 0px !important;
    border-radius: 59px;
    background-color: black;
    position: relative;
    margin-bottom: 10px;
  }
  #followers-and-likes-desktop {
    display: none !important;
  }
  #followers-and-likes-mobile {
    display: flex !important;
    position: relative !important;
    top: 15px !important;
    margin: auto !important;
  }
  #flow-wrap {
    padding: 0px 5px !important;
  }

  #favorites-wrap {
    padding: 0 10px !important;
  }

  .other-profile-pic {
    left: 32px !important;
    right: 0px !important;
    top: 17px !important;
  }
  #msg-user-name {
    position: absolute;
    /* left: 80px !important; */
    right: 0px !important;
  }
}

#profile-pic {
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center center;
  border: 0px solid rgb(79, 217, 117);
  left: 25px;
  top: 33px;
  border-radius: 59px;
  background-color: black;
  position: relative;
}
#followers-and-likes-desktop {
  display: flex;
}
#followers-and-likes-mobile {
  display: none;
}

#name-user-name-fullname {

}

/* #profile-card {
  background: #3f3f3e;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  padding: 14px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  color: #717790;
  position: relative;
  width: 100%;
  height: 152px;
  max-width: 232px;
  z-index: 1;
  border-radius: 20px;
} */

@media (max-width: 768px) {
  #vertical-tab-images {
    border-bottom: 0px solid #40ffe8 !important;
  }
}

#mobile-menu-wrap {
  bottom: 0px !important;
}

@media (max-width: 375px) {
  #login-btn {
    width: 70px !important;
  }
  #sign-up-btn {
    width: 70px !important;
  }
}

@media (max-width: 830px) {
  .large-logo-desktop {
    display: block;
    font: 0/0 a;
    /* background: url(/images/logo.svg) no-repeat 0px 0px; */
    background-size: 126px 29px !important;
    width: 125px !important;
    height: 30px !important;
  }

  .login-section-fields {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .login-section-input-field {
    margin-right: 0px !important;
    width: 100%;
    flex: 3 1 0% !important;
  }
  .forgot-password {
    display: none;
  }

  .email-address-label {
    display: block;
  }
  .password-label {
    display: block;
  }
  .forgot-button {
    display: flex;
  }

  .login-section-input-field-password {
    margin-right: 0px !important;
    width: 100%;
    border-radius: 3px !important;
    -ms-flex: 3 1 !important;
    flex: 3 1 !important;
  }
}

.header-content {
  display: flex;
  justify-content: space-between;
  padding-right: 0px;
}

.meta-content-container {
  margin: 0 auto;
  padding: 0 20px;
  max-width: 111rem;
  width: 100%;
}
.meta-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px;
}

.header-mobile {
  display: -ms-flexbox;
  display: flex;
  height: 118px;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid rgb(221, 224, 235);
}

.header-content-mobile {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 100%;
  -ms-flex-pack: center;
  width: 100%;
  overflow: hidden;
  -ms-flex-align: center;
  align-items: center;
}

.footer {
  display: flex;
  height: 42px !important;
  border-bottom: 0px solid black;
  justify-content: center;
  align-items: center;
  z-index: 999999;
}

.footer-content {
  display: flex;
  align-items: center;
  padding: 0px 50px;
}

/* .footer-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    height: 77px;
    -ms-flex-pack: center;
    width: 100%;
    overflow: hidden;
    padding: 26px 40px;
    align-items: center;
   } */

.footer-links-content {
  display: flex;
}
.footer-link {
  font-size: 12px;
  color: #80848c;
  font-family: "Source Sans Pro";
  margin-right: 10px;
}
.footer-links-left {
  display: flex;
  align-items: flex-start;
  margin-left: 24px;
}

.footer-links-right {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 80px;
}

.footer-links {
  display: flex;
  flex-direction: row;
}

.left-section {
  -ms-flex-align: center;
  align-items: center;
  display: flex;
}

.top-section {
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  height: 70px;
  z-index: 1;
}

.middle-section {
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
}

/* .right-section {
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 157px;
  padding-right: 15px;
} */

.right-section-tablet {
  display: -ms-flexbox;
  display: none;
  -ms-flex-align: center;
  align-items: center;
  border: 0px solid black;
  -ms-flex: 2 1;
}
.new-button-wrap {
  margin-right: 16px;
}
.new-button-dropdown {
  position: relative;
  display: inline-block;
  border-top-left-radius: 21px;
  border-top-right-radius: 21px;
  background-color: transparent;
}
.new-button-dropdown-content {
  display: none;
  position: absolute;
  min-width: 99px;
  height: 51.3px;
  z-index: 1;
  background-color: #0a7f29;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  padding-top: 3.5px;
  padding-left: 1px;
  padding-right: 1px;
}

.new-btn {
  display: flex;
  align-items: center;
}

.signup-button-wrap {
  margin-left: 16px;
  margin-right: 4px;
}

.signup-button {
  display: block;
  outline: none;
  cursor: pointer;
  border: 1px solid rgb(10, 127, 41);
  border-radius: 40px;
  height: 32px;
  width: 82px;
  background-color: rgb(27, 178, 67);
  font-family: "Source Sans Pro";
  color: white;
  font-size: 14px;
  font-weight: 600;
  position: relative;
}

.login-button-wrap {
  margin-right: 0px;
}

.login-button {
  display: block;
  outline: none;
  cursor: pointer;
  border: 1px solid rgb(10, 127, 41);
  border-radius: 40px;
  height: 32px;
  width: 82px;
  background-color: rgb(27, 178, 67);
  font-family: "Source Sans Pro";
  color: white;
  font-size: 14px;
  font-weight: 600;
  position: relative;
}

.hoverdrop {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-left: 7px;
}

.drop-menu-wrap {
  padding-left: 1px;
  padding-right: 1px;
  /* margin-top: 10px; */
  height: 23px;
  align-items: center;
  display: flex;
}

.drop-menu-newroom {
  font-family: "Source Sans Pro";
  font-size: 12px;
  color: rgb(255, 255, 255);
  font-weight: 600;
}

.drop-menu-newroom :hover {
  background-color: #03b049 !important;
}

.drop-menu-community {
  text-decoration: none;
  font-weight: 600;
  color: rgb(255, 255, 255);
  margin-bottom: 0.2px;
}

.search-box {
  /* max-width: 550px; */
  margin: 0px 15px;
  width: 100%;
  height: 42px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  /* padding-left: 15px;
  margin-left: 16px; */
  background-color: #202020;
  border: 0;
  /* border-radius: 3px; */
  /* color: #262626; */
  outline: 0;
  /* z-index: 2; */
  color: #474747;
  font-family: ArialMT;
  font-size: 15px;
  font-weight: 400;
  line-height: 29px;
  text-align: left;
}

.selected_flow {
  border: 2px dashed rgb(64, 255, 232) !important;
}

.bound {
  height: 400px;
  width: 400px;
}

.MuiInputBase-input {
  font-size: 14px !important;
  color: white !important;
}

.MuiOutlinedInput-multiline {
  padding: 0px 0px !important;
}

.MuiAlert-filledSuccess {
  color: #000 !important;
  font-weight: 500 !important;
  background-color: #6268f5 !important;
  font-size: 14px !important;
}

.remix-text[contenteditable="true"]:hover {
  outline: 0px dashed #40ffe8 !important;
}

.remix-text[contenteditable="true"] {
  outline: 0px dashed #40ffe8 !important;
}

.MuiOutlinedInput-input {
  padding: 10px 10px !important;
}

.MuiOutlinedInput-input #outlined-error-helper-text {
  padding: 20px 10px !important;
}

/* .MuiInputLabel-outlined {
  transform: translate(14px, 14px) scale(1) !important;
} */

.MuiFormLabel-root.Mui-error {
  padding: 3px 10px !important;
}

.CodeMirror-scroll {
  padding-bottom: 10px !important;
}

@media (min-width: 600px) {
  .MuiMenuItem-root {
    min-height: 48px !important;
  }
}

/* .rwt__tab[aria-selected="true"] {
  position: relative;
  background-color: rgb(23, 23, 23);
} */
@media (max-width: 997px) {
  /* .room-post {
    width: inherit !important;
  } */
}

/* .rwt__tab[aria-selected="true"]:after {
  border-bottom: 3px solid #40ffe8 !important;
} */
/* @media only screen and (max-width: 1023px) and (min-width: 768px) {
  .main-nav {
    display:flex;
    position: relative;
    width: 677px !important;
    margin:auto;
  }
  .roomPostContainer {
    margin: 20px 31px !important;
  }


} */

.-tablet {
  background-color: #f9fafa;
  border: 1px solid #dedfe1;
  border-radius: 40px;
  height: 32px;
  width: 34px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 15px;
  margin-right: 18px;
  margin-left: 16px;
  -ms-flex: 1 1 103px;
  flex: 0 0 10px;
}
.search-input {
  background-color: #202020;
  border: 0;
  border-radius: 3px;
  color: #262626;
  outline: 0;
  z-index: 2;
  width: 100%;
  font-size: 14px;
  padding-right: 12px;
}

.message-icon {
  border: 1px solid #40ffe8;
  height: 32px;
  width: 32px;
  border-radius: 15px;
  /* margin-right:8px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-pic {
  border: 1px solid gray;
  height: 32px;
  width: 32px;
  border-radius: 15px;
  background-color: gray;
}

.main-section-wrap {
  -ms-flex: 1 1;
  flex: 1 1;
  -ms-flex-align: center;
  /* align-items: center; */
  -ms-flex-pack: center;
  /* justify-content: center; */
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #f9fafa;
  align-items: center;
  /* padding: 8px 40px; */
}

.main-section {
  width: 100%;
  align-items: center;
}

.room-posts-filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tui-image-editor-container .tui-image-editor-menu {
  width: auto;
  /* list-style: none; */
  padding: 0;
  margin: 0 auto;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap !important;
  overflow-y: scroll;
}

.room-post {
  display: inline-block;
  /* height:auto; */
  /* width: 100%; */
  /* margin: 0px 2% 13px 0px; */
  position: relative;
  /* box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.28); */
  background-color: transparent !important;
  height: 639px;
  max-width: 479px;
}

.room-post-create {
  display: inline-block;
  /* height:auto; */
  /* width: 100%; */
  /* margin: 0px 2% 13px 0px; */
  position: relative;
  /* box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.28); */
  background-color: transparent !important;
}

.menu-item {
  padding: 5.5px 14px;

  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  border: 0px transparent solid;
  background: #a962f1;
  border-radius: 20px;
  color: #222222 !important;
  font-weight: bold !important;
  font-family: "quicksand";
  font-size: 14px !important;
}
.menu-item-wrapper {
  border: 1px transparent solid;
}
.menu-item-wrapper.active {
  border: 0px blue solid;
}
.menu-item.active {
  border: 0px green solid;
}

.scroll-menu-arrow {
  padding: 20px;
  cursor: pointer;
}

.scroll-menu-arrow--disabled {
  visibility: hidden;
}

.related-room-post {
  display: inline-block;
  height: auto;
  /* width: 99%; */

  border-radius: 5px;

  /* margin: 0px 2% 13px 0px; */
  position: relative;
  /* box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.28); */
  background-color: #242424;
}

#search-create-text {
  display: block;
}

@media (max-width: 640px) {
  .fa-comments {
    font-size: 22.5px !important;
  }

  /* .new-btn {
    display: none !important;
  } */
  /* .output_frame {
    left: calc(50% - 320px) !important;
  } */

  .output_frame_re_rendered {
    left: calc(50% - 320px) !important;
  }
}

@media (max-width: 917px) {
}

@media (max-width: 680px) {
  .roomPostContainer {
    margin-bottom: 20px !important;
  }

  #main-wrap {
    padding: 0px 0px !important;
  }

  /* .header {
    padding: 10px 0px !important;
  } */

  #create-button {
    width: 71px !important;
    height: 26px !important;
  }

  .fa-minus {
    font-size: 12px !important;
    margin-left: 8px !important;
  }

  #create-text {
    font-size: 11px !important;
    font-weight: 700;
    position: absolute;
    margin-left: 22px !important;
    color: rgb(64, 255, 232);
  }
  #header-user-circle {
    margin-right: 10px !important;
  }
  #create-btn-wrap-right {
    margin-right: 15px !important;
  }
}

/* @media (max-width: 917px) {
  .main-nav ul li:nth-child(8n) {
    display: none !important;
  }
} */

/* @media (max-width: 845px) {
  .main-nav ul li:nth-child(7n) {
    display: none !important;
  }

} */
.profile-avatar-wrap {
}
.profile-loggedOutSignedIn-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 765px) {
  /* .main-nav ul li:nth-child(6n) {
    display: none !important;
  } */
  /* .profile-avatar-wrap {
    display: block !important;
  } */
  /* .profile-loggedOutSignedIn-wrap {
    display: none !important;
  } */
}

/* @media (max-width: 685px) {
  .main-nav ul li:nth-child(5n) {
    display: none !important;
  }
}

@media (max-width: 616px) {
  .main-nav ul li:nth-child(4n) {
    display: none !important;
  }
} */

@media (max-width: 557px) {
  /* .main-nav ul li:nth-child(3n) {
    display: none !important;
  } */
}

@media (max-width: 505px) {
  .communities-nav-wrap {
    height: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0px 0px !important;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  /* .room-post {
    height: 40vw;
    width: 84vw;
  } */

  /* #mobile-tab-bar {
    display: flex !important;
  } */
}

@media (max-width: 698px) {
  . {
    /* padding-top: 0px !important; */
  }
  #publish-section {
    /*display: none !important;*/
  }
  #mobile-menu-wrap {
    bottom: 177px !important;
  }
  .page-wrap {
    padding: 0px 0px 0px 0px !important;
  }
}

/* @media (max-width: 480px) {
  .main-nav ul li:nth-child(5n) {
    display: none !important;
  } */

/* .room-post {
    height: 105vw;
    width: 95vw;

  } */
/*}

/* .room-post:nth-child(3n) {
  margin-right: 0;
}

.room-post:nth-child(6n) {
  margin-right: 0;
} */

/* @media screen and (max-width: 1003px) {
  .room-post:nth-child(3n) {
    margin-right:0px;
  }
  .room-post {
    width:48% !important;
  }

  .room-post:nth-of-type(odd) {
    float: right;
    margin: 0px 0% 13px 0px;
  }

  .room-post:nth-of-type(even) {
    float: left;
    margin: 0px 0% 13px 0px;
  }

  .room-post:nth-of-type(odd) {
    float: right;
    margin: 0px 0% 13px 0px;
  }

  .room-post:nth-of-type(even) {
    float: left;
    margin: 0px 0% 13px 0px;
  }

} */

/* @media screen and (max-width: 775px) {
  .room-post:nth-child(3n) {
    margin-right:0px;
  }
  .room-post {
    width:48% !important;
  }

  .room-post:nth-of-type(odd) {
    float: right;
    margin: 0px 0% 13px 0px;
  }

  .room-post:nth-of-type(even) {
    float: left;
    margin: 0px 0% 13px 0px;
  }

  .room-post:nth-of-type(odd) {
    float: right;
    margin: 0px 0% 13px 0px;
  }

  .room-post:nth-of-type(even) {
    float: left;
    margin: 0px 0% 13px 0px;
  }

} */

/*----------------------Sign Up---------------------*/
.main-section-wrap-signup-screen {
  /* flex: 1 1; */
  /* -ms-flex-align: center; */
  /* align-items: center; */
  /* -ms-flex-pack: center; */
  /* justify-content: center; */
  /* display: -ms-flexbox; */
  /* display: flex; */
  /* -ms-flex-direction: column; */
  /* flex-direction: column; */
  /* background: #f9fafa; */
  /* align-items: center; */
  width: 100%;
  background: #1c1c1c;
}

.main-section-wrap-signup-screen-mobile {
  -ms-flex: 1 1;
  flex: 1 1;
  -ms-flex-align: center;
  /* align-items: center; */
  -ms-flex-pack: center;
  /* justify-content: center; */
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #f9fafa;
  align-items: center;
  padding: 0px 30px 25px 30px;
}

.signup-screen-label {
  color: white;
  font-weight: bold;
  margin: 10px;
  font-size: 21px;
  font-weight: bold;
  font-family: "Quicksand";
}

.signup-screen-label-mobile {
  color: #1bb243;
  font-weight: bold;
  margin: 21px;
  font-size: 20px;
}

.main-section-signup-box {
  /* align-items: center; */
  /* height: 440px; */
  background: #1c1c1c;
  /* margin: auto; */
  border: 0px solid #dde0eb;
  border-radius: 6px;
  /* display: -webkit-flex; */
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  font-family: "Source Sans Pro";
  color: #80848c;
  font-size: 12px;
  width: 100%;

  position: relative;
 

}

.MuiInputBase-input {
  height: 100%;
}

.MuiInputLabel-filled {
  color: #9c9c9c;
}

/* .MuiInputBase-input,
.MuiFilledInput-input,
.MuiInputBase-inputMultiline .MuiFilledInput-inputMultiline {
  height: 100% !important;
} */

.MuiFormLabel-root,
.MuiInputLabel-root,
.MuiInputLabel-formControl,
.MuiInputLabel-animated,
.MuiInputLabel-filled {
  color: #9c9c9c !important;
}

.MuiAvatar-img {
  color: transparent;
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
  text-align: center;
  text-indent: 10000px;
  position: absolute;
  top: 0px;
}

.MuiAvatar-root,
.MuiAvatar-circle {
  position: relative;
  border-radius: 50%;
  width: 20%;
  padding-bottom: 20%;
  background: white;
}

.main-section-signup-box-mobile {
  width: 100%;

  height: 100%;
  background: white;
  /* margin: auto; */
  border: 1px solid #dde0eb;
  border-radius: 6px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  color: #80848c;
  font-size: 12px;
  padding: 0px 13px 0px 13px;
}

.signup-section-p {
  font-size: 12px;
  margin: 10px 10px;
  color: white;
  margin-left: 15px;
}

.signup-section-p-mobile {
  font-size: 12px;
  margin: 19px 10px;
}

.signup-section-log-in-here-p {
  font-weight: bold;
  color: #1bb243;
  text-decoration: none;
  font-size: 12px;
  margin-left: 5px;
}

.signup-section-fields {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
}

.signup-section-fields-mobile {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-pack: center;
  justify-content: center;
  flex-direction: column;
}

.signup-section-input-field {
  border: 0px solid #dde0eb;
  border-radius: 3px;
  color: #333333;
  font-size: 1.4rem;
  /* margin-bottom: 1.4rem; */
  padding: 6px;
  font-family: Helvetica, Arial, sans-serif;
  outline: none;
  background-color: #f9fafa;
  -webkit-font-smoothing: antialiased;
  -webkit-flex: 1 1;
  flex: 1 1;
  width: 100%;
  margin-top: 10px;
}

.signup-section-input-field-mobile {
  border: 1px solid #dde0eb;
  border-radius: 6px;
  color: #333333;
  font-size: 1.4rem;
  margin-bottom: 1.4rem;
  padding: 1rem;
  font-family: Helvetica, Arial, sans-serif;
  outline: none;
  background-color: #f9fafa;
  -webkit-font-smoothing: antialiased;
  -ms-flex: 1 1;
  flex: 1 1;
  width: 100%;
}

.signup-section-signup-button {
  background-color: rgb(64, 255, 232);
  border: none;
  color: black;
  cursor: pointer;
  font-size: 13px;
  font-family: "Source Sans Pro", sans-serif;
  /* margin-bottom: 10px; */
  padding: 1rem;
  line-height: 0;
  width: 70px;
  height: 25px;
  border-radius: 3px;
  font-weight: 600;
  right: 21px;
  /* position: relative; */
  margin-bottom: 10px;
  /* margin-left: 177px; */
  float: right;
}

.signup-section-signup-button-mobile {
  background-color: #1bb243;
  border: 1px solid #0a7f29;
  color: white;
  cursor: pointer;
  font-size: 1.4rem;
  font-family: "Source Sans Pro", sans-serif;
  /* margin-bottom: 10px; */
  padding: 1rem;
  line-height: 0;
  /* width: 184px; */
  width: 100%;
  height: 36px;
  border-radius: 6px;
  margin: 8px 11px 6px;
}

.signup-screen-lbl {
  font-size: 14px !important;
  color: white;
  margin-top: 5px;
  margin-bottom: 5px;
}

.signup-screen-lbl-mobile {
  font-size: 13px !important;
  margin-bottom: 7px;
  font-weight: 550;
}
/*----------------------Sign Up---------------------*/

/*----------------------Login---------------------*/
.main-section-wrap-login-screen {
  /*webkit-flex: 1 1; */
  flex: 1 1;
  -ms-flex-align: center;
  /* align-items: center; */
  -ms-flex-pack: center;
  /* justify-content: center; */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  background: #0f0f0f;
  -webkit-align-items: center;
  align-items: center;
  padding: 0px 20px;
  /* -webkit-justify-content: center; */
  /* justify-content: center; */
}

.MuiSnackbar-anchorOriginBottomCenter {
  left: 0% !important;
  right: auto;
  bottom: 24px !important;
  /* transform: translateX(0%) !important; */
}
.MuiSnackbar-root {
  left: 0px !important;
  right: 0px !important;
  display: flex;
  z-index: 1000000000000 !important;
  /* position: fixed; */
  align-items: center;
  justify-content: center;
}

#name-user-name-fullname {
  margin-bottom: 0px;
}

@media (max-width: 600px) {
  .MuiSnackbar-anchorOriginBottomCenter {
    left: 0% !important;
    right: auto;
    bottom: 24px !important;
    /* transform: translateX(0%) !important; */
  }
  .MuiSnackbar-root {
    left: 0px !important;
    right: 0px !important;
    display: none !important;
    z-index: 1000000000000 !important;
    /* position: fixed; */
    align-items: center;
    justify-content: center;
  }
}
.login-screen-wrap {
  flex: 1;
  display: flex;
  height: 100% !important;
  width: 100% !important;
}

.password-forgot-password {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* .mdl-textfield__label {
  top: 10px !important;
} */

/* .firebaseui-id-submit,
.firebaseui-button,
.mdl-button,
.mdl-js-button,
.mdl-button--raised,
.mdl-button--colored {
  background: #40ffe8 !important;
} */

.mdl-button--raised.mdl-button--colored {
  background-color: #4fd975 !important;
  border-radius: 4px !important;
  color: #000 !important;
  font-weight: bold !important;
  font-family: "quicksand" !important;
}

.mdl-button--primary.mdl-button--primary {
  background-color: #4fd975 !important;
  border-radius: 4px !important;
  color: #000 !important;
  font-weight: bold !important;
  font-family: "quicksand" !important;
}

.login-section-p {
  font-size: 14px !important;
  margin: 10px 0px;
}

.login-section-sign-up-here-p {
  font-family: "Source Sans Pro";
  font-weight: bold;
  color: #1bb243;
  text-decoration: none;
  font-size: 12px;
  margin-left: 5px;
}

.login-section-fields {
  display: flex;
  width: 100%;
}

.login-section-input-field {
  border: 1px solid #dde0eb;
  border-radius: 6px;
  color: #333333;
  font-size: 1.4rem;
  margin-bottom: 1.4rem;
  padding: 1rem;
  font-family: Helvetica, Arial, sans-serif;
  outline: none;
  background-color: #f9fafa;
  -webkit-font-smoothing: antialiased;
  flex: 1;
}

.login-section-input-field-password {
  border: 1px solid #dde0eb;
  border-radius: 6px;
  color: #333333;
  font-size: 1.4rem;
  margin-bottom: 1.4rem;
  padding: 1rem;
  font-family: Helvetica, Arial, sans-serif;
  outline: none;
  background-color: #f9fafa;
  -webkit-font-smoothing: antialiased;
  flex: 1;
}

.login-section-login-button {
  background-color: rgb(64, 255, 232);
  border: none;
  color: black;
  cursor: pointer;
  font-size: 12px;
  font-family: "Source Sans Pro", sans-serif;
  /* margin-bottom: 10px; */
  padding: 1rem;
  line-height: 0;
  width: 70px;
  height: 25px;
  border-radius: 3px;
  font-weight: 600;

  margin-bottom: 20px;
  margin-left: 17px;
  float: right;
}

#SaveAndContinue {
  font-family: "Quicksand";
  max-height: 48px;
  border: 0px;
  height: 100%;
  width: 100%;
  padding: 1%;
  font-size: 14px;
  color: black;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #4fd975;
  border-radius: 10px;
}

.profile-avatar-wrap {
  cursor: pointer;
}

@media (max-width: 414px) {
  /* #notification-wrap {
    height: calc(100vh - 155px) !important;
  } */


  #profile-wrap {
    position: relative;
    border-radius: 50%;
    width: 30.7% !important;
    background: white;
    justify-content: center;
    align-items: center;
  }
}

#profile-wrap {
  position: relative;
  border-radius: 50%;
  width: 20%;
  background: white;
  justify-content: center;
  align-items: center;
}

.firebaseui-container {
  background-color: #3f3f3e !important;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  direction: ltr;
  font: 16px Roboto, arial, sans-serif;
  margin: 0 auto;
  margin-bottom: 0px;
  max-width: 100% !important;
  overflow: visible;
  position: relative;
  text-align: left;
  width: 100%;
}

.mdl-shadow--2dp {
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.14), 0 0px 0px 0px rgba(0, 0, 0, 0),
    0 0px 0px 0 rgba(0, 0, 0, 0) !important;
}

/*----------------------Login---------------------*/

/*----------------------Community---------------------*/
.main-section-wrap-community-screen {
  flex: 1 1;
  -ms-flex-align: center;
  /* align-items: center; */
  -ms-flex-pack: center;
  /* justify-content: center; */
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #f9fafa;
  align-items: center;
  padding: 31px 13px 30px;
  height: 100vh;
}

.community-screen-label {
  color: #1bb243;
  font-weight: bold;
  margin: 24px;
  font-size: 16px;
}

.main-section-community-box {
  width: 100%;
  max-width: 600px;
  -ms-flex-align: center;
  align-items: center;
  height: 205px;
  background: white;
  /* margin: auto; */
  border: 1px solid #dde0eb;
  border-radius: 6px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  font-family: "Source Sans Pro";
  color: #80848c;
  font-size: 12px;
  padding: 0px 21px;
  -ms-flex: 1 1;
  flex: 1 1;
  padding: 31px 16px 26px;
}

.community-section-p {
  font-size: 12px;
  margin: 10px 10px;
}

.community-section-fields {
  display: flex;
  width: 100%;
}

.community-section-input-field {
  border: 1px solid #dde0eb;
  border-radius: 6px;
  color: #333333;
  font-size: 1.4rem;
  margin-bottom: 1.4rem;
  padding: 1rem;
  font-family: Helvetica, Arial, sans-serif;
  outline: none;
  background-color: #f9fafa;
  -webkit-font-smoothing: antialiased;
  flex: 1;
  width: 100%;
}

.community-section-login-button {
  background-color: #1bb243;
  border: 1px solid #0a7f29;
  color: white;
  cursor: pointer;
  font-size: 1.4rem;
  font-family: "Source Sans Pro", sans-serif;
  /* margin-bottom: 10px; */
  padding: 1rem;
  line-height: 0;
  width: 140px;
  height: 32px;
  border-radius: 6px;
  margin: 10px 11px 6px;
}

.community-side-bar-text {
  border: 1px solid #dde0eb;
  border-radius: 6px;
  color: #333333;
  font-size: 1.4rem;
  margin-bottom: 1.4rem;
  padding: 1rem;
  font-family: Helvetica, Arial, sans-serif;
  outline: none;
  background-color: #f9fafa;
  -webkit-font-smoothing: antialiased;
}

.community-create-community-button {
  outline: none;
  cursor: pointer;
  border: 1px solid rgb(10, 127, 41);
  border-radius: 17.5px;
  height: 36px;
  width: 100%;
  background-color: rgb(27, 178, 67);
  font-family: "Source Sans Pro";
  color: white;
  font-weight: 100;
  font-size: 14px;
}
/*----------------------Community---------------------*/

/*----------------------RoomMain----------------------*/
.CodeMirror {
  position: relative;
  overflow: hidden;
  background: white !important;
  width: 100% !important;
  height: 100% !important;
  font-size: 16px !important;
}
.description-container {
  height: 140px;
  width: 100%;
  border-bottom: 0px solid black;
}

.room-title {
}

.user-name {
}

.like-icon {
}

.save-link {
}

.share-link {
}

.remix-link {
}
.description-text {
}
.tags {
}

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 100%;
  height: 5px;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  cursor: se-resize;

  z-index: 99999;

  background-color: rgb(32, 32, 32);
}
.box {
  display: inline-block;
  background: #ccc;
  border: 1px solid black;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
  margin: 20px;
}
.box .text {
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 36px;
}
/* .box3 .react-resizable-handle {
    display: none;
  }
  .box3:hover . {
    display: block;
  } */

.page-wrap twilight {
  padding: 0;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /* width: 100%; */
}

.page-wrap {
  position: relative;
  /*padding: 20px 20px 50px 20px;*/
}

.full-page {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  z-index: 0 !important;
  top: 0px !important;
  padding-top: 64px;
}

.full-screen-cable-node:fullscreen {
  background-color: #faa;
  height: 100%;
  width: 100%;
}

.room-main-page-wrap {
  display: flex;
  flex: 1;
  flex-direction: row;
  /* padding-top: 70px; */
}

.tabs-wrap {
  display: flex;
  height: 32px;
  width: 255px;
}

.tab-details-component {
  flex: 1 0 32px;
  border: 0px solid black;
  cursor: pointer;
}

.tab-details-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 31px;
}

.tabs-details-wrap-mobile {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: center;
  height: 31px;
}

.tab-rooms-component {
  flex: 1 0 90px;
  border: 0px solid black;
  cursor: pointer;
}

.tab-rooms-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 31px;
}

.tab-comments-component {
  flex: 1 0 32px;
  border: 0px solid black;
  cursor: pointer;
}

.tabs-comments-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 31px;
}

.room-info-wrap-box {
  display: flex;
  width: 100%;
  margin-top: 16px;
  background-color: rgb(249, 250, 250);
}

.room-info-wrap-box-mobile {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  background-color: rgb(249, 250, 250);
}

.room-info-wrap {
  border-radius: 20px;
  height: 29px;
  width: 29px;
  background-color: yellow;
  margin-right: 10px;
  margin-left: 17px;
}

.room-info-wrap-mobile {
  border-radius: 20px;
  height: 29px;
  width: 29px;
  background-color: yellow;
  margin-right: 10px;
  margin-left: 17px;
}

.room-info {
  width: 661px;
  display: -ms-flexbox;
  display: flex;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-left: 4px;
}

.room-info-mobile {
  width: 661px;
  display: -ms-flexbox;
  display: flex;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-flex: 11 1;
  flex: 11 1;
}

.button-hover {
  flex: 0 112px;
  display: flex;
  align-items: center;
  height: 20px;
  border: 0px solid red;
  margin-left: 0px;
}
.button-hover-text {
  color: black;
  font: weight 550;
  font-family: "Source Sans Pro";
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: "grayscale";
}

.room-by {
  flex: 0 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  border: 0px solid red;
  padding-top: 5px;
}

#room-wrap {
  height: 100%;
}

.room-by-mobile {
  -ms-flex: 0 1 44px;
  flex: 0 1 44px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 20px;
  border: 0px solid red;
  padding-top: 10px;
}

.room-by-text {
  font-size: 12px;
  color: #80848c;
  font-family: "Source Sans Pro";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.room-by-text-mobile {
  font-size: 14px;
  color: #80848c;
  font-family: "Source Sans Pro";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 49px;
}

.full-name-info {
  -ms-flex: 0 1 111px;
  flex: 0 1 111px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  /* justify-content: center; */
  height: 20px;
  border: 0px solid red;
  padding-top: 5px;
  margin-left: 1px;
}

.full-name-info-mobile {
  -ms-flex: 0 1 111px;
  flex: 0 1 111px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  /* justify-content: center; */
  height: 20px;
  border: 0px solid red;
  padding-top: 10px;
  margin-left: 2px;
}

.full-name-text {
  font-size: 12px;
  color: #80848c;
  font-family: "Source Sans Pro";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  line-height: 1px;
  margin-right: 11px;
}

.full-name-text-mobile {
  font-size: 14px;
  color: #80848c;
  font-family: "Source Sans Pro";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  line-height: 1px;
  margin-right: 10px;
}

.room-like {
  flex: 0 10px;
  display: flex;
  height: 20px;
  border: 0px solid red;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
}

.room-like-mobile {
  -ms-flex: 0 1 10px;
  flex: 0 1 10px;
  display: -ms-flexbox;
  display: flex;
  height: 20px;
  border: 0px solid red;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 14px;
  margin-left: 13px;
}

.room-like-number {
  flex: 0 12px;
  height: 20px;
  border: 0px solid red;
  align-items: center;
  justify-content: center;
  display: flex;
  padding-top: 5px;
}

.room-like-number-mobile {
  -ms-flex: 0 1 12px;
  flex: 0 1 12px;
  height: 20px;
  border: 0px solid red;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
  padding-top: 12px;
}

.room-like-number-text {
  font-size: 12px;
  color: #1bb243;
  font-family: "Source Sans Pro";
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 8px;
}

.room-save {
  flex: 0 42px;
  height: 20px;
  border: 0px solid red;
  align-items: center;
  justify-content: center;
  display: flex;
  padding-top: 3px;
}

.room-save-mobile {
  -ms-flex: 0 1 42px;
  flex: 0 1 42px;
  height: 20px;
  border: 0px solid red;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
  padding-top: 9px;
}

.menubg {
  background-color: #181818;
  color: rgb(64, 255, 232) !important;
}
.menubg-s {
  background-color: #181818;
  color: rgb(64, 255, 232) !important;
  fill: rgb(64, 255, 232) !important;
}
.menubg-remix {
  background-color: transparent;
  color: rgb(64, 255, 232) !important;
}

.menubg-tab-mobile {
  background-color: #181818;
  color: rgb(64, 255, 232) !important;
}
.menubg-s-tab-mobile {
  background-color: #181818;
  color: rgb(64, 255, 232) !important;
  fill: rgb(64, 255, 232) !important;
}
.el {
  color: rgb(64, 255, 232) !important;
}

.remix-icon {
  fill: rgb(64, 255, 232) !important;
}

.menubgnot {
  color: rgb(82, 82, 82);
}

.menu-bg-border {
  border-right: 1px solid rgb(221, 224, 235);
}

.pesdk-react-editor .pesdk-react-controls__container__row {
  background: #181818 !important;
}

.pesdk-react-editor .pesdk-react-canvas__container__row {
  background: #181818 !important;
}

.pesdk-react-editor .pesdk-react-topBar__row {
  background: #181818 !important;
}

.pesdk-react-editor .pesdk-react-header__label {
  background: #181818 !important;
}

.pesdk-react-editor .pesdk-react-topBar__button--blue {
  background: #40ffe8 !important;
  color: #000000 !important;
}

.pesdk-react-editor .pesdk-react-header {
  background: #181818 !important;
}

.pesdk-react-editor .pesdk-react-topBar__button__label {
  color: #000 !important;
}

.pesdk-react-topBar__button__icon {
  display: none !important;
}

.pesdk-react-editor .pesdk-react-topBar__button--withIcon {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.pesdk-react-editor .pesdk-react-topBar__button--withIcon {
  background-color: #40ffe8 !important;
}

.pesdk-react-editor .pesdk-react-topBar__row {
  height: 55px !important;
}

.room-save-text {
  font-size: 12px;
  color: #686b72;
  font-family: "Source Sans Pro";
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 3px;
}

.room-share {
  flex: 0 43px;
  height: 20px;
  border: 0px solid red;
  align-items: center;
  justify-content: center;
  display: flex;
  padding-top: 5px;
}

.room-share-mobile {
  -ms-flex: 0 1 43px;
  flex: 0 1 43px;
  height: 20px;
  border: 0px solid red;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
  padding-top: 12px;
}

.room-share-link {
  font-size: 12px;
  color: #686b72;
  font-family: "Source Sans Pro";
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.top-boxes {
  background-color: rgb(249, 250, 250);
}
.overflow-detector {
  height: 110px;
  background-color: #f9fafa;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  height: 213px;
  width: 100%;
}

.text-for-overflow-detector {
  height: 213px;
  width: 100%;
  /*word-wrap: break-word;*/
}

/* .description-text {
  font-family: "Source Sans Pro";
  width: 100%;
  padding: 0px 58px;
  font-size: 12px;
  color: #494a4c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: 3px;
  margin-top: -2px;
} */

.description-text {
  font-family: quicksand;
  margin-top: 10px;
  color: white;
  font-size: 18px;
  font-weight: 800;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
  -webkit-line-clamp: 2;
}

.description-text-mobile {
  font-family: "Source Sans Pro";
  width: 100%;
  padding: 0px 19px;
  font-size: 12px;
  color: #494a4c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: 5px;
  margin-top: 10px;
  font-weight: 600;
}

.editor-options-desktop {
  position: absolute;
  display: flex;
  flex: 0 200px;
  color: black;
  right: 0;
  width: 195px;
  height: 32px;
  align-items: center;
  padding-top: 2px;
}

.editor-options-mobile-wrap {
  width: 100%;
  height: 32px;
  z-index: 99999;
  border-bottom: 1px solid rgb(221, 224, 235);
  background-color: white;
  display: flex;
  justify-content: center;
}

.editor-options-mobile {
  position: relative;
  display: flex;
  flex: 0 200px;
  color: black;
  right: 0;
  width: 195px;
  height: 32px;
  align-items: center;
  padding-top: 2px;
}

.show-more-area {
  align-items: center;
  height: 0px;
  display: flex;
  flex-direction: column;
  padding-left: 18px;
  border-bottom: 1px solid rgb(221, 224, 235);
  display: block;
}
.show-more-box {
  flex: 1;
  width: 100%;
  position: relative;
  top: -44px;
  height: 22px;
  position: relative;
}

.show-more-box-mobile {
  -ms-flex: 1 1;
  flex: 1 1;
  width: 100%;
  position: relative;
  top: -72px;
  height: 22px;
  position: relative;
}

.show-more-section {
  width: 100%;
  height: 22px;
  background-color: #f9fafa;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: column;
  align-items: center;
}

.cover-strip {
  width: 100%;
  height: 6px;
  background-color: #f9fafa;
  opacity: 0.7;
  top: -4px;
  position: absolute;
  visibility: hidden;
}

.show-more-button {
  visibility: hidden;
}
.showmore-text {
  font-size: 12px;
  font-weight: 600;
  font-family: "Source Sans Pro";
  color: #1bb243;
  top: 0px;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: -2px;
  cursor: pointer;
}
.show-more-preview-bar-wrap {
  height: 36px;
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
}

.show-more-preview-bar-wrap-mobile {
  height: 36px;
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  margin-top: 46px;
  padding-right: 17px;
}

.tags-wrap {
  color: #80848c;
  font-family: "Source Sans Pro";
  display: flex;
  width: 100%;
  padding-top: 0px;
}

.tags-wrap-mobile {
  color: #80848c;
  font-family: "Source Sans Pro";
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 0px;
  height: 100px;
}

.tag-img-wrap {
  background-color: #f9fafa;
  height: 20px;
}

.tag-img-wrap-mobile {
  background-color: #f9fafa;
  height: 16px;
  width: 16px;
  margin-left: 9px;
}

.tags {
  background-color: #f9fafa;
  height: 24px;
  width: 100%;
}
.tags-text {
  font-family: "Source Sans Pro";
  font-size: 12px;
  color: #80848c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.code-box-labels {
  flex: 1;
  display: flex;
  width: 100%;
  position: relative;
  height: 52px;
}

.code-box-label {
  flex: 1;
  height: 52px;
  border-bottom: 1px solid #3E3E3E;
}
.code-label-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 52px;
  border-bottom: 1px solid #3E3E3E;
}

.code-label-html {
  color: white;
  font-weight: 600;
  margin-right: 7px;
}

.code-label-css {
  color: white;
  font-weight: 600;
  margin-right: 7px;
}

.code-label-js {
  color: white;
  font-weight: 600;
  margin-right: 7px;
  font-family: "Quicksand" !important;
}

.code-section {
  display: flex;
  flex-direction: row;
  height: 100%;
  padding-top: 70px;
}

.display-icon {
  background-color: black;
  height: 20px;
  width: 15px;
  margin-right: 6px;
}

.display-wrap {
  display: flex;
}

.display-text {
  font-size: 12px;
  font-family: "Source Sans Pro";
  color: #686b72;
  -webkit-font-smoothing: antialiased;
}

.draw-icon {
  background-color: black;
  height: 20px;
  width: 20px;
  margin-left: 10px;
}

.draw-text {
  font-size: 12px;
  font-family: "Source Sans Pro";
  color: #686b72;
  -webkit-font-smoothing: antialiased;
}

.draw-wrap {
  display: flex;
}

.editor-icon {
  background-color: black;
  height: 20px;
  width: 20px;
  margin-left: 10px;
}

.lock-editor-text {
  font-size: 12px;
  font-family: "Source Sans Pro";
  color: #686b72;
  -webkit-font-smoothing: antialiased;
}

.lock-editor-wrap {
  display: flex;
}

.html_code {
  flex: 1;
  width: 100%;
  resize: none;
  outline: none;

  border: 1px solid rgb(32, 32, 32);
}

.css_code {
  flex: 1;
  width: 100%;
  resize: none;
  outline: none;
  border: 1px solid #dde0eb;
}

.js_code {
  flex: 1;
  width: 100%;
  resize: none;
  outline: none;
  border: 1px solid #dde0eb;
}

.preferences {
  font-family: Source Sans Pro;
  color: #80848c;
  font-size: 12px;
  margin-left: 14px;
  margin-top: 1px;
}

.clone {
  font-family: "Source Sans Pro";
  color: #80848c;
  font-size: 12px;
  margin-left: 10px;
  margin-top: 1px;
}

.report {
  font-family: "Source Sans Pro";
  color: #80848c;
  font-size: 12px;
  margin-left: 10px;
  margin-top: 1px;
}

.flag {
  font-family: "Source Sans Pro";
  color: #80848c;
  font-size: 12px;
  margin-left: 9px;
  margin-top: 1px;
}

.live {
  font-family: "Source Sans Pro";
  color: #80848c;
  font-size: 12px;
  margin-left: 10px;
  margin-top: 1px;
}

.live-mobile {
  font-family: "Source Sans Pro";
  color: #80848c;
  font-size: 14px;
  margin-left: 10px;
  margin-top: 1px;
}

.on-switch-wrap {
  background-color: #1bb243;
  height: 14px;
  width: 24px;
  margin-left: 8px;
  border-radius: 7px;
  margin-top: 2px;
  display: flex;
  align-items: center;
}

.on-switch-wrap-mobile {
  background-color: #1bb243;
  height: 17px;
  width: 28px;
  margin-left: 8px;
  border-radius: 8px;
  margin-top: 2px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.on-switch {
  background-color: white;
  height: 10px;
  width: 10px;
  border-radius: 10px;
  left: 3px;
  position: relative;
}

.on-switch-mobile {
  background-color: white;
  height: 12px;
  width: 12px;
  border-radius: 10px;
  left: 3px;
  position: relative;
}

.output-sizer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.result {
  position: relative !important;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  overflow: hidden;
  background: #222222;
}
/* input[type="text"] {
  border-radius: 0px !important;
  background-color: transparent !important;
  font-family: quicksand !important;
} */

.MuiInputBase-input {
  font-family: "Quicksand" !important;
}

.dropzone {
  height: 100px !important;
  font-family: "Quicksand" !important;
  color: #f06263 !important;
  background: #3f3f3e !important;
}

.dropzone-instructions--main {
  color: #f06263 !important;
}

.dropzone-instructions--sub {
  color: #fefffe !important;
  font-family: quicksand !important;
}

.dropzone {
  border: 2px dashed #f06263 !important;
}

/* .cropper-container,
.cropper-bg {
  height: 290px !important;
}

.cropper-crop-box {
  height: 285px !important;
} */
.MuiTypography-root,
.MuiFormControlLabel-label,
.MuiTypography-body1 {
  margin-left: 10px !important;
  font-family: "Quicksand" !important;
}
.dg-hdl,
.dg-hdl-t,
.dg-hdl-tl {
  content: "";
  /* position: absolute; */
  /* top: -5px; */
  /* left: -5px; */
  width: 10px !important;
  height: 10px !important;
  /* margin-left: 50%; */
  /* margin-top: 50%; */
  border-radius: 50% !important;
  background: rgb(218, 207, 207) !important;
  box-shadow: 0 0 5px 1px rgba(14, 19, 24, 0.15),
    0 0 0 1px rgba(14, 19, 24, 0.15);
}

.output_frame_animation {
  border: 0;
  background: #3e3e3e !important;
  position: absolute;
  left: 0;
  z-index: 1;
  margin: auto;
  border-radius: 20px !important;
}

.remix_overlay_wrap {
  border: 0;
  background: #3f3f3e !important;
  /* position: absolute; */
  left: 0;
  z-index: 1;
  margin: auto;
  border-radius: 15px;
}

.output_frame_re_rendered {
  border: 0;
  background: #1f1f1f;
  position: absolute;
  left: 0;
  z-index: 1;
  margin: auto;
}

.output_frame_objects {
  border: 0;
  background: #1f1f1f;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
#out-cover {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: block;
}

.react-tabs__tab-list {
  border-bottom: 1px solid rgb(221, 224, 235);
  /* list-style: none; */
}

.details-text {
  font-size: 9px;
  color: #80848c;
  font-family: "Source Sans Pro";

  margin-top: 2px;
}

.item {
  width: 100% !important;
}

.details-text-selected {
  font-size: 9px;
  color: #ffffff;
  font-family: "Source Sans Pro";
  margin-right: 10px;
  margin-top: 2px;
  margin-left: 10px;
}

.rooms-text {
  font-size: 9px;
  color: #80848c;
  font-family: "Source Sans Pro";
  margin-right: -2px;
  margin-top: 2px;
  margin-left: 7px;
}

.rooms-text-selected {
  font-size: 9px;
  color: #ffffff;
  font-family: "Source Sans Pro";
  margin-right: 10px;
  margin-top: 2px;
  margin-left: 4px;
  position: relative;
  left: 2px;
}

.comments-text {
  font-size: 9px;
  color: #80848c;
  font-family: "Source Sans Pro";
  margin-left: 9px;
  margin-right: 7px;
  margin-top: 3px;
}

.comments-text-selected {
  font-size: 9px;
  color: #ffffff;
  font-family: "Source Sans Pro";
  margin-left: 9px;
  margin-right: 7px;
  margin-top: 3px;
}

.preferences-bottom {
  position: absolute;
  height: 41px;
  width: 100%;
  background-color: #f9fafa;
  z-index: 99999;
  bottom: 33px;
  display: none;
  border-top: 1px solid rgb(221, 224, 235);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Source Sans Pro";
  padding: 13px 38px 27px 38px;
}

.MuiFormControlLabel-root {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-left: 0px !important;
  margin-right: 0px !important;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
}

#text {
  color: white;
}

.menu-select {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menu-search {
  height: 30px;
  width: 223px;
  margin-top: 20px;
  border-radius: 5px;
  border: 1px solid black;
  padding-left: 14px;
}

.main-add-section {
  display: flex;
  flex-wrap: wrap;
  width: 300px;
  overflow: hidden;
  height: 500px;
  background-color: #181818;
}

.menu-sel-wrap {
  width: 100%;
  height: 100%;
  margin: auto;
}

.menu-sel-wrap-wrap {
  width: 299px;
  height: 100%;
  float: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #181818;
}

.images-section {
  height: 100px;
  width: 100px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  background: #01aadc;
  border-radius: 5px;
  font-size: 20px;
}

.text-section {
  height: 100px;
  width: 100px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: "column";
  color: white;
  background: #01aadc;
  border-radius: 5px;
  font-size: 20px;
}

.gifs-section {
  height: 100px;
  width: 100px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  background: #01aadc;
  border-radius: 5px;
  font-size: 20px;
}

.stickers-section {
  height: 100px;
  width: 100px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  background: #01aadc;
  border-radius: 5px;
  font-size: 20px;
}
.shapes-section {
  height: 100px;
  width: 100px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  background: #01aadc;
  border-radius: 5px;
  font-size: 20px;
}

.icons-section {
  height: 100px;
  width: 100px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  background: #01aadc;
  border-radius: 5px;
  font-size: 20px;
}
.videos-section {
  height: 100px;
  width: 100px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  background: #01aadc;
  border-radius: 5px;
  font-size: 20px;
}
.audio-section {
  height: 100px;
  width: 100px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  background: #01aadc;
  border-radius: 5px;
  font-size: 20px;
}
.selection {
  width: 299px;
  margin-left: 100%;
  height: 100%;
  background: black;
}
.innerSel {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  align-items: center;
  background: rgb(24, 24, 24);
}

.overlay_output_frame {
  z-index: 2;
  /* background-color: red; */
  position: absolute;
  height: 100vh;
  width: 100%;
  border: 0px;
}

.output_frame_wrap {
  background-color: rgb(34, 34, 34) !important;
  position: relative;
  flex-grow: 1 !important;
  overflow: hidden;
}

/*----------------------RoomMain----------------------*/

/*----------------------Comments----------------------*/

.main-section-wrap-comments-screen {
  flex: 1 1;
  -ms-flex-align: center;
  /* align-items: center; */
  -ms-flex-pack: center;
  /* justify-content: center; */
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
}

.main-section-wrap-comments-box {
  /* height: 100%; */
  width: 100%;
  /* -webkit-align-items: center; */
  /* align-items: center; */
  background: #0f0f0f;
  /* margin: auto; */
  background: #0f0f0f;
  /* display: -webkit-flex; */
  /* display: flex; */
  -webkit-flex-direction: column;
  /* flex-direction: column; */
  font-family: "Source Sans Pro";
  color: #80848c;
  font-size: 12px;
  font-weight: 600;
  /* padding: 0px 21px; */
  -webkit-flex: 1 1;
  flex: 1 1;
  padding-left: 10px;
  padding-right: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-left: 77px;
  padding-right: 36px;
}

.react-tabs__tab--selected {
  background: #1bb243;
}

.category {
  margin: 8px 25px 10px;
}

@media only screen and (max-width: 1025px) {
  .category {
    margin: auto;
  }
}

/*----------------------Comments---------------------*/

/*----------------------Profile----------------------*/
.profile-main {
  width: 100%;
  background-color: white;
  padding: 20px;
  /* border-bottom: 1px solid #dde0eb; */
  height: 180px;
  background-color: #202020;
}

.profile-info {
  padding: 0px 50px;
  display: flex;
}

.profile-pic {
  border-radius: 40px;
  background-color: black;
  height: 80px;
  width: 80px;
}

.profile-top-section-bottom-section-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 30px;
}

.name-badge-follow-wrap {
  display: flex;
  flex-direction: row;
}

.name-badge-follow {
  display: flex;
  align-items: center;
}

.profile-name-profile {
  font-size: "18px";
  font-weight: 600;
  color: white;
}

.profile-verified {
  height: 14px;
  width: 14px;
  background-color: green;
  border-radius: 10px;
  margin-left: 3px;
}

.profile-follow-button {
  margin-left: 15px;
  margin-right: 15px;
  border: 1px solid green;
  border-radius: 4px;
  padding: 2px;
  width: 70px;
  font-size: 12px;
}

.profile-followers {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-follower-num {
  font-size: 13px;
  font-weight: 600;
  color: white;
}

.bio-info {
  word-wrap: break-word;
  max-width: 400px;
}

/* @media (max-width: 830px) {
  .name-badge-follow-wrap {
    display: flex;
    -ms-flex-direction: row;
    flex-direction: column;
  }

  .profile-pic {
    height: 50px;
    width: 50px;
  }

} */

.resizable {
  background: white;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 100px;
  left: 100px;
}

.resizable .resizers {
  width: 100%;
  height: 100%;

  box-sizing: border-box;
}

.resizable .resizers .resizer {
  width: 10px;
  height: 10px;
  border-radius: 50%; /*magic to turn square into circle*/
  background: white;

  position: absolute;
}

.resizable .resizers .resizer.top-left {
  left: -5px;
  top: -5px;
  cursor: nwse-resize; /*resizer cursor*/
}
.resizable .resizers .resizer.top-right {
  right: -5px;
  top: -5px;
  cursor: nesw-resize;
}
.resizable .resizers .resizer.bottom-left {
  left: -5px;
  bottom: -5px;
  cursor: nesw-resize;
}
.resizable .resizers .resizer.bottom-right {
  right: -5px;
  bottom: -5px;
  cursor: nwse-resize;
}

/*----------------------Profile----------------------*/
.footer {
  flex-shrink: 0;
  text-align: center;
  background-color: white;
  color: white;
}

.footer {
  height: 90px;
  border: 1px solid rgb(221, 224, 235);
  width: 100%;
}

.sign-up {
  display: flex;
  flex: 1;
  height: 100%;
}

.is-active {
  color: green;
}

.logo-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #565656;
  /* margin-right: 31px; */
}
.small-logo {
  display: none;
  font: 0/0 a;
  background-size: 138px 26px;
  width: 180px;
  height: 65px;
  border: 0;
  outline: 0;
}

.large-logo-desktop {
  display: block;
  font: 0/0 a;
  /* background: url(/images/logo.svg) no-repeat 0px 0px; */
  background-size: 159px 45px;
  width: 161px;
  height: 45px;
  border: 0;
  outline: 0;
}

.large-logo-tablet {
  display: block;
  font: 0/0 a;
  /* background: url(/images/logo.svg) no-repeat 0px 0px; */
  background-size: 159px 45px;
  width: 161px;
  height: 45px;
  border: 0;
  outline: 0;
}

.large-logo-mobile {
  display: block;
  font: 0/0 a;
  /* background: url(/images/logo.svg) no-repeat 0px 0px; */
  background-size: 126px 44px;
  width: 127px;
  height: 45px;
  border: 0;
  outline: 0;
  position: relative;
  top: -6px;
  left: -4px;
}

.my-communities-drop {
  height: 18px;
  margin-left: 15px;
}

.my-communities-drop-mobile {
  height: 18px;
  margin-left: 15px;
  position: relative;
  top: -7px;
  left: -4px;
}

.my-communities {
  text-decoration: none;
  font-size: 14px;
  font-family: "Source Sans Pro";
  color: rgb(128, 132, 140);
  -webkit-font-smoothing: antialiased;
}

/* ---------------------------------- App Modal ------------------------ */

.modal-wrap {
  height: 100%;
  overflow: hidden;
  padding-bottom: 127px;
}

.modal-messages-wrap {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(221, 224, 235);
  padding: 10px 20px;
}

.modal-messages-title {
  color: green;
  font-size: 20;
}

.modal-messages-message-box-wrap {
  display: flex;
}

.modal-messages-close {
  color: gray;
  font-size: 20px;
  margin-left: 20px;
}

/* for the modal opacity issue */
.modal-box-background {
  height: 100%;
  width: 100%;
  background-color: black;
}

.left {
  float: left;
  clear: left;
}
.right {
  float: right;
  clear: right;
}

/* ---------------------------------- App Modal ------------------------ */

/* ---------------------------------- Communities ---------------------- */

.communities {
  align-items: center;
  display: flex;
  border: 0px solid red;
  overflow: hidden;
  margin-right: 10px;
  color: #979aa1;
}

.communities ul {
  /* list-style-type: none; */
  display: inline-block;
  display: flex;
  height: 20px;
  background: white;
  margin-left: 14px;
}

.communities-wrap {
  padding: 0px 20px;
  display: flex;
  border-bottom: 1px solid #e5e8f0;
  background: white;
}

.communities-nav-wrap {
  height: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 50px;
}

.communities-list {
  display: flex;
  align-items: center;
  overflow: hidden;
  background: white;
  overflow: hidden;
  margin-left: 10px;
}

.communities-view-more {
  font-size: 13px;
  font-weight: 700px;
  padding: 0px 15px;
  color: #80848c;
  /* left: 313px; */
  width: 122px;
  background: white;
  right: 0;
  white-space: nowrap;
}

/* ---------------------------------- Communities ----------------------- */

/* ---------------------------------- Code Boxes ------------------------ */

#html-box {
  height: 100%;
  width: 3px;
  background-color: rgb(32, 32, 32);
}

#css-box {
  height: 100%;
  width: 3px;
  background-color: rgb(32, 32, 32);
}

.create-community-shorter-title {
  font-size: 12px;
}

.create-sidebar-text {
  font-size: 12px;
}

.community-side-bar-text {
  height: 100px;
  width: 100%;
  resize: none;
}

.create-community-wrap {
  flex: 1;
  display: flex;
}
#drop-area {
  border: 2px dashed #ccc;
  border-radius: 20px;
  width: 200px;
  font-family: sans-serif;
}
#drop-area.highlight {
  border-color: purple;
}
p {
  margin-top: 0;
}
.my-form {
}
#gallery {
  margin-top: 10px;
}
#gallery img {
  width: 150px;
  margin-bottom: 10px;
  margin-right: 10px;
  vertical-align: middle;
}
.button {
  display: inline-block;
  padding: 10px;
  background: #ccc;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.button:hover {
  background: #ddd;
}
#fileElem {
  display: none;
}
/* ----------------------------------- Code Boxes ----------------------- */

/* --------------------------- Create Communities ----------------------- */

.create-community-text {
  font-size: 14px;
}

.create-community-name-wrap {
  width: 100%;
}

.create-community-name {
  font-size: 12px;
}

.community-screen-text-top {
}

/* --------------------------- Create Communities ----------------------- */

/* --------------------------- dhtml labels ----------------------------- */

.dhtml-labels-wrap {
  height: 34px;
  width: 100%;
  border-top: 1px solid rgb(32, 32, 32);
  border-bottom: 1px solid rgb(32, 32, 32);
  background: rgb(24, 24, 24);
  z-index: 99999;
  position: absolute;
  margin-top: 70px;
  display: block;
}

.preferencesLbls {
  color: black;
  color: #80848c;
  font-size: 12px;
}

/* --------------------------- dhtml labels ----------------------------- */

/* --------------------------- FR Object ------------------------------- */

.resizable-object-wrap {
  background: white;
  position: absolute;
  top: 0px;
  z-index: 999999;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4;
  border: 1px solid #dedfe1;
}

.draggable-box {
  display: flex;
  background: white;
  position: absolute;
  top: 0px;
  z-index: 99999999999999;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid #dedfe1;
}

.menuBar {
  border-bottom: 1px solid #dedfe1;
  height: 46px;
  width: 100%;
}

.menu-options-wrap {
  height: 27px;
  width: 57px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
}

.menu-options-close {
  /* background-image: url("/images/close@3x.png"); */
  height: 16px;
  width: 18px;
  background-size: 14px 14px;
  background-repeat: no-repeat;
}

.menu-options-min {
  /* background-image: url("/images/minimize@3x.png"); */
  height: 16px;
  width: 18px;
  background-size: 14px 14px;
  background-repeat: no-repeat;
}

.menu-options-max {
  /* background-image: url("/images/maximize@3x.png"); */
  height: 16px;
  width: 18px;
  background-size: 14px 14px;
  background-repeat: no-repeat;
}

.object-content-wrap {
  flex: 1;
  overflow: hidden;
}

.object-content {
  height: 100%;
  border: 0px solid red;
  width: 100%;
}

.object-footer {
  border-top: 1px solid #dedfe1;
  height: 25px;
  width: 100%;
}

.button-box {
  border: 0px solid black;
  height: 34px;
}

.button-box-logged-out {
  border: 0px solid black;
  height: 34px;
  padding-right: 16px;
}

.sign-up-log-in-wrap {
  flex: 2 1 0%;
  display: flex;
  border: 0px solid black;
  right: 10px;
  position: absolute;
}

button#vertical-tab-images-tab.rwt__tab {
  border-bottom: 0px !important;
}
.item1 {
  grid-area: header;
}
.item2 {
  grid-area: menu;
}
.flows-wrap {
  grid-area: main;
}
.item4 {
  grid-area: right;
}
.item5 {
  grid-area: footer;
}
.item7 {
  grid-area: button;
  padding: 12px 0px;
  display: -webkit-flex;
  display: flex;

  -webkit-align-items: center;
  align-items: center;
  max-width: 826px;
  margin: auto;
  width: 100%;
}
.item8 {
  grid-area: menu;
  padding: 0px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  margin: auto;
  width: 100%;
}
.item9 {
  grid-area: space;
  padding: 0px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  margin: auto;
  width: 100%;
}

.grid-container {
  display: grid;

  grid-template-columns: auto auto auto auto auto;
  grid-template-rows: 12px auto auto auto auto;
  grid-template-areas:
    "header header header header header header"
    "button button button button button button"
    "menu menu menu menu menu menu"
    "main main main main main main"
    "space space space space space space";
  height: 100%;
  width: 100%;
  /* -webkit-align-content: center; */
  /* align-content: center; */
  margin-top: 5px;
}

/* .grid-container > div {
  background-color: #222222;
} */

.flows-wrap {
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

.flows {
  display: grid;
  /* grid-template-rows: 384px 370px 250px; */
  grid-template-rows: 398px 398px 398px 398px 400px 398px 470px;
  padding: 0px 30px;
  grid-gap: 95px 20px;
  /* -webkit-align-content: center; */
  /* align-content: center; */
  margin: auto;
  grid-template-columns: repeat(auto-fit, minmax(222.5px, 1fr));
  width: 100%;
  height: 100vh;
}

#search-glass-wrap {
  width:92px;
}

@media (max-width: 800px) {
  #search-glass-wrap {
    width:20px !important;

  }

  #search-create-text {
    display: none !important;
  }
  
  .flows {
    display: grid;
    /* grid-template-rows: 384px 370px 250px; */
    grid-template-rows: 400px 400px 400px 400px 400px 400px 400px 400px 400px 400px 400px 400px 400px 400px 400px 400px 400px 400px 400px 450px !important;
    padding: 0px 30px;
    grid-gap: 95px 20px;
    /* -webkit-align-content: center; */
    /* align-content: center; */
    margin: auto;
    grid-template-columns: repeat(auto-fit, minmax(222.5px, 1fr));
    width: 100%;
    height: 100vh;
  }
}

.flows > div {
  text-align: center;
  font-size: 30px;

  border-radius: 15px;
  /* max-width: 277px; */
}

/* .MuiSwitch-root,
.Component-root-23 {
  width: 57px !important;
} */

/* .publish-wrap {
  padding-left: 50px !important;
  padding-right: 50px !important;
} */

.publish-wrap {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  /* background-color: rgb(31, 31, 31) !important; */
  width: 100%;
  margin-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
}
/* .publish-wrap-wrap {
  background-color: rgb(31, 31, 31);
} */

.MuiButtonGroup-root,
.MuiButtonGroup-contained {
  width: 100% !important;
}

.MuiGrid-root,
.MuiGrid-item,
.MuiGrid-grid-xs-12 {
  width: 100% !important;
}

/* .vs-dark .monaco-scrollable-element>.scrollbar>.slider {
  bac
} */

#vertical-tab-images-tab > p::after {
  border-bottom: 0px !important;
}

@media (max-width: 800px) {
  .menu-btn {
    position: absolute;
    top: 30px !important;
    right: 20px !important;
  }
  .profile-avatar-wrap {
  }
  .profile-avatar-wrap {
    display: block !important;
  }
  /* .main-nav ul li:nth-child(6n) {
    display: none !important;
  } */
  .profile-avatar-wrap {
    display: block !important;
  }
  /* .profile-loggedOutSignedIn-wrap {
    display: none !important;
  } */
  /* .logoIMG {
    height: 25px !important;
    margin: auto;
  } */
  .dhtml-labels-wrap {
    display: none !important;
  }
  #lock-editor {
    display: none !important;
  }
  #password-protect {
    display: none !important;
  }
  #disable-comments {
    display: none !important;
  }
  #disable-remix {
    display: none !important;
  }
  #ar-menu {
    display: none !important;
  }
  #vr-menu {
    display: none !important;
  }
  #live-menu {
    display: none !important;
  }
}

.profile-avatar-wrap {
}

.package-highlight {
  background-color: rgb(45, 42, 43);
}

.package-highlight:hover {
  background-color: rgb(45, 42, 43) !important;
}

.logoIMG {
  height: 58px;
}

/* input[type="text"] {
  height: 23px;
  background-color: #3f3f3e;
  border-radius: 20px;
  font-size: 12px;
  color: #fff !important;
  padding-left: 14px;
  font-family: "quicksand";
  padding-right: 30px;
} */
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff !important;
  opacity: 1; /* Firefox */
  font-weight: bold;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff !important;
  font-weight: bold;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff !important;
  font-weight: bold;
}
.MuiBottomNavigation-root {
  background-color: rgb(24, 24, 24) !important;
}
/* .profile-avatar {
  height: 32px !important;
  width: 32px !important;
} */

.menu-btn {
  position: absolute;
  top: 40px;
  right: 100px;
}
.scrollableDiv {
  max-width: 1470px !important;
  margin: auto;
  background-color: #222222;
}

/* #scrollableDiv {
  overflow-y: scroll;
} */

/* .profile-wrap {
  padding: 45px 30px;
} */

img[src=""],
img:not([src]) {
  visibility: hidden;
}

/* --------------------------- FR Object ------------------------------- */

/* ---------------------------------- Sprite --------------------------- */
.doors-3x,
.doors-2x,
.doors,
.display-3x,
.display-sel-3x,
.draw-3x,
.draw-sel-3x,
.comments-3x,
.comments-sel-3x,
.details-3x,
.details-sel-3x,
.mail-3x,
.rooms-3x,
.rooms-sel-3x,
.view-3x,
.back-arrow-3x,
.display-2x,
.display-sel-2x,
.comments-preview-3x,
.likes-3x,
.play-3x,
.draw-2x,
.draw-sel-2x,
.magnify-3x,
.comments-2x,
.comments-sel-2x,
.details-2x,
.details-sel-2x,
.like-3x,
.mail-2x,
.my-communities-down-3x,
.pricetag-3x,
.room-filters-down-arrow-3x,
.rooms-2x,
.rooms-sel-2x,
.pause-3x,
.view-2x,
.back-arrow-2x,
.lock-3x,
.plus-3x,
.reset-3x,
.comments-preview-2x,
.likes-2x,
.play-2x,
.magnify-2x,
.down-editor-3x,
.like-2x,
.my-communities-down-2x,
.pricetag-2x,
.room-filters-down-arrow-2x,
.display,
.display-sel,
.pause-2x,
.draw,
.draw-sel,
.lock-2x,
.plus-2x,
.reset-2x,
.comments,
.comments-sel,
.details,
.details-sel,
.mail,
.rooms,
.rooms-sel,
.down-editor-2x,
.view,
.back-arrow,
.comments-preview,
.likes,
.play,
.magnify,
.like,
.my-communities-down,
.pricetag,
.room-filters-down-arrow,
.pause,
.lock,
.plus,
.reset,
.down-editor {
  display: inline-block;
  /* background: url("/images/sprite.png") no-repeat; */
  overflow: hidden;
  text-indent: -9999px;
  text-align: left;
}

.doors-3x {
  background-position: -7px -0px;
  background-size: 131px 307px;
  width: 117px;
  height: 108px;
  margin-bottom: 20px;
}
.doors-2x {
  background-position: -10px -325px;
  width: 254px;
  height: 210px;
}
.doors {
  background-position: -10px -545px;
  width: 127px;
  height: 105px;
}
.display-3x {
  background-size: 136px 308px;
  background-position: -93px -108px;
  width: 24px;
  height: 21px;
}
.display-sel-3x {
  background-position: -274px -389px;
  width: 66px;
  height: 54px;
}
.draw-3x {
  background-size: 134px 301px;
  background-position: -90px -149px;
  width: 24px;
  height: 20px;
}
.draw-sel-3x {
  background-position: -274px -517px;
  width: 60px;
  height: 54px;
}
.comments-3x {
  background-size: 129px 313px;
  background-position: -47px -187px;
  width: 18px;
  height: 18px;
  position: relative;
}
.comments-sel-3x {
  background-size: 129px 313px;
  background-position: -68px -199px;
  width: 18px;
  height: 18px;
  position: relative;
}
.details-3x {
  background-size: 134px 305px;
  background-position: -90px -192px;
  width: 20px;
  height: 21px;
}
.details-sel-3x {
  background-size: 134px 305px;
  background-position: -45px -202px;
  width: 22px;
  height: 21px;
}
.mail-3x {
  background-position: -211px -645px;
  width: 54px;
  height: 45px;
  background-size: 133px 288px;
  background-position: -69px -201px;
  width: 20px;
  height: 18px;
}
.rooms-3x {
  background-size: 133px 299px;
  background-position: -89px -209px;
  width: 21px;
  height: 21px;
}
.rooms-sel-3x {
  background-size: 133px 299px;
  background-position: -2px -215px;
  width: 21px;
  height: 21px;
}
.view-3x {
  background-position: -26px -226px;
  background-size: 135px 314px;
  width: 17px;
  height: 14px;
}
.back-arrow-3x {
  background-position: -205px -158px;
  width: 18px;
  height: 19px;
  background-size: 235px 452px;
}
.display-2x {
  background-position: -187px -700px;
  width: 44px;
  height: 36px;
}
.display-sel-2x {
  background-position: -74px -706px;
  width: 44px;
  height: 36px;
}
.comments-preview-3x {
  background-position: -115px -204px;
  background-size: 136px 323px;
  width: 14px;
  height: 17px;
}
.likes-3x {
  background-position: -115px -216px;
  background-size: 137px 313px;
  width: 16px;
  height: 17px;
}
.play-3x {
  background-size: 133px 299px;
  background-position: -110px -222px;
  width: 18px;
  height: 18px;
}
.draw-2x {
  background-position: -241px -709px;
  width: 40px;
  height: 36px;
}
.draw-sel-2x {
  background-position: -10px -724px;
  width: 40px;
  height: 36px;
}
.magnify-3x {
  background-position: -350px -417px;
  width: 30px;
  height: 30px;
  background-size: 193px 349px;
  background-position: -60px -273px;
  width: 26px;
  height: 24px;
  margin: 11px;
  position: relative;
}
.comments-2x {
  background-position: -344px -453px;
  width: 36px;
  height: 36px;
}
.comments-sel-2x {
  background-position: -344px -499px;
  width: 36px;
  height: 36px;
}
.details-2x {
  background-position: -291px -709px;
  width: 36px;
  height: 36px;
}
.details-sel-2x {
  background-position: -337px -743px;
  width: 36px;
  height: 36px;
}
.like-3x {
  background-size: 138px 313px;
  background-position: -59px -254px;
  width: 18px;
  height: 18px;
}
.mail-2x {
  background-position: -344px -545px;
  width: 36px;
  height: 30px;
}
.my-communities-down-3x {
  background-size: 138px 329px;
  background-position: -73px -195px;
  width: 12px;
  height: 10px;
}
.pricetag-3x {
  background-size: 145px 243px;
  background-position: -20px -196px;
  width: 16px;
  height: 14px;
  margin-left: 41px;
}
.pricetag-3x-mobile {
  background-size: 145px 243px;
  background-position: -20px -196px;
  width: 16px;
  height: 14px;
  margin-left: 3px;
}
.room-filters-down-arrow-3x {
  background-position: -82px -267px;
  background-size: 146px 325px;
  width: 12px;
  height: 11px;
}
.rooms-2x {
  background-position: -269px -755px;
  width: 36px;
  height: 36px;
}
.rooms-sel-2x {
  background-position: -10px -770px;
  width: 36px;
  height: 36px;
}
.pause-3x {
  background-position: -106px -777px;
  width: 33px;
  height: 48px;
}
.view-2x {
  background-position: -223px -789px;
  width: 32px;
  height: 24px;
}
.back-arrow-2x {
  background-position: -350px -325px;
  width: 30px;
  height: 30px;
}
.lock-3x {
  background-position: -350px -365px;
  width: 30px;
  height: 42px;
}
.plus-3x {
  background-position: -350px -417px;
  width: 30px;
  height: 30px;
  background-size: 137px 322px;
  background-position: -118px -146px;
  width: 13px;
  height: 12px; /* margin-right: 11px; */
  position: relative;
}
.reset-3x {
  background-position: -315px -789px;
  width: 30px;
  height: 36px;
}
.comments-preview-2x {
  background-position: -149px -792px;
  width: 28px;
  height: 28px;
}
.likes-2x {
  background-position: -56px -798px;
  width: 28px;
  height: 28px;
}
.play-2x {
  background-position: -265px -801px;
  width: 28px;
  height: 32px;
}
.magnify-2x {
  background-position: -355px -789px;
  width: 26px;
  height: 26px;
}
.down-editor-3x {
  background-position: -68px -252px;
  background-size: 146px 292px;
  width: 11px;
  height: 14px;
  margin: 0px 10px -3px;
}
.like-2x {
  background-position: -10px -816px;
  width: 24px;
  height: 24px;
}
.my-communities-down-2x {
  background-position: -221px -823px;
  width: 24px;
  height: 16px;
}
.pricetag-2x {
  background-position: -355px -825px;
  width: 24px;
  height: 24px;
}
.room-filters-down-arrow-2x {
  background-position: -149px -830px;
  width: 24px;
  height: 16px;
}
.display {
  background-position: -94px -835px;
  width: 22px;
  height: 18px;
}
.display-sel {
  background-position: -303px -835px;
  width: 22px;
  height: 18px;
}
.pause-2x {
  background-position: -44px -836px;
  width: 22px;
  height: 32px;
}
.draw {
  background-position: -183px -838px;
  width: 20px;
  height: 18px;
}
.draw-sel {
  background-position: -255px -843px;
  width: 20px;
  height: 18px;
}
.lock-2x {
  background-position: -213px -849px;
  width: 20px;
  height: 28px;
}
.plus-2x {
  background-position: -10px -850px;
  width: 20px;
  height: 20px;
}
.reset-2x {
  background-position: -126px -856px;
  width: 20px;
  height: 24px;
}
.comments {
  background-position: -335px -859px;
  width: 18px;
  height: 18px;
}
.comments-sel {
  background-position: -363px -859px;
  width: 18px;
  height: 18px;
}
.details {
  background-position: -76px -863px;
  width: 18px;
  height: 18px;
}
.details-sel {
  background-position: -285px -863px;
  width: 18px;
  height: 18px;
}
.mail {
  background-position: -156px -866px;
  width: 18px;
  height: 15px;
}
.rooms {
  background-position: -184px -866px;
  width: 18px;
  height: 18px;
}
.rooms-sel {
  background-position: -243px -871px;
  width: 18px;
  height: 18px;
}
.down-editor-2x {
  background-position: -40px -878px;
  width: 16px;
  height: 24px;
}
.view {
  background-position: -149px -777px;
  width: 16px;
  height: 12px;
}
.back-arrow {
  background-position: -10px -880px;
  width: 15px;
  height: 15px;
}
.comments-preview {
  background-position: -187px -673px;
  width: 14px;
  height: 14px;
}
.likes {
  background-position: -313px -887px;
  width: 14px;
  height: 14px;
}
.play {
  background-position: -212px -887px;
  width: 14px;
  height: 16px;
}
.magnify {
  background-position: -126px -835px;
  width: 13px;
  height: 13px;
}
.like {
  background-position: -106px -752px;
  width: 12px;
  height: 12px;
}
.my-communities-down {
  background-position: -241px -700px;
  width: 12px;
  height: 8px;
}
.pricetag {
  background-position: -315px -755px;
  width: 12px;
  height: 12px;
}
.room-filters-down-arrow {
  background-position: -315px -777px;
  width: 12px;
  height: 8px;
}
.pause {
  background-position: -104px -863px;
  width: 11px;
  height: 16px;
}
.lock {
  background-position: -335px -835px;
  width: 10px;
  height: 14px;
}
.plus {
  background-position: -313px -863px;
  width: 10px;
  height: 10px;
}
.reset {
  background-position: -337px -887px;
  width: 10px;
  height: 12px;
}
.down-editor {
  background-position: -76px -836px;
  width: 8px;
  height: 12px;
}

/* ---------------------------------- Sprite --------------------------- */

.ReactModal__Overlay {
  z-index: 99999;
}
.selected {
  color: rgb(48, 184, 82);
}
.selected-background {
  background-color: rgb(27, 178, 67);
  color: white;
}
.notselected {
  color: "#80848C";
}

.ReactTags__tagInputField {
  border: 0px solid #dde0eb;
  border-radius: 6px;
  color: rgb(64, 255, 232);
  font-size: 15px;
  margin-bottom: 1.4rem;
  padding: 1rem;
  font-family: Helvetica, Arial, sans-serif;
  outline: none;
  background-color: rgb(37, 37, 37);
  -webkit-font-smoothing: antialiased;
  -webkit-flex: 1 1;
  flex: 1 1;
  width: 100%;
  margin-top: 6px;
  height: 34px;
}

.tag-wrapper,
.ReactTags__tag {
  border-radius: 6px;
  padding: 5px 12px 5px 15px;
  background-color: #f9fafa;
  border: 1px solid #dde0eb;
  margin-bottom: 20px;
  margin-right: 10px;
  font-size: 15px;
  text-align: center;
}

.ReactTags__remove {
  margin-left: 7px;
}
.ReactTags__selected {
  display: flex;
  flex-wrap: wrap;
}

.rrs__label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rrs--options-visible,
.rrs__options {
  height: 95px !important;
}

.sc-htoDjs {
  color: #a59c9c !important;
}

.tag-wrapper,
.ReactTags__tag {
  font-weight: bold;
  color: rgb(64, 255, 232);
  font-size: 13px;
  background-color: transparent;
  border: 1px solid rgb(64, 255, 232);
  border-radius: 4px;
  padding: 5px 7px;
  position: relative;
  display: flex;

  justify-content: space-between;
  align-items: center;
}

/* The container must be positioned relative: */
.custom-select {
  position: relative;
  font-family: Arial;
}

.custom-select select {
  display: none; /*hide original SELECT element: */
}

.select-selected {
  background-color: DodgerBlue;
}

/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/* style the items (options), including the selected item: */
.select-items div,
.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

/* Style items (options): */
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.dropdown {
  position: relative;
  display: inline-block;
  background-color: rgb(37, 37, 37);
}

.dropdown > input[type="checkbox"] {
  position: absolute;
  left: -100vw;
}

.dropdown > label,
.dropdown > a[role="button"] {
  display: inline-block;
  padding: 6px 15px;
  color: #333;
  line-height: 1.5em;
  text-decoration: none;
  border: 0px solid #8c8c8c;
  cursor: pointer;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.dropdown > label:hover,
.dropdown > a[role="button"]:hover,
.dropdown > a[role="button"]:focus {
  border-color: #333;
}

/* .dropdown > label:after,
.dropdown > a[role="button"]:after {
  content: "\f0d7";
  font-family: FontAwesome;
  display: inline-block;
  margin-left: 6px;
} */

.dropdown > ul {
  position: absolute;
  z-index: 999;
  display: block;
  left: -100vw;
  top: calc(1.5em + 14px);
  border: 1px solid #8c8c8c;
  background: #fff;
  padding: 6px 0;
  margin: 0;
  /* list-style: none; */
  width: 100%;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
}

.dropdown > ul a {
  display: block;
  padding: 6px 15px;
  text-decoration: none;
  color: #fff;
}

.dropdown > ul a:hover,
.dropdown > ul a:focus {
  background: #ececec;
}

.dropdown > input[type="checkbox"]:checked ~ ul,
.dropdown > ul:target {
  left: 0;
}

.dropdown > [type="checkbox"]:checked + label:after,
.dropdown > ul:target ~ a:after {
  content: "\f0d8";
}

.dropdown a.close {
  display: none;
}

.dropdown > ul:target ~ a.close {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  text-indent: -100vw;
  z-index: 1000;
}

/*
 Demo purposes only
*/
*,
*:before,
*:after {
  box-sizing: border-box;
}

.dropdown + h2 {
  margin-top: 60px;
}

/* #nav-lists, li {
  margin:0px 10px
} */

html,
body {
  margin: 0;
}

* {
  box-sizing: border-box;
}

.demo-hidden {
  display: none;
}

.demo-navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  height: 75px;
  background-color: #222;
}

.demo-navbar ul {
  /* list-style: none; */
  margin: 0 auto;
  display: block;
  padding: 0;
}

.demo-navbar ul li {
  display: inline;
}

.demo-navbar ul li a {
  text-indent: -9999px;
  font-size: 0;
  margin: 0 15px;
}

.demo-navbar ul li a svg {
  width: 60px;
  height: 50px;
  pointer-events: all;
  color: #999;
}

.demo-navbar ul li a[data-portsize*="320"] svg {
  width: 14px;
}

.demo-navbar ul li a[data-portsize*="768"] svg {
  width: 20px;
}

.demo-navbar ul li a[data-portsize*="1024"] svg {
  width: 28px;
}

.demo-navbar ul li a[data-portsize*="reset"] svg {
  width: 41px;
}

svg .sg-fill {
  fill: #222;
  transition: fill 0.3s;
}

.demo-navbar ul li svg:hover {
  color: #ccc;
  transition: color 0.3s;
}

.demo-navbar ul li .fr-active svg {
  color: #ccc;
}

.demo-content {
  width: 100%;
  display: block;
  margin: 0 auto;
  background-color: #ccc;
  height: 600px;
  border: 12px solid #ddd;
}

html {
  font-family: quicksand;
}

body {
  margin: 0;
  padding: 0;
}
ul,
li {
  /* list-style: none; */
  margin: 0;
  padding: 0;
}
input[type="button"],
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
}
input[type="file"] {
  position: absolute;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.header .logo {
  margin: 10px 5px;
  width: 180px;
  vertical-align: middle;
}
.header .name {
  font-size: 16px;
  font-weight: bold;
}
.header .menu {
  padding: 10px;
  background-color: #000;
}
.header .menu input {
  opacity: 0;
}
.header .menu img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}
.header .button {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
  border-radius: 5px 5px;
  width: 30px;
  height: 30px;
  border: 0;
  background-color: #fff;
  vertical-align: middle;
}
.header .button.disabled img {
  opacity: 0.5;
}
.tui-image-editor {
  height: 100%;
}
.tui-image-editor-canvas-container {
  margin: 0 auto;
  top: 50%;
  /* transform: translateY(-50%); */
  /* -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%); */
  overflow: hidden;
}
.tui-image-editor-controls {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  background-color: #fff;
}
.tui-image-editor-controls .scrollable {
  display: inline-block;
  overflow-x: auto;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  font-size: 0;
  background-color: #000;
  vertical-align: middle;
}
.tui-image-editor-controls .no-scrollable {
  overflow-x: hidden;
}

.tui-image-editor-controls .menu-button {
  width: 80px;
  height: 80px;
  border: none;
  vertical-align: middle;
  background-color: #000;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  outline: 0;
}
.tui-image-editor-controls .submenu-button {
  width: 80px;
  height: 80px;
  border: none;
  background-color: #ddd;
  vertical-align: middle;
}
.tui-image-editor-controls .hiddenmenu-button {
  margin: 0 10px;
  padding: 5px;
  border: none;
  color: #fff;
  background-color: rgba(255, 255, 255, 0);
}
.tui-image-editor-controls .submenu {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 0;
}
.tui-image-editor-controls .submenu.show {
  display: block;
}

.tui-image-editor-controls .hiddenmenu {
  position: absolute;
  display: none;
  padding: 40px;
  width: 100%;
  left: 0;
  bottom: 80px;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 9999;
}
.tui-image-editor-controls .hiddenmenu.show {
  display: block;
}
.tui-image-editor-controls .hiddenmenu .top {
  font-size: 12px;
  color: #fff;
  margin-bottom: 20px;
}
.tui-image-editor-controls .btn-prev {
  display: inline-block;
  width: 30px;
  height: 80px;
  background-color: #000;
  color: #fff;
  border: none;
  vertical-align: middle;
}
.tui-image-editor-controls .tui-colorpicker-container {
  display: inline-block;
}
.tui-image-editor-controls .msg {
  position: absolute;
  margin-left: 50%;
  padding: 5px 10px;
  left: -86px;
  top: -50px;
  border-radius: 5px 5px;
  background-color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
}
.tui-image-editor-controls .msg.hide {
  display: none;
}

.MuiInputBase-root {
  line-height: 1.7em !important;
  letter-spacing: 0.00938em;
}

.MuiTypography-root,
.MuiCardHeader-title,
.MuiTypography-body2,
.MuiTypography-displayBlock {
  font-size: 14px !important;
}

.MuiTypography-root,
.MuiTypography-body2,
.MuiTypography-colorTextPrimary {
  color: rgb(240, 240, 240) !important;
}

#comment-card-button {
  color: rgb(209, 209, 209) !important;
}

.hidden-scrollbars::-webkit-scrollbar {
  display: none;
}

.hidden-scrollbars {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.MuiFormLabel-root,
.MuiInputLabel-root,
.MuiInputLabel-formControl,
.MuiInputLabel-animated,
.MuiInputLabel-shrink,
.MuiFormLabel-filled {
  font-size: 16px !important;
}

.MuiSwitch-root,
.Component-root-23 {
  margin: 0px !important;
}

.fileContainer .uploadPictureContainer img.uploadPicture {
  height: 40px;
  width: 40px;
}

.fileContainer .deleteImage {
  position: absolute;
  top: -9px;
  right: -9px;
  color: #fff;
  background: #ff4081;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  font-size: 26px;
  font-weight: bold;
  line-height: 30px;
  width: 30px;
  height: 30px;
  display: none !important;
}

.fileContainer .uploadPictureContainer img.uploadPicture {
  height: 120px !important;
  width: 120px !important;
  border-radius: 65px !important;
}

.firebaseui-textfield.mdl-textfield,
.firebaseui-input {
  color: white !important;
}

.MuiList-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: whitesmoke;
}

.content {
  color: #40ffe8;
}

.ldBar {
  position: relative;
}
.ldBar.label-center > .ldBar-label {
  position: absolute;
  top: 50%;
  left: 50%;
  /* -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
  text-shadow: 0 0 3px #fff;
}
.ldBar-label:after {
  content: "%";
  display: inline;
}
.ldBar.no-percent .ldBar-label:after {
  content: "";
}

@media (max-width: 320px) {
  .icon {
    font-size: 0.5rem !important;
  }
  /* .main-nav {
    display: flex !important;
    flex-direction: column !important;
  } */
}

@media (max-width: 700px) {
  .main-nav {
    display: -webkit-flex;
    display: flex;
    /* position: relative; */
    /* width: calc(100% - 50px) !important; */
    -webkit-justify-content: space-between;
    justify-content: space-between;
    margin-top: 0px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    flex-direction: column !important;
  }

  #nav-wrap {
    height: auto !important;
    width: auto !important;
    display: flex;
    align-items: center;
  }
  /* .main-nav {
    padding: 0px 30px !important;
  } */
  /* .scroll-menu-wrap {
    width: calc(100vw - 180px) !important;
    max-width: 100% !important;
  } */
  /* .output_frame {
    padding-top: 55px !important;
  } */
}

.main-title-text {
  color: white;
  font-size: 41px;
  position: relative;

  left: 32px !important;
}

#menu-wrap {
  position: relative;
  height: calc(100vh / 2);

  overflow-y: scroll;
  bottom: 0px;
  width: 100%;
}

#menu-wrap-mobile {
  position: relative;
  height: calc(100vh / 2);
  bottom: 0px;
  width: 100%;
}

@media (max-width: 858px) {
  .section-wrap {
    flex-direction: column !important;
  }
  .section-wrap {
    align-items: center !important;
  }
  .title-section-one-wrap {
    width: 80% !important;
  }
  .fr-landing-grid {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
  }
  .main-title-text {
    color: white;
    font-size: 41px;
    position: relative;

    left: 32px !important;
  }
  .fr-landing-grid-item-1 {
    top: 0px !important;
  }
  .img-wrap {
    top: 30px !important;
    left: 0px !important;
  }
  /* section {
    border: 4px solid #3e3e3e !important;
    padding-top: 40px !important;
  } */
  .main-title-text {
    top: 50px !important;
  }
  .fr-landing-form {
    height: 370px;
    width: 100%;
    max-width: 425px;
    display: flex;
    position: relative;
    top: 70px !important;
  }

  .title-section-one-wrap {
    margin: 100px auto auto !important;
  }
  .screenshots {
    flex-direction: column !important;
    margin: auto !important;
    justify-content: center !important;
    align-items: center !important;
    display: flex !important;
  }
  .screenshot {
    margin-bottom: 5px !important;
    margin-right: 0px !important;
  }
  /* #menu-wrap {
    position: absolute;
    height: calc(100vh / 2);

    overflow-y: scroll;
    bottom: -0px;
    width: 100%;
  } */
}

.screenshot {
  margin-bottom: 0px;
  margin-right: 5px;
}

/* .title-section-one-wrap {

} */

.main-title-text {
  top: 85px;
}

.fr-landing-grid {
  display: grid;
}

.fr-landing-form {
  height: 370px;
  width: 100%;
  max-width: 425px;
  display: flex;
  position: relative;
  top: 34px;
}

.main-title-text {
  height: 242px;
  max-width: 518px;
  width: 100%;
  position: relative;
  color: white;
  font-size: 38px;
  bottom: 30px;
  font-family: quicksand;
}
.img-wrap {
  top: 50px;
  /* left: -35px; */
}

.fr-landing-grid {
  grid-template-columns: 63.4% 37%;
}

.title-section-one-wrap {
  width: 800px;
}

.icon {
  font-size: 0.8rem;
}

#nav-wrap {
  width: 500px;

  margin-bottom: 10px !important;
  display: flex;
}
/* .scroll-menu-wrap {
  width: calc(100vw / 3);
  max-width: 400px;
} */
/* #search-wrap {
  width: 100%;
  background: #3f3f3e;
} */

.rwt__tablist:not([aria-orientation="vertical"])
  .rwt__tab[aria-selected="true"]:after {
  border-bottom: 0px !important;
}

#bio-section {
  -webkit-line-clamp: 2 !important;
}

#name-card-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: 45px 40px;
  left: 0px;
}

.code-wizard-question,
input::placeholder {
  color: #8a8a8a !important;
}

textarea::-webkit-input-placeholder {
  color: #8a8a8a !important;
}

textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #8a8a8a !important;
}

textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #8a8a8a !important;
}

textarea:-ms-input-placeholder {
  color: #8a8a8a !important;
}

textarea::placeholder {
  color: #8a8a8a !important;
}

/* .view-lines,
.monaco-mouse-cursor-text {
  font-weight: normal;
  font-size: 14px !important;
  font-feature-settings: "liga" 0, "calt" 0;
  line-height: 18px;
  letter-spacing: 0px;
  width: 346px;
  height: 152px;
  font-family: quicksand !important;
  font-weight: bold !important;
} */
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.btn-before-click {
  text-decoration: none;
  border: none;
  padding: 12px 40px;
  font-size: 16px;
  background-color: green;
  color: #fff;
  border-radius: 5px;
  box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  outline: none;
  transition: 0.2s all;
}
.btn-after-click {
  /* transform: scale(0.98); */
  /* Scaling button to 0.98 to its original size */
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  /* Lowering the shadow */
}

.fr-landing-grid {
  /* overflow: visible; */
  display: grid;
}

.fr-landing-container {
  width: 100%;
  max-width: 955px;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fr-landing-grid-item-1,
.fr-landing-grid-grid-item-2 {
  position: relative;
  top: 31px;
  display: flex;
  /* justify-content: center;
  flex-direction: column; */
}

.fr-landing-image {
  display: flex;
}

.content {
  background: transparent !important;
}

.fr-landing-showcase {
  padding-left: 20px;
  padding-right: 20px;
  border: 0px !important;
}

.home-selected {
  background-color: transparent !important;
  height: 40px;
  border-bottom: 1px solid white !important;
}

.legend {
  opacity: 1 !important;
  background-color: #a962f1 !important;
}
.carousel-root {
  width: 100% !important;
}

.example {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 50px;
  padding: 0px 50px;
  max-width: 1000px;
  margin: auto;
  width: 100%;
}

@media (max-width: 858px) {
  .example {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 50px;
    padding: 0px 50px;
    max-width: 1000px;
    margin: auto;
    width: 100%;
  }
}

.img-wrap {
  position: relative;

  justify-content: center;
  align-items: center;
  display: flex;
  width: 100% !important;
  max-width: 100vw !important;
  margin: auto !important;
}

.fr-circle-one {
  background: #a962f1;
  height: 120px;
  width: 120px;
  border-radius: 50%;
  position: absolute;
  right: -32px;
  top: 20px;
}
.fr-circle-two {
  background: #f06263;
  height: 170px;
  width: 170px;
  border-radius: 50%;
  position: absolute;
  bottom: -14px;
  left: -44px;
}
.sign-up-form {
  width: 320px;
  background: #3f3f3e;
  border-radius: 15px;
  padding: 15px;
  display: block;
  margin: auto;
  z-index: 1;
}
.sign-up-for-early {
  font-size: 30px;
  color: white;
  font-family: quicksand;
}

.access {
  font-size: 30px;
  color: white;
  font-family: quicksand;
  margin-top: -10px;
}

.today {
  color: #a962f1;
  font-family: quicksand;
  font-size: 30px;
  position: relative;
  top: -10px;
  margin-left: 8px;
}

.sign-up-text-wrap {
  border-radius: 20px;
  background: #4fd975;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 10px;
}

.sign-up-text {
  color: #3f3f3e;
  font-family: quicksand;
  font-size: 25px;
}

.login-text-wrap {
  border-radius: 20px;
  background: #6371f6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-text {
  color: #3f3f3e;
  font-family: quicksand;
  font-size: 25px;
}

.title-section-one-wrap {
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: 180px;
  margin-bottom: 24px;
}

.title-one {
  font-family: quicksand;
  color: white;
  font-size: 38px;
  position: relative;
  margin-bottom: 57px;
  margin: 0px auto;
  width: 1024px;
  max-width: 90%;
  padding: 0rem 0px 5px;
  text-align: center;
}

.section-paragraph {
  color: white;
  font-family: quicksand;
  font-size: 18px;
  text-align: center;
  margin: auto auto 80px;
  max-width: 800px;
  width: 100%;
  padding: 0px 40px;
}

.fr-circle-three {
  background: rgb(79, 217, 117);
  height: 170px;
  width: 170px;
  border-radius: 50%;
  position: absolute;
  bottom: 330px;
  left: 165px;
}

.fr-circle-four {
  background: rgb(99, 113, 246);
  height: 170px;
  width: 170px;
  border-radius: 50%;
  position: absolute;
  left: 287px;
  top: 145px;
}

/* .flows-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.flow-wrap {
  display: flex;
  width: 100%;
  max-width: 937px;
  position: relative;
  bottom: 125px;
  left: 22px;
} */

/* .flow {
  height: 385px;
  width: 317px;
  background: black;
  z-index: 100;
  border-radius: 15px;
  margin-right: 7px !important;
} */

/* .flow {
  width: 380;
  background: black;
  z-index: 100;
  border-radius: 15px;
} */

.flow {
  background: black;
}
.fr-landing-grid-item-1 {
  margin-right: 82px;
}
.flow-circle-flows-wrap {
  height: 328px;
  width: 100%;
  position: relative;
  top: 142px;
}

.supported-by {
  margin-left: 37px;
  /* margin-bottom: 32px; */
  font-size: 27px;
  display: flex;
  height: 57px;
  font-family: "Quicksand" !important;
}
.every-os {
  font-size: 27px;
  bottom: 35px;
  left: 182px;
  position: relative;
  color: #4fd975;
  font-family: "Quicksand" !important;
}
.period {
  font-size: 27px;
  left: 280px;
  bottom: 75px;
  position: relative;
}
.ai {
  font-size: 27px;
  bottom: 34px;
  left: 142px;
  position: relative;
  color: #f06263;
  font-family: "Quicksand" !important;
}
.and-with {
  font-size: 27px;
  left: 170px;
  bottom: 74px;
  position: relative;
  font-family: "Quicksand" !important;
}
.our {
  position: relative;
  left: 35px;
  bottom: 25px;
  font-size: 27px;
  display: flex;
}
.with {
  font-size: 20px;
  left: 27px;
  bottom: 33px;
  position: relative;
}
.flows-are {
  font-size: 27px;
}
.remixable-flows {
  color: rgb(169, 98, 241);
  font-size: 27px;
  margin-top: 0px;
  margin-left: 9px;
  font-family: "Quicksand" !important;
}
.growing-list {
  color: rgb(169, 98, 241);
  font-size: 27px;
  margin-left: 5px;
  font-family: "Quicksand" !important;
}
@media (max-width: 858px) {
  .fr-circle-three {
    background: rgb(79, 217, 117);
    height: 170px;
    width: 170px;
    border-radius: 50%;
    position: absolute;
    bottom: 355px !important;
    left: 75% !important;
  }
  .fr-circle-four {
    background: rgb(99, 113, 246);
    height: 170px;
    width: 170px;
    border-radius: 50%;
    position: absolute;
    left: -77px !important;
    top: 210px !important;
  }
  .flow {
    display: none;
    height: 450px !important;
    width: 100% !important;
    max-width: 350px !important;
    background: black;
    z-index: 100;
    border-radius: 15px;
    margin-bottom: 7px !important;
    position: relative !important;
    overflow: hidden !important;
  }
  .flow-wrap {
    display: flex !important;
    width: 100%;
    max-width: 937px;
    flex-direction: column !important;
    align-items: center !important;
    position: relative;
    left: 0px !important;
  }
  .fr-landing-showcase {
    margin-bottom: 710px !important;
  }
  .fr-landing-grid-item-1 {
    margin-right: 0px !important;
  }
  .flow-circle-flows-wrap {
    height: 328px;
    width: 100%;
    position: relative;
    top: 250px !important;
    max-width: 350px !important;
  }
  .title-section-coders {
    margin: 100px auto auto !important;
    margin-bottom: 80px !important;
  }
  .title-section-download {
    margin: 100px auto auto !important;
    margin-bottom: 80px !important;
  }
  .grid-container-main {
    display: grid;
    grid-gap: 15px 23px;
    /* grid-template-columns: auto auto auto; */
    grid-template-rows: auto auto auto;
    grid-template-columns: repeat(auto-fit, minmax(322px, 1fr));
  }

  .grid-item-main {
    font-size: 30px;
    text-align: center;
  }
  .supported-by {
    margin-left: 28px !important;
    /* margin-bottom: 32px; */
    font-size: 20px !important;
    display: flex;
  }
  .every-os {
    font-size: 20px !important;
    bottom: 25px !important;
    left: 137px !important;
    position: relative;
    color: #4fd975;
    font-family: Quicksand !important;
  }
  .remixable-by-os {
    font-size: 20px !important;
    bottom: 25px !important;
    left: 137px !important;
    position: relative;
    color: #4fd975;
  }
  .period {
    left: 207px !important;
    bottom: 61px !important;
    position: relative !important;
    font-size: 27px !important;
  }
  .and-with {
    font-size: 20px;
    left: 128px;
    bottom: 56px;
    position: relative;
    font-family: "Quicksand" !important;
  }

  .our {
    position: relative;
    left: 21px !important;
    bottom: 25px !important;
    font-size: 20px;
    display: flex;
  }

  .flows-are {
    font-size: 20px !important;
  }

  .growing-list {
    color: rgb(169, 98, 241);
    font-size: 20px !important;
    margin-left: 5px;
    font-family: "Quicksand" !important;
  }

  .remixable-flows {
    color: rgb(169, 98, 241);
    font-size: 20px !important;
    margin-left: 5px;
    margin-top: 0px !important;
    font-family: "Quicksand" !important;
  }
  .flows-text {
    color: rgb(169, 98, 241);
    font-size: 20px;
    margin-left: 5px;
  }
  .with {
    font-size: 20px !important; /* left: 10px; */
    bottom: 32px !important;
    position: relative !important;
    left: 29px !important;
  }
  .ai {
    font-size: 20px;
    bottom: 25px;
    left: 105px;
    position: relative;
    color: #f06263;
  }
  .supported-by {
    margin-right: 20px !important;
  }
}
.feed {
  padding: 20px;
}
.grid-container-main {
  display: grid;
  grid-gap: 15px 23px;
  /* grid-template-columns: auto auto auto; */
  grid-template-rows: auto auto auto;
  grid-template-columns: repeat(auto-fit, minmax(322px, 1fr));
}

.main-wrap {
  max-width: 900px !important;
  margin: auto !important;
  padding-bottom: 100px !important;
}

.main-wrap-profile {
  max-width: 989px !important;
  margin: auto !important;
}

.grid-item-main {
  font-size: 30px;
  text-align: center;
}
@media (max-width: 947px) {
  #display_output_frame {
    width: 385% !important;
    top: -70px !important;
    max-width: 830px !important;
    transform: scale(0.4) !important;
  }
}

@media (max-width: 700px) {
  /* .room-post {
    width: calc(100vw - 40px) !important;
  } */
}

@media (max-width: 858px) {
  #display_output_frame {
    transform: scale(0.5) !important;
  }

  /* .room-post {
    max-width: 443px !important;
  } */
  /* .main-nav {
    padding: 0px 20px !important;
  } */
  .main-wrap {
    max-width: 443px !important;
    margin: auto !important;
  }

  .grid-container-main {
    display: grid;
    grid-gap: 15px 23px;
    /* grid-template-columns: auto auto auto; */
    grid-template-rows: auto auto auto;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr)) !important;
  }
}

@media (max-width: 769px) {
  #display_output_frame {
    width: 385% !important;
    top: 0px !important;
    max-width: 830px !important;
    transform: scale(0.7) !important;
  }

  .remix_overlay_wrap {
    border-radius: 0px !important;
  }
}

@media (max-width: 700px) {
  .feed {
    padding: 0px 20px !important;
  }
}

.css-b8ldur-Input {
  margin: 0px !important;
}

.snap-child {
  max-width: 443px;
  width: 100%;
}

#display_output_frame {
  height: 150%;
  width: 258%;
}

#display_output_frame-2 {
  height: 150%;
  width: 258%;
}

#display_output_frame-3 {
  height: 150%;
  width: 258%;
}

.main-title-text-no-code {
  color: rgb(169, 98, 241);
  font-size: 41px;
  bottom: 30px;
  font-family: quicksand;
  top: 82px;
  right: 24px;
  width: 389px;
  position: relative;
  white-space: nowrap;
}
.flows-easily-shared {
  font-family: quicksand;
  color: white;
  font-size: 38px;
  position: relative;
  margin: 0px auto 0px;
  width: 1024px;
  max-width: 73%;
  padding: 0rem 0px 5px;
  text-align: center;
}

.browse-our-favorite {
  width: 100% !important;
}

@media (max-width: 853px) {
  #display_output_frame-2 {
    height: 117% !important;
    width: 258%;
  }
  #display_output_frame-3 {
    height: 117% !important;
    width: 258%;
  }
  #display_output_frame {
    height: 117% !important;
    width: 258%;
  }
  .main-title-text-no-code {
    color: rgb(169, 98, 241) !important;
    font-size: 41px !important;
    bottom: 30px !important;
    font-family: "Quicksand" !important;
    top: 47px !important;
    right: 25px !important;
    width: 389px !important;
    position: relative !important;
    white-space: nowrap !important;
  }
  .flows-easily-shared {
    font-family: "Quicksand";
    color: white;
    font-size: 38px;
    position: relative;
    margin: 0px auto 0px;
    width: 1024px;
    max-width: 73%;
    padding: 0rem 0px 5px;
    text-align: center;
  }
  .browse-our-favorite {
    width: 59.5% !important;
  }
}

@media (max-width: 414px) {
  #display_output_frame-2 {
    height: 155% !important;
    width: 258%;
  }
  #display_output_frame-3 {
    height: 155% !important;
    width: 258%;
  }
  #display_output_frame {
    height: 155% !important;
    width: 258%;
  }

  .main-title-text {
    height: 242px;
    max-width: 518px;
    width: 100%;
    position: relative;
    color: white;
    font-size: 30px;
    bottom: 30px;
    font-family: quicksand;
    left: 72px !important;
    position: relative;
  }
  .main-title-text-no-code {
    color: rgb(169, 98, 241) !important;
    font-size: 30px !important;
    bottom: 30px !important;
    font-family: quicksand !important;
    top: 51px !important;
    right: 73px !important;
    width: 389px !important;
    position: relative !important;
    white-space: nowrap !important;
  }
  .browse-our-favorite {
    width: 70% !important;
  }
}

@media (max-width: 375px) {
  #display_output_frame-2 {
    height: 170% !important;
    width: 258%;
  }
  #display_output_frame-3 {
    height: 170% !important;
    width: 258%;
  }
  #display_output_frame {
    height: 170% !important;
    width: 258%;
  }
  .browse-our-favorite {
    width: 79.5% !important;
  }
}

@media (max-width: 320px) {
  #display_output_frame-2 {
    height: 200% !important;
    width: 258%;
  }
  #display_output_frame-3 {
    height: 200% !important;
    width: 258%;
  }
  #display_output_frame {
    height: 200% !important;
    width: 258%;
  }
  .fr-landing-form {
    width: calc(100% - 30px) !important;
    margin: auto !important;
  }

  .main-title-text {
    left: 88px !important;
    font-size: 25px !important;
  }
  .main-title-text-no-code {
    font-size: 25px !important;
    right: 88px !important;
  }
  .browse-our-favorite {
    width: 79.5% !important;
  }
}

.main-title-text {
  height: 45px !important;
}
.first-list li {
  list-style-type: disc !important;
  display: list-item !important;
}

#create-video {
  margin: 40px auto 230px;
  display: block;
  max-width: 700px;
  width: 100%;
  transition: all 1.2s cubic-bezier(0.85, 0, 0.15, 1) 0s;
  border: 1px solid rgb(36, 36, 36);
  box-shadow: rgb(0 0 0 / 24%) 0px 9.55893px 19.1179px,
    rgb(0 0 0 / 12%) 0px 9.55893px 4.77946px;
  border-radius: 4px;
  opacity: 1;
  margin-bottom: 0px;
}

#flow-video {
  margin: 40px auto 230px;
  display: block;
  max-width: 700px;
  width: 100%;
  transition: all 1.2s cubic-bezier(0.85, 0, 0.15, 1) 0s;
  border: 1px solid rgb(36, 36, 36);
  box-shadow: rgb(0 0 0 / 24%) 0px 9.55893px 19.1179px,
    rgb(0 0 0 / 12%) 0px 9.55893px 4.77946px;
  border-radius: 4px;
  opacity: 1;
  margin-bottom: 0px;
}

#code-video {
  margin: 40px auto 230px;
  display: block;
  max-width: 700px;
  width: 100%;
  transition: all 1.2s cubic-bezier(0.85, 0, 0.15, 1) 0s;
  border: 1px solid rgb(36, 36, 36);
  box-shadow: rgb(0 0 0 / 24%) 0px 9.55893px 19.1179px,
    rgb(0 0 0 / 12%) 0px 9.55893px 4.77946px;
  border-radius: 4px;
  opacity: 1;
  margin-bottom: 0px;
}

.footer-list ul li {
  list-style-type: none !important;
}

.uneditable-input input[type]:focus {
  outline-color: #6371f6 !important;
  outline-width: 5px !important;
  outline-style: solid;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.carousel.carousel-slider .control-arrow {
  top: 100px !important;
  color: #fff;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 5px;
  height: 280px !important;
}

.carousel.carousel-slider {
  position: relative;
  margin: 0;
  overflow: hidden;
  border-radius: 20px !important;
}

.carousel .carousel-status {
  position: absolute;
  top: 30px !important;
  right: 50px !important;
  padding: 5px;
  font-size: 10px;
  text-shadow: 1px 1px 1px rgb(0 0 0 / 90%);
  color: #fff;
  font-size: 15px !important;
  font-family: "Quicksand" !important;
  font-weight: bolder !important;
}

::-webkit-input-placeholder {
  /* Edge */
  font-family: "Quicksand" !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: "Quicksand" !important;
}

::placeholder {
  font-family: "Quicksand" !important;
}

.read_more {
  display: none;
}

.description-text {
  font-family: "Quicksand" !important;
  margin-top: 15px !important;
  color: white;
  font-size: 16px !important;
  /* font-weight: 800; */
  /* display: -webkit-box; */
  /* -webkit-box-orient: vertical; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  width: 100%;
  max-width: 353px;
  -webkit-line-clamp: 3;
  text-align: left;
  margin: auto;
}


.text {
  width: 100%;
  font-family: "Quicksand";
  font-size: 10px;
  color: white;
  line-height: 20px;
  text-align: left;
}

.read-or-hide {
  color: #4fd975;
  cursor: pointer !important;
}

.read-or-hide-create {
}

/* ::-webkit-scrollbar {
  -webkit-appearance: none !important;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px !important;
  background-color: #fff !important;
} */

[contenteditable] {
  outline: 0px solid transparent !important;
}
/* .cropper-container {
  height: 200px !important;
  width: 200px !important;
} */

.create-module {
  position: relative;
  width: 10em;
  height: 10em;
  border: 3px solid #3cefff;
  overflow: hidden;
  animation: spin 3s ease infinite;
  margin-bottom: 20px;
}

.create-module::before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  width: 10em;
  height: 10em;
  background-color: #6371f6;
  transform-origin: center bottom;
  transform: scaleY(1);
  animation: fill 3s linear infinite;
}

@keyframes spin {
  50%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fill {
  25%,
  50% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

.shareInput:focus {
  outline: 5px solid #6371f6;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
}

.embedInput:focus {
  outline: 5px solid #6371f6;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
}

.searchInput:focus {
  /* outline: 5px solid #6371f6;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px; */
}

.formInputOutline:focus {
  outline: 5px solid #6371f6;
  -webkit-border-radius: 5px;

  -moz-border-radius: 5px;

  border-radius: 5px;
}
.formInputOutline:after {
  border-radius: 30px !important;
}

.firebaseui-error {
  color: #f06263 !important;
  direction: ltr;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  text-align: left;
  font-weight: bold;
}

.firebaseui-textfield,
.mdl-textfield,
.mdl-js-textfield,
.mdl-textfield--floating-label,
.is-upgraded .firebaseui-textfield-invalid {
  font-family: "Quicksand" !important;
  font-weight: bold !important;
}

.MuiFormLabel-root,
.MuiInputLabel-root,
.MuiInputLabel-formControl,
.MuiInputLabel-animated,
.MuiInputLabel-filled {
  font-family: "Quicksand" !important;
  font-weight: bold !important;
}

.Mui-focused {
  border-color: #6371f6 !important;
}

.rwt__tablist[aria-orientation="vertical"]
  .rwt__tab[aria-selected="true"]:after {
  border-right: 0px solid #00d8ff !important;
}

.loader {
  position: relative;
  height: 50px;
  width: 70px;
}

.outer,
.middle,
.inner {
  border: 3px solid transparent;
  border-top-color: white;
  border-right-color: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
}

.outer {
  width: 3.5em;
  height: 3.5em;
  margin-left: -1.75em;
  margin-top: -1.75em;
  animation: spin 2s linear infinite;
}

.middle {
  width: 2.1em;
  height: 2.1em;
  margin-left: -1.05em;
  margin-top: -1.05em;
  animation: spin 1.75s linear reverse infinite;
}

.inner {
  width: 0.8em;
  height: 0.8em;
  margin-left: -0.4em;
  margin-top: -0.4em;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.react-resizable-handle {
  width: 100% !important;
  height: 4px !important;
  box-sizing: border-box !important;
  cursor: se-resize !important;
  z-index: 99999 !important;
  background-color: transparent !important;
}

.triangle {
  border-color: transparent transparent red transparent;
  border-style: solid;
  border-width: 0px 20px 20px 20px;
  height: 0px;
  width: 0px;
  transform: rotate(135deg);
  height: 18px !important;
  width: 20px !important;
}

/* #a {
  background: repeating-linear-gradient(
    -47deg,
    transparent,
    transparent 0px,
    #fff 2px,
    #fff 7px
  );
  width: 16px;
  height: 25px;
}

#b {
  width: 0px;
  height: 0px;
  border-right: 21px solid transparent;
  border-bottom: 27px solid transparent;
  border-top: 25px solid #2d2a2b;
} */

.strip_tri {
  overflow: hidden;
}

.strip_tri::after {
  content: "";
  position: absolute;
  display: block;
  width: 27px;
  height: 0;
  box-shadow: 0 10px 0 1.5px white, 0 15px 0 1.5px white, 0 24px 0 1px black;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg) scale(0.5);
  transform: translate(-50%, -50%) rotate(-50deg) scale(1.5);
  top: 48%;
  left: 70%;
}

.css-1fxhnzr-MuiPaper-root-MuiDrawer-paper {
  background-color: transparent !important;
  border-radius: 10px !important;
}
/* 
.ReactModal__Overlay {
  -webkit-perspective: 600;
  perspective: 600;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
} */

/* .ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity 150ms ease-out;
} */

.ReactModal__Content {
  /* -webkit-transform: scale(0.5) rotateX(-30deg);
  transform: scale(0.5) rotateX(-30deg); */
  transition: transform 600ms cubic-bezier(0.25, 1, 0.5, 1),
    opacity 600ms cubic-bezier(0.25, 1, 0.5, 1);
  transform: translateY(100%);
}

.ReactModal__Content--after-open {
  /* -webkit-transform: scale(1) rotateX(0deg);
  transform: scale(1) rotateX(0deg);
  transition: all 150ms ease-in; */

  transform: translateY(0%);
}

.ReactModal__Overlay--before-close {
  transition: transform 600ms cubic-bezier(0.25, 1, 0.5, 1);
  background: rgba(0, 0, 0, 0) !important;
  transform: translateY(150%) !important;
}

.remix-base {
  transition: transform 600ms cubic-bezier(0.25, 1, 0.5, 1),
    opacity 600ms cubic-bezier(0.25, 1, 0.5, 1);
  transform: translateY(100%);
}

.remix-before {
  transform: translateY(150%);
}

.remix-open {
  /* -webkit-transform: scale(1) rotateX(0deg);
  transform: scale(1) rotateX(0deg);
  transition: all 150ms ease-in; */

  transform: translateY(50%);
}

.iphoneXR {
  position: absolute;
  display: block;
  left: -48px;
  z-index: 1;
  margin-top: 0px;
  border: 0px;
  width: 123%;
  height: 115%;
  top: -28px;
  transform: scale(0.8);
}

.iphoneSE {
  position: absolute;
  display: block;
  left: -44px;
  z-index: 1;
  margin-top: 0px;
  border: 0px;
  width: 123%;
  height: 111%;
  top: -1px;
  transform: scale(0.8);
}

.create-button-create-menu:hover {
  filter: brightness(150%);
  cursor: "pointer";
}

.fr-logo {
  display: block;
}

.create-button-header {
  display: flex;
}

.add-mobile {
  display: none;
}

.question-mobile {
  display: block;
}

.bell-mobile {
  display: block;
}

.create-from-scratch-btn {
  margin-bottom: 5px !important;
  margin-right:18px;
  gap: 10px;
  max-width: 300px;
}

.browse-official-templates {
  /* width: 100%; */
  max-width: 300px;
}

.browse-official-templates {
  width: 100%;
  gap: 10px !important;
  /* max-width: 100% !important; */
}

@media (max-width: 570px) {
  /* .fr-logo {
    display: none !important;
  } */

  .create-button-header {
    display: none !important;
  }

  .add-mobile {
    display: block !important;
  }
  .question-mobile {
    display: block !important;
  }

  .bell-mobile {
    display: block !important;
  }
}

::-webkit-scrollbar-button {
  width: 5px !important;
  height: 5px !important;
  /* min-height: 40px; */
}
#detail-section {
  margin-top: 20px;
}
@media (max-width: 800px) {
  #details {
    display: none !important;
  }
  #detail-section {
    margin-top: 80px !important;
  }
  #made-by-label {
    display: none !important;
  }
  #user-label {
    display: none !important;
  }

  #detail-section {
    height: calc(100% - 270px) !important;
  }
}



@media (max-width: 598px) {
  #create-buttons {
    flex-direction: column;
    width: 100% !important;
  }
  .create-from-scratch-btn {
    max-width: 100% !important;
    margin-bottom: 5px !important;
    margin-right:0px !important;
    width: 100% !important;
    gap: 0px !important;

  }

  .browse-official-templates {
    width: 100%;
    gap: 0px !important;
    max-width: 100% !important; 
  }
}

mark {
  background: none;
  color: rgb(250, 93, 58);
  cursor: pointer;
}

.flow-frame {
  opacity: 0;
  transition: all 300ms ease-in-out !important;
}

.flow_thumbnail .flow-feed-frame {
  /* width: 1440px;
  height: 900px; */
}

.flow_thumbnail {
  position: relative;
  /* -ms-zoom: 0.25;
  -moz-transform: scale(0.25);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.25);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.25);
  -webkit-transform-origin: 0 0; */
  height: 100%;
}
/* 
.flow-thumbnail:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
} */

input:-webkit-autofill {
  background-color: transparent !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px #2d2a2b inset !important;
  color: #fefffe !important;
  -webkit-text-fill-color: #fefffe !important;
  font-weight: bold !important;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.firebaseui-text {
  font-family: quicksand !important;
}

.firebaseui-link {
  font-family: quicksand !important;
}

.mdl-textfield__input {
  font-family: quicksand !important;
}

.firebaseui-info-bar-message {
  font-family: quicksand !important;
  color: #fefffe !important;
}

.firebaseui-info-bar {
  background-color: #2d2a2b !important;
  border: 0px !important;
}
.firebaseui-textfield {
  border: 0px !important;
}

#RemixAITextArea {
  width: 100% !important;
}

.MuiButton-label {
  text-transform: none !important;
  font-size: 15px;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.hidden-scrollbars::-webkit-scrollbar {
  display: block !important;
}

.tags-input-container {
  border: 2px solid #000;
  padding: 0.5em;
  border-radius: 3px;
  width: min(80vw, 600px);
  margin-top: 1em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
}

.tag-item {
  background-color: rgb(218, 216, 216);
  display: inline-block;
  padding: 0.5em 0.75em;
  border-radius: 20px;
}
.tag-item .close {
  height: 20px;
  width: 20px;
  background-color: rgb(48, 48, 48);
  color: #fff;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5em;
  font-size: 18px;
  cursor: pointer;
}

.tags-input {
  flex-grow: 1;
  padding: 0.5em 0;
  border: none;
  outline: none;
}

.MuiInputBase-root,
.MuiOutlinedInput-root .MuiInputBase-formControl,
.MuiInputBase-multiline,
.MuiOutlinedInput-multiline {
  width: 100% !important;
}

.html-tab-selected {
  background-color: rgb(169, 98, 241) !important;
  height: 20px;
  font-size: 18px;
  width: 70px;
  border-radius: 15px;
  border: none;
  margin-right: 10px !important;
}

.css-tab-selected {
  background-color: #6371f6 !important;
  height: 20px;
  font-size: 18px;
  width: 70px;
  border-radius: 15px;
  border: none;
  margin-right: 10px !important;
}

.js-tab-selected {
  background-color: #f06263 !important;
  height: 20px;
  font-size: 18px;
  width: 70px;
  border-radius: 15px;
  border: none;
  margin-right: 10px !important;
}

.fill {
  object-fit: fill;
}

.contain {
  object-fit: contain;
}

.cover {
  object-fit: cover;
  max-width: 500px;
  width: "100%";
}

.scale-down {
  object-fit: scale-down;
}

.post-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #dbdbdb;
  margin-bottom: 1.5rem;
}
.post-card h4 {
  background: #fafafa;
  padding: 0.5rem;
}
.post-card img {
  height: 300px;
  width: 500px;
  object-fit: cover;
}
.Grid {
  border: 1px solid #d9dddd;
}

.GridItem {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f0;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.w-100 {
  width: 100% !important;
}

.d-block {
  display: block !important;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media (min-width: 992px) {
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}
@media (min-width: 1200px) {
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
}

/* .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
} */

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

h1,
h5 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h5 {
  font-size: 1.25rem;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.pulsate {
  animation: pulse 1s linear infinite;
}

@keyframes ldio-f6zo5shqd9o {
  0% {
    transform: translate(6px, 40px) scale(0);
  }
  25% {
    transform: translate(6px, 40px) scale(0);
  }
  50% {
    transform: translate(6px, 40px) scale(1);
  }
  75% {
    transform: translate(40px, 40px) scale(1);
  }
  100% {
    transform: translate(74px, 40px) scale(1);
  }
}
@keyframes ldio-f6zo5shqd9o-r {
  0% {
    transform: translate(74px, 40px) scale(1);
  }
  100% {
    transform: translate(74px, 40px) scale(0);
  }
}
@keyframes ldio-f6zo5shqd9o-c {
  0% {
    background: #a962f1;
  }
  25% {
    background: #4fd975;
  }
  50% {
    background: #6371f6;
  }
  75% {
    background: #f06263;
  }
  100% {
    background: #a962f1;
  }
}
.ldio-f6zo5shqd9o div {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform: translate(40px, 40px) scale(1);
  background: #a962f1;
  animation: ldio-f6zo5shqd9o 1s infinite cubic-bezier(0, 0.5, 0.5, 1);
}
.ldio-f6zo5shqd9o div:nth-child(1) {
  background: #f06263;
  transform: translate(74px, 40px) scale(1);
  animation: ldio-f6zo5shqd9o-r 0.25s infinite cubic-bezier(0, 0.5, 0.5, 1),
    ldio-f6zo5shqd9o-c 1s infinite step-start;
}
.ldio-f6zo5shqd9o div:nth-child(2) {
  animation-delay: -0.25s;
  background: #a962f1;
}
.ldio-f6zo5shqd9o div:nth-child(3) {
  animation-delay: -0.5s;
  background: #f06263;
}
.ldio-f6zo5shqd9o div:nth-child(4) {
  animation-delay: -0.75s;
  background: #6371f6;
}
.ldio-f6zo5shqd9o div:nth-child(5) {
  animation-delay: -1s;
  background: #4fd975;
}
.loadingio-spinner-ellipsis-mu696e7bli {
  width: 48px;
  height: 48px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-f6zo5shqd9o {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.48);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-f6zo5shqd9o div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */


::-webkit-scrollbar-thumb {
  border-radius: 4px !important;
  background-color: rgba(
    124,
    191,
    142,
    0.5
  ) !important; /* Applied your color with opacity here */
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5) !important;
}

#flow-container {
  outline: none !important;
}

/* RC TABS START */

.rc-tabs {
  border: none !important;
}
.rc-tabs-tab {
  padding: 0px;
  text-transform: capitalize;
  padding-right: 15px;
  cursor: pointer;
}

.rc-tabs-tab .rc-tabs-tab-btn {
  font-weight: normal;
}
.rc-tabs-tab-btn {
  padding: 7px !important;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}
.rc-tabs-tab.rc-tabs-tab-active {
  background: #3f3f3e !important;
  color: #fefffe !important;
  font-family: quicksand !important;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  font-weight: bold !important;
}

.rc-tabs-ink-bar {
  background: #59c7fd !important;
}
.rc-tabs-content-holder {
  padding: 10px;
  width: 100%;
  max-width: 772px;
  height: calc(100vh - 570px);
  border-radius: 15px;
  padding-left: 34px;
  padding-right: 20px;
  overflow-y: scroll;
}
.rc-tabs-nav {
  width: 283px !important;
  position: relative;
  top: 0px;
  border-right: 2px solid #3e3e3e;
}

.rc-tabs-nav-list {
  padding-left: 18px;
}

.rc-tabs {
  display: flex;
  flex-direction: row;
}

.rc-tabs-tab.rc-tabs-tab-active {
  color: #fefffe !important;
  font-family: quicksand !important;
  /* border-top-left-radius: 20px; */
  /* border-bottom-left-radius: 20px; */
  font-weight: bold !important;
  width: 236px;
  left: 18px;
  top: 287px;
  background: #323232;
  border-radius: 5px;
  overflow: hidden;
}

.rc-tabs-content {
}
.rc-tabs-tab-btn {
  padding: 7px !important;
  font-family: quicksand !important;
  padding-left: 55px !important;
}

/* .card {
  aspect-ratio: 1 / 1.5; 
  margin-bottom: 50%;
} */
/* responsive image */
.card-thumb {
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
}

.card-thumb {
  /* Initial setup for the image */

  overflow: hidden;
  transition: transform 0.3s ease;
  pointer-events: all;
  z-index: -1;
}

.card-thumb:hover {
  /* Zoom effect on hover */
  transform: scale(1.03);
}

.bg-scrolling-element-when-modal-active {
  /* when modal active */
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  /* Other browsers */
  overscroll-behavior: none;
}
.card {
  overflow: hidden;
}

.react-joyride__tooltip {
  border-radius: 15px !important;
  padding: 0px !important;
}

.flow-step {
  width: 100%;
  max-width: 443px;
}

/* Adding some basic styling to button */

.btn {
  text-decoration: none;
  border: none;
  font-size: 16px;
  background-color: green;
  color: #fff;
  box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  outline: none;
  transition: 0.2s all;
  height: 30px;
  background-color: rgb(79, 217, 117);
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  padding: 0px 15px;
}
/* Adding transformation when the button is active */

.btn:active {
  transform: scale(0.98);
  /* Scaling button to 0.98 to its original size */
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  /* Lowering the shadow */
}

.error-input {
  border: 3px solid rgb(240, 98, 99);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

.block-user-input {
  border: none;

  outline: none;
}

.remixed-by-icon {
  /* margin-bottom: 20px; */
}
.technical-close {
  top: 2px;
  right: 1px;
}
@font-face {
  /* font-family: "NexaBold"; */
  /* src: url("/public/NexaBold.ttf.woff") format("woff"),
    url("/public/NexaBold.ttf.svg#NexaBold") format("svg"),
    url("/public/NexaBold.ttf.eot"),
    url("/public/NexaBold.ttf.eot?#iefix") format("embedded-opentype"); */
  font-weight: normal;
  font-style: normal;
}
.react-tabs__tab-list {
  border-bottom: 0px solid #aaa !important;
}

.blur-it {
  filter: blur(4px);
}

.open-remix-widget-modal {
  animation: fadeIn 1s, slideIn 0.5s linear;

  margin: auto;
  border-radius: 10px;
  border-radius: 7px;
  overflow: hidden;
}

.close-remix-widget-modal {
  animation: fadeOut 1s, slideOut 0.5s linear;

  margin: auto;
  border-radius: 10px;
}

@keyframes slideIn {
  0% {
    transform: translateY(400px);
    animation-timing-function: ease-out;
  }
  60% {
    transform: translateY(-30px);
    animation-timing-function: ease-in;
  }
  80% {
    transform: translateY(10px);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateY(0px);
    animation-timing-function: ease-in;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideOut {
  0% {
    transform: translateY(-400px);
    animation-timing-function: ease-out;
  }
  60% {
    transform: translateY(-30px);
    animation-timing-function: ease-in;
  }
  80% {
    transform: translateY(10px);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateY(0px);
    animation-timing-function: ease-in;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.triangle {
  border-color: transparent transparent red transparent;
  border-style: solid;
  border-width: 0px 20px 20px 20px;
  height: 0px;
  width: 0px;
  -webkit-transform: rotate(135deg);
  transform: rotate(0deg);
  height: 18px !important;
  width: 41px !important;
  position: relative;
  left: 59px;
  bottom: 15px;
}
.triangle-0 {
  width: 60px;
  height: 60px;
  border-bottom: solid 30px rgb(200, 30, 50);
  border-right: solid 30px rgb(200, 30, 50);
  border-left: solid 30px transparent;
  border-top: solid 30px transparent;
}
.triangle-1 {
  width: 60px;
  height: 60px;
  border-bottom: solid 30px rgb(200, 30, 50);
  border-left: solid 30px rgb(200, 30, 50);
  border-right: solid 30px transparent;
  border-top: solid 30px transparent;
}
.triangle-2 {
  width: 30px;
  height: 60px;
  border-right: solid 30px rgb(200, 30, 50);
  border-bottom: solid 30px transparent;
  border-top: solid 30px transparent;
}
.triangle-3 {
  width: 30px;
  height: 60px;
  border-left: solid 30px rgb(200, 30, 50);
  border-bottom: solid 30px transparent;
  border-top: solid 30px transparent;
}
.triangle-4 {
  width: 60px;
  height: 30px;
  border-bottom: solid 30px #1c1c1c;
  border-left: solid 30px transparent;
  border-right: solid 30px transparent;
}
.triangle-5 {
  width: 60px;
  height: 30px;
  border-top: solid 30px rgb(200, 30, 50);
  border-left: solid 30px transparent;
  border-right: solid 30px transparent;
}

.green-button {
  background-color: rgb(79, 217, 117); /* Green background */
  color: white; /* White text */
  border: none; /* Remove border */
  cursor: pointer; /* Add a pointer cursor on hover */
  transition: background-color 0.3s ease; /* Add a transition effect */
  font-weight: bold;
}

.green-button:active {
  background-color: rgb(70 213 109); /* Darker green background */
}
.green-button:hover {
  background-color: rgb(63, 173, 94); /* Darker green background */
}

.red-button {
  background-color: rgb(240, 98, 99); /* red background */
  color: white; /* White text */
  padding: 12px 20px; /* Some padding */
  border: none; /* Remove border */
  cursor: pointer; /* Add a pointer cursor on hover */
  transition: background-color 0.3s ease; /* Add a transition effect */
  font-weight: bold;
}

.red-button:active {
  background-color: rgb(241 84 85); /* Darker red background */
}
.red-button:hover {
  background-color: rgb(231 82 83); /* Darker red background */
}

.purple-button {
  background-color: #a962f1; /* purple background */
  color: white; /* White text */
  padding: 12px 20px; /* Some padding */
  border: none; /* Remove border */
  cursor: pointer; /* Add a pointer cursor on hover */
  transition: background-color 0.3s ease; /* Add a transition effect */
  font-weight: bold;
}

.purple-button:active {
  background-color: rgb(127 57 199); /* Darker red background */
}
.purple-button:hover {
  background-color: rgb(144 64 226); /* Darker red background */
}

.custom-button {
  background-color: transparent !important;
  border: none !important;
  color: inherit !important;
}
.skeleton-loading {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  position: absolute;
  height: 100vh;
  background: #3f3f3e !important;
  top: 0px;
  max-width: 301px;
}

.skeleton-loading__title,
.skeleton-loading__subtitle {
  width: 80%;
  height: 1rem;
  margin-bottom: 1rem;
  background-color: #e5e5e5;
  animation: loading 1s infinite;
}

.skeleton-loading__subtitle {
  width: 60%;
  height: 0.8rem;
}

@keyframes loading {
  0% {
    transform: scaleX(1);
  }
  20% {
    transform: scaleX(0.8);
  }
  100% {
    transform: scaleX(1);
  }
}

/* Icon size and space between icons */
.tabbar-icon {
  display: flex;
  align-items: center;
  font-size: 16px; /* added font size for font awesome icons */
}

/* Bottom tab bar */
.tab-bar {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #1c1c1c !important;
  /* border-top: 1px solid #ddd; */
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%; /* changed to take up full width */
  justify-content: space-between;
  z-index: 1000 !important;
  padding-bottom: 34px !important;
}

.RoomPost {
  position: relative;
  padding-bottom: 100%;
  height: 100%;
  overflow: hidden;
}

.RoomPost iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#create-no-circle {
  background-image: url("/public/images/create.svg") !important;
  background-repeat: no-repeat;
  background-size: contain;
  height: 20px;
  width: 20px;
  margin-right: 20px;
}

#help-circle {
  background-image: url("/public/images/help-circle.svg") !important;
  background-repeat: no-repeat;
  background-size: contain;
  height: 20px;
  width: 20px;
  margin-right: 35px;
}

#header-mail {
  background-image: url("/public/images/chat.svg") !important;
  background-repeat: no-repeat;
  background-size: contain;
  height: 20px;
  width: 20px;
}

#header-info {
  background-image: url("/public/images/header-info.svg") !important;
  background-repeat: no-repeat;
  background-size: contain;
  height: 20px;
  width: 20px;
}

#header-bell {
  background-image: url("/public/images/header-bell.svg");
  background-repeat: no-repeat;
  background-size: contain;
  height: 20px;
  width: 20px;
}

#header-info {
  background-image: url("/public/images/header-info.svg") !important;
  background-repeat: no-repeat;
  background-size: contain;
  height: 20px;
  width: 20px;
}

#nav-wrap {
  display: flex;
}

.header-profile-icon-desktop {
  height: 35px;
  width: 35px;
  left: 0px;
  position: relative;
  top: 0px;
  object-fit: cover;
}

.header-profile-icon {
  height: 30px;
  width: 30px;
  left: 0px;
  position: relative;
  top: 0px;
  object-fit: cover;
}

#search-wrap {
  display: flex;
  width: 100%;
  max-width: 374px;
}

.searchInput {
  display: flex;
  width: 100%;
  max-width: 372px;
  margin-top: 0px;
  top: 0px;
  left: 2px;
  padding: 0px 0px 0px 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 19px;
  height: 42px;
  outline: none;
  background: rgba(28, 28, 28, 0.5);
  border-radius: 10px;
  flex: 0 0 auto;
  order: 0;
  align-self: stretch;
  border: 0;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

#technical {
  width: 100%;
  right: 5px;
}

#menu-se {
  justify-content: space-around;
}

.technical-dropdowm {
  position: absolute;
  background: rgb(50, 50, 50);
  top: 0px;
  border-radius: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  z-index: 10000000;
  padding: 5px 15px;
  -webkit-justify-content: center;
  justify-content: center;
  width: 133px;
  right: 5px;
  box-sizing: border-box;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  border: 1px solid rgb(79, 217, 117);
  color: white;
}




/* #code-wizard-convo {
  max-height: 100% !important;
  height: calc(100vh - 700px);
  margin-bottom: 80px !important;
} */


#technical-step {
  max-width: 130px;
}


.technical-step {
  max-width: 130px;
}
@media only screen and (max-width: 600px) {
  .RoomPost iframe {
    height: 600px;
  }
}

/* Show tab bar on larger screens */
@media only screen and (min-width: 768px) {
  .tab-bar {
    display: flex;
  }
}

/* For devices with a screen width of 375px or less */
@media (max-width: 375px) {
  .flow-wrap-wrap {
    padding-bottom: 0px !important;
  }
}

/* For devices with a screen width of 425px or more */

/* For devices with a screen width of 768px or more */
@media (min-width: 700px) {
  .flow-wrap-wrap {
    padding-bottom: 0px !important;
  }

  .header-create-icon {
    font-size: 30px !important;
  }
}


#search-wrap {
  margin-top: 20px;
}

@media (max-width: 817px) {
  #technical-step {
    max-width: 100% !important;
  }
  
  
  .technical-step {
    max-width: 100% !important;
  }
  .searchInput {
    display: -webkit-flex;
    display: flex;
    width: 100% !important;
    max-width: 100% !important;
    margin-top: 0px !important;
    max-width: 754px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    left: 7px !important;
  }
  .searchInput {
    display: flex;
    width: 100%;
    max-width: 372px;
    margin-top: 0px;
    top: 0px;
    left: 2px;
    padding: 0px 0px 0px 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 19px;
    height: 42px;
    outline: none;
    background: #1c1c1c !important;
    border-radius: 10px;
    flex: 0 0 auto;
    order: 0;
    align-self: stretch;
    border: 0;
    font-family: Quicksand;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }
  #search-wrap {
    display: -webkit-flex !important;
    display: flex !important;
    max-width: 100% !important;
    padding-left: 0px !important;
    margin-bottom: 10px !important;
    margin-top: 5px !important;
    margin: auto;
    margin-left: 0px;
  }

  .feed-select {
    width: 100% !important;
  }
  #menu-se {
    width: 100% !important;
    justify-content: space-between !important;
  }
  .technical-step {
    width: 100% !important;
  }

  #technical {
    width: 100% !important;
  }

  #technical {
    width: 100%;
    right: 0px !important;

    justify-content: space-between !important;
  }
  .technical-dropdowm {
    position: absolute;
    background: rgb(50, 50, 50);
    top: 0px !important;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    z-index: 10000000;
    padding: 4px 12px;
    justify-content: center;
    width: 100%;
    max-width: 245px !important;
    right: 0px !important;
    box-sizing: border-box;
    align-items: flex-start;
    border: 1px solid rgb(79, 217, 117);
    color: white;
  }

  .technical-close {
    top: 2px !important;
    right: 4px !important;
  }
  #save-wrap {
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
  }
 
  .pagination {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* margin-bottom: 124px; */
    position: relative;
    margin-bottom: 30px;
    margin-top: 15px;
    max-width: 500px !important;
    margin: auto !important;
  }
}

@media (max-width: 700px) {
  .flow-wrap-wrap {
    padding-bottom: 0px !important;
  }

  .header-help-icon {
    font-size: 30px !important;
  }
  .header-create-icon {
    font-size: 30px !important;
  }
  .header-mail-icon {
    font-size: 30px !important;
  }
  .header-notification-icon {
    font-size: 30px !important;
  }

  .profile-avatar-wrap {
    height: 24px !important;
    width: 24px !important;
  }
  #remix-desktop {
    display: none !important;
  }
  
  #full_wrap {
    padding-top: 0px !important;
    overflow-y: hidden !important;
  }

  .tab-bar {
    display: flex !important;
  }

  .top-boxes {
    display: none !important;
  }

  .top-box-wrap {
    display: flex;
    flex-direction: "column";
    /* width: "100%"; */
    /* padding-top: 10px !important; */
  }
  #mobile-tab {
    display: block !important;
  }
  .output-container {
    padding: 0px 0px 30px !important;
  }

  .mail-notification-dot {
    height: 12px;
    width: 12px;
    background-color: #f06263;
    border-radius: 50%;
    position: absolute;
    color: white;
    font-family: quicksand;
    justify-content: center;
    align-items: center;
    display: flex;
    top: 0px;
    right: 19px !important;
    border: 2px solid #3f3f3e;
    font-weight: bold;
  }

  .notification-bell-dot {
    height: 12px;
    width: 12px;
    background-color: #f06263;
    border-radius: 50%;
    position: absolute;
    color: white;
    font-family: quicksand;
    justify-content: center;
    align-items: center;
    display: flex;
    top: 0px;
    right: 17.5px !important;
    border: 2px solid #3f3f3e;
    font-weight: bold;
  }

  .header-profile-icon {
    height: 30px !important;
    width: 30px !important;
    position: relative;
  }
  #nav-wrap {
    display: flex !important;
  }
  #menu-se {
    justify-content: space-between !important;
  }
  #technical {
    justify-content: space-between !important;
  }
}

iframe::-webkit-scrollbar {
  width: 0;
  height: 0;
}

iframe {
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
  overflow: -moz-scrollbars-none;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #222;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.showOverlay {
  opacity: 1;
  visibility: visible;
}

#visual-effect {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f00;
  border: none;
}

#visual-effect {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f00;
  border: none;
  transition: width 0.1s, height 0.1s;
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.5);
}

#visual-effect.active {
  width: 70px;
  height: 70px;
  box-shadow: 0 0 20px rgba(255, 0, 0, 1);
}

/* Override the default background color of the editor
.editor-container .monaco-editor-background {
  background-color: #f5f5f5 !important;
} */

/* Override the default font size of the editor */
.editor-container .monaco-editor-font {
  font-size: 14px !important;
}

/* Override the default line numbers background color
.editor-container .monaco-editor .margin {
  background-color: #f5f5f5 !important;
} */

/* Override the default color of the cursor */
.editor-container .monaco-editor .cursor {
  border-color: red !important;
}

/* Override the default color of the selection */
.editor-container .monaco-editor .view-line::selection {
  background-color: yellow !important;
}

.editor-container .monaco-editor .current-line {
  border: none !important;
  box-shadow: none !important;
}

/* Override the default font family and size */
.editor-container .monaco-editor .monaco-editor-font {
  font-family: quicksand !important;
  font-size: 25px !important;
}

/* Style the scrollbar thumb
.editor-container .monaco-editor .scrollbar-thumb {
  background-color: #ffffff !important;
  border-radius: 20px;
  width: 8px !important;
  margin: 0 !important;
  opacity: 1 !important;
  transition: opacity 0.3s ease-in-out;
} */

/* Hide the scrollbar track */
.editor-container .monaco-editor .scrollable-element::-webkit-scrollbar-track {
  background-color: transparent !important;
}

/* Style the scrollbar thumb */
.editor-container .monaco-editor .scrollable-element::-webkit-scrollbar-thumb {
  background-color: #fff !important;
  border-radius: 20px !important;
}

/* Show the scrollbar thumb on hover */
.editor-container
  .monaco-editor
  .scrollable-element::-webkit-scrollbar-thumb:hover {
  background-color: #fff !important;
  border-radius: 20px !important;
}

.vs-dark .monaco-scrollable-element > .scrollbar > .slider {
  position: absolute;
  top: 0px;
  left: 1px !important;
  width: 11px !important;
  contain: strict;
  height: 124px;
  background: white !important;
  border-radius: 10px !important;
  /* margin: auto; */
}

.monaco-scrollable-element > .invisible {
  opacity: 0;
  pointer-events: none;
  height: 0px !important;
}

.ai-trainer-user {
  padding: 10px;
  background-color: #6371f6 !important;
  font-family: "Quicksand";
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 10px;
  color: #222 !important;
  font-size: 14px !important;
  word-break: break-all;
}

.ai-trainer-assistant {
  padding: 10px;
  background-color: rgb(79, 217, 117) !important;
  font-family: "Quicksand";
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 10px;
  margin-bottom: 10px;
  font-size: 14px !important;
  word-break: break-all;
}
@keyframes dot-color {
  0% {
    background-color: rgb(169, 98, 241);
  }
  33% {
    background-color: #6371f6;
  }
  66% {
    background-color: rgb(79, 217, 117);
  }
  100% {
    background-color: rgb(169, 98, 241);
  }
}

.animated-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%; /* Make the divs circular */
  margin-left: 2px;
  margin-right: 2px;
  background-color: rgb(169, 98, 241); /* Set initial background color */
  animation: dot-color 2s linear infinite;
}

.animated-dot-create-menu {
  display: inline-block;
  width: 3px;
  height: 3px;
  border-radius: 50%; /* Make the divs circular */
  margin-left: 2px;
  margin-right: 2px;
  background-color: #000; /* Set initial background color */
  animation: dot-color 2s linear infinite;
}

.syntax-highlighter {
  font-family: quicksand !important;
}

.ai-create-assistant {
  width: 100%;
  border: 2px solid white;
  border-radius: 10px 10px 10px 0px;
  margin-bottom: 10px;
  padding: 5px;
  color: #fff;

  font-family: quicksand;
  list-style: none;
  font-size: 14px;
}

.ai-create-user {
  width: 100%;
  border: 2px solid white;
  border-radius: 10px;
  border-bottom-right-radius: 0px;
  background-color: white;
  padding: 5px;
  margin-bottom: 10px;
  color: #222;

  font-family: quicksand;
  list-style: none;
  font-size: 14px;
}

.ai-create-error {
  width: 100%;
  border: 0px solid white;

  border-radius: 10px 10px 10px 0px;

  background-color: rgb(240, 98, 99);
  padding: 5px;
  color: #222;
  margin-bottom: 10px;
  font-family: 14px;
}

.ai-create {
  display: none;
  background-color: #1c1c1c !important;
}

.room-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 500px);
  position: relative;
}

.room-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(379px, 1fr));
  grid-gap: 33px;
}

.room-grid-create {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(294px, 1fr));
  grid-gap: 15px;
}

.room-container {
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100%;
  /* height: calc(100vh - 170px); */
}

@media only screen and (max-width: 400px) {
  .room-container {
    display: block !important;
    grid-template-rows: auto !important;
    height: auto !important;
  }
 
}

.room-content {
  justify-content: center;
  align-items: center;
  max-width: 1003px;
  width: 100%;
  padding-top: 8px;
}

.no-items {
  text-align: center;
  font-family: quicksand;
  color: white;
  font-size: 17px;
  font-weight: lighter;
}

.pagination {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-top: 0px;
  /* margin-bottom: 124px; */
  position: relative;
  margin-bottom: 30px;
  margin-top: 30px;
  padding-bottom: 20px !important;
}

.red-gradient-border {
  display: inline-block;
  background: linear-gradient(to bottom, #f16262, transparent);
  padding: 1px;
}

.blue-gradient-border {
  display: inline-block;
  background: linear-gradient(to bottom, #0094FF, transparent);
  padding: 1px;
}

.red-gradient-border::after {
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: -1;
  background: linear-gradient(to bottom, #f16262, transparent);
  border: 1px solid transparent;
  background-clip: padding-box, border-box;
  box-sizing: border-box;
}

.purple-gradient-border {
  display: inline-block;
  background: linear-gradient(to bottom, #df5fff, transparent);
  padding: 1px;
}

.purple-gradient-border::after {
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: -1;
  background: linear-gradient(to bottom, #df5fff, transparent);
  border: 1px solid transparent;
  background-clip: padding-box, border-box;
  box-sizing: border-box;
}

.rc-tabs-tab .rc-tabs-tab-btn {
  font-weight: normal;
  color: #ffffff;
}

.monaco-editor,
.monaco-editor-background,
.monaco-editor .inputarea.ime-input {
  background-color: #1c1c1c !important;
}

.bg-home {
  /* Styles for Home component */
}

.chrome-picker {
  width: 225px;
  background: #1c1c1c !important;
  border-radius: 2px;
  box-sizing: initial;
  font-family: quicksand !important;
  box-shadow: none !important;
}

.react-datepicker__header {
  text-align: center;
  background-color: #1c1c1c !important;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}

.react-datepicker__header {
  text-align: center;
  background-color: #1c1c1c !important;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: white;
  font-weight: bold;
  font-size: 0.944rem;
  font-family: quicksand !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: white;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
  font-family: quicksand;
}

.react-datepicker__month {
  background: black;
  font-family: quicksand;
}
.react-datepicker {
  font-family: quicksand !important;
  font-size: 0.8rem;
  background-color: transparent !important;
  color: #000;
  border: 0px solid #aeaeae !important;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker__month {
  background: #1c1c1c !important;
  font-family: quicksand !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: white;
  font-weight: bold;
  font-size: 0.944rem;
  font-family: quicksand !important;
  margin-bottom: 15px !important;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* #root {
  padding-bottom: calc(env(safe-area-inset-bottom) + 170px);
  height: 100%;
  overflow-y: scroll;
  padding-top: 73px;
} */

#nav-wrap {
  width: 500px;
  margin-bottom: 10px !important;
  display: -webkit-flex;
  display: flex;
  margin-top: 19px;
  align-items: center;
}

#menu-se {
  margin-top: 18px;
  margin-right: 15px;
  /* width: 117px; */
}

#technical {
  margin-left: 1px;
}
#feed-space {
  height: 10px;
}

.create-with-ai {
  height: 30px;

  z-index: 1000;
  right: 40px;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
}

.save-btn-create-or-remix {
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}


.save-btn-create-or-remix:hover {
  background-color: #4FD975 !important;
  color: #1c1c1c !important;
}

.save-btn-create-or-remix- {
  transition: all 0.2s ease-in-out !important;
  cursor: pointer;
 
}


.save-btn-create-or-remix-:hover {
  background-color: #4FD975 !important;
  color: #1c1c1c !important;
}

.save-btn-create-or-remix- {
  color:white;
  display: none;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 4px 10px;
  justify-content: space-around;
  font-family: quicksand;
  font-weight: bold;
  font-size: 12px;
  text-decoration: none;
  border-radius: 8px;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  border: 1px solid #1c1c1c !important;
}

.remix-with-widgets {
  height: 30px;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  cursor: pointer;
  max-width: 130px;
  margin-top:10px;
  color:white;
  transition: all 0.2s ease-in-out !important;

}

@media (max-width: 817px) {
  .main-nav {
    display: -webkit-flex;
    display: flex;
    /* position: relative; */
    width: 100%;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    margin-bottom: -2px !important;
    padding-right: 0px;
    max-width: 484px !important;
  }
  #nav-wrap {
    width: 100% !important;
    display: -webkit-flex;
    display: flex;
    margin-top: 19px;
    margin-bottom: 15px !important;
  }

  #menu-se {
    margin-top: 0px !important;
    margin-right: 8px !important;
    margin-left: 0px !important;
  }

  #technical {
    margin-top: 0px;
    margin-left: 1px;
  }
  #feed-space {
    height: 10px !important;
  }

}

.room-post-wrapper {
  display: flex;
  width: 100%; /* Make the wrapper take the full width of the column */
  padding: 5px; /* Adjust the padding as needed */
}

.align-left {
  justify-content: flex-end !important;
}

.align-right {
  justify-content: flex-start !important;
}

.grid-container {
  display: grid;
  grid-gap: 10px;
  padding: 0px;
}

/* Base grid layout */
.grid-container {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

#feed-menu {
  width: calc(100% - 15px);
  background-color: #4fd975;
  top: 64px;
}

.overlay-icons {
  position: absolute;
  bottom: 0px;
  z-Index: 100;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

/* Smaller devices (less than 768px) */
@media only screen and (max-width: 767px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
  #feed-menu {
    width: calc(100% - 8px) !important;
    background-color: #4fd975;
    top: 45px !important;
  }
  /* .create-with-ai {
    height: 30px;
    position: absolute;
    bottom: 525px !important;
    z-index: 1000;
    right: 40px;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex !important;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
  } */

  .overlay-icons {
    position: absolute;
    bottom: 59px !important;
    z-Index: 100;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
 }
}

/* Medium devices (tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

/* Large devices (desktops, 1024px and up) */
@media only screen and (min-width: 1024px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

/* html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
} */

.card-thumb {
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  transform: scale(1);
  cursor: pointer;
}
html,
body {
  margin: 0;
}
html {
  width: 100%;
}
body {
  overscroll-behavior: none;
}

iframe {
  overflow: hidden;
}

#remix-widget {
  padding: 0px;
  background: #1c1c1c;
  border: 1px solid #ddd;
  background: rgb(28, 28, 28);
  border: 0px solid rgb(221, 221, 221);
  transform: translate(202px, 26px);
  height: calc(100vh - 200px);
  width: 270px;
  border-radius: 20px;
  overflow: hidden;
  cursor: move;
}

html {
  scroll-behavior: smooth;
  position: absolute;
  height: -webkit-fill-available;
}


.floating-label {
  position: relative;
  margin-top: 20px;
}

.floating-label input[type="text"] {
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  padding: 10px;
  display: block;
  width: 100%;
  outline: none;
}

.floating-label label {
  position: absolute;
  top: 10px;
  left: 10px;
  background: white;
  transition: all 0.2s ease;
  pointer-events: none;
  color: #999;
}




.floating-label.focused label,
.floating-label input[type="text"]:not(:placeholder-shown) + label {
  top: -10px;
  left: 10px;
  font-size: 12px;
  color: #777;
  padding: 0 4px;
}

.floating-label input[type="text"]:focus {
  border-color: blue;
}


.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.input-container label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 23px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: white;
  font-size: 15px;
  line-height: 0.5;
  left: 10px;
  bottom: 43px;
  font-family: quicksand;
}

.input-container:focus-within label,
.input-container label.filled {
  transform: translate(0, 12px) scale(0.8);
  color: white;
}

.custom-select-wrapper {
  position: relative;
  width: 100%; /* Match the width of the select */
}

.custom-select-wrapper::after {
  content: ''; /* You can also use a character or icon like '▼' */
  position: absolute;
  right: 10px; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Makes sure clicks on the arrow still open the select */
  /* Add your arrow styling here, e.g., background-image or font icon */
}

@keyframes dot {
  0% { content: '.'; }
  33% { content: '..'; }
  66% { content: '...'; }
}

.dot-animation::after {
  animation: dot 1.5s infinite;
  content: '';
}


@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.dot {
  animation: blink 1.4s infinite both;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

/* #message-wrap {
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  margin-top: 2px !important;
  padding-top: 10px;
} */
@media (max-width: 571px) {
/* 
#message-wrap {
  height: 100%;
  overflow: hidden;
  margin-top: 2px !important;
  padding-top: 10px;
} */

}


::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}

.read-more-container {
  font-family: "Quicksand", sans-serif;
  font-weight: 800;
  font-size: 14.9118px;
  word-break: break-word; /* Adjusts word breaking behavior */
  color: white;
  display: block; /* Adjust as necessary for your layout */
  max-width: 100%; /* Ensure the container does not overflow its parent */
}

.read-or-hide {
  cursor: pointer;
  margin-left: 5px;
  font-weight: bold;
}


.read-more-container {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  max-width: 100%;
}


.message-container, .message-list-container, .message-wrap-wrap, .message-wrap {
  max-width: 700px;
  margin: auto;
  position: relative;
  width: 100%;
  height: 94vh; /* Adjust based on your needs */
  overflow: hidden; /* Consider changing to 'auto' for the actual scrollable container */
}

.message-list {
  overflow-y: auto; /* Ensure this is scrollable */
  flex-grow: 1; /* Allows this element to grow and fill the space */
}
.message-list-container {
  display: flex;
  flex-direction: column;
  height: 90vh; /* Adjust based on your layout */
  padding-bottom: 100px; /* Reserve space for the message input area */
}



.top-section {
  height: 70px; /* Fixed height */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.message-list {
  flex-grow: 1; /* Take up all available space */
  overflow-y: auto; /* Scroll if content overflows */
  background-color: #e0e0e0; /* Just for visibility */
  margin: 0; /* Remove default margin */
  padding: 0 10px; /* Adjust padding as needed */
  list-style: none; /* Remove default list styling */
}

.message-list li {
  margin-bottom: 10px; /* Space between messages */
}


.mainContent {
  height:100%;
  width:100%;
}

.iframe-container {
  margin-bottom: 30px;
  margin-right: 25px;
  margin-left: 25px;
  margin-top: 25px;
}

.appContainer {
  display: flex;
  flex-direction: row;
  height:calc(100vh - 0px);
}

.mainContent {
  flex-grow: 1;
  transition: margin-left 0.3s ease-out;
}

/* Existing styles for .panelOpen */
.panelOpen {
  margin-left: 299px;
  transition: margin-left 0.3s ease-out;
}


@media (max-width: 767px) {
  .appContainer {
    display: flex;
    flex-direction: row;
    height: auto !important;
  }
}



/* Media query for viewport widths less than 768px */
@media (max-width: 767px) {
  

  .panelOpen {
    margin-left: 0; /* Adjust as needed for mobile resolutions */
  }

  .iframe-container {
    margin-bottom: 0px !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    margin-top: 0px !important;
  }

}

#preview-bar {

}
.MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 2.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
  color: white;
}

.MuiCardHeader-avatar {

}

.item:empty {
  display: none;
}

.MuiCardHeader-avatar {
  flex: 0 0 auto;
  margin-right: 0px !important;
}

button.scroll-to-bottom {
  transition: opacity 0.3s ease-in-out;
  opacity: 0;  
  visibility: hidden; 
}

button.scroll-to-bottom.show {
  opacity: 1;
  visibility: visible; 
}

body, html {
  margin: 0;
  touch-action: manipulation; /* Disables pinch-zoom */
}


.rbc-month-view {
  position: relative;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  height: 100%;
  /* margin: 20px; */
  padding: 0px;
  background: #5B5AD8 !important;
  font-family: quicksand;
  color: white;
}

.rbc-calendar {
  height: 100%;
    min-height: 400px;
}

.rbc-toolbar {
  margin-top: 20px;
  margin-left: 25px;
  margin-right: 25px;
  display: flex;
  flex-direction: column;
  color:white;
  font-family: quicksand;
}

.rbc-toolbar button {
  color: #373a3c;
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: 1px solid #ccc;
  padding: 0.375rem 1rem;
  border-radius: 4px;
  line-height: normal;
  white-space: nowrap;
  background: #ffff !important;
  font-family: quicksand !important;
}

.rbc-month-view {
  position: relative;
  border: 0px solid #ddd !important;
}

.rbc-month-view {
  position: relative;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  height: 100%;
  max-width: 400px;
  margin: auto;
  min-height: 200px !important;
  margin-bottom: 35px !important;
  overflow: hidden !important;
  border-radius: 5px;
}

.room-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(308px, 1fr));
  grid-gap: 40px;
  width: 100%;  
  box-sizing: border-box;
}


@media (max-width: 768px) {
  .room-grid {
      min-height: calc(100vh - 270px); /* Smaller subtraction for smaller screens */
  }
}

@media (max-width: 480px) {
  .room-grid {
      min-height: calc(100vh - 50px); /* Even smaller subtraction for very small screens */
      /*grid-template-columns: 1fr;*/ /* Simplify to one column on very small screens */
  }
}

.language-html {
  font-family: quicksand !important;
  font-size: 14px;
}

.overlay-icons {
  position: absolute;
  bottom: 0;
  z-Index: 100;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

@keyframes dotFlashing {
  0% { background-color: #1c1c1c; }
  50%, 100% { background-color: transparent; }
}

.animated-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 25px;
  /* font-size: 10px; */
}

.animated-dots div {
  width: 6px;
  height: 6px;
  margin: 0 2px;
  border-radius: 50%;
  background-color: #1c1c1c;
  animation: dotFlashing 1s infinite linear alternate;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  color: #4FD975 !important;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
  font-weight: bold !important;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #476d8a !important;
  color: rgb(0, 0, 0);
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  margin-top: 0;
  color: #4FD975 !important;
  font-weight: bold;
  font-size: 0.944rem;
}

.invite-spinner {
  border: 4px solid rgba(255,255,255,0.2);  
  border-radius: 50%;
  border-top: 4px solid #000;  
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  position: absolute;
  right: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

input:disabled {
  background-color: rgb(37 36 36) !important; /* Light gray background */
  color: #a0a0a0 !important;            /* Dimmed text color */
  border: 0px solid #d0d0d0 !important; /* Light gray border */
  cursor: not-allowed !important;       /* Cursor indicating the field is not interactive */
}

.animated-dot-red {
  display: inline-block;
  width: 3px;
  height: 3px;
  margin-left: 4px;
  background-color: rgb(240, 98, 99);
  border-radius: 50%;
  opacity: 0;
  animation: fade 1.5s infinite;
}

.animated-dot-red:nth-child(1) {
  animation-delay: 0s;
}

.animated-dot-red:nth-child(2) {
  animation-delay: 0.3s;
}

.animated-dot-red:nth-child(3) {
  animation-delay: 0.6s;
}



.animated-dot-white {
  display: inline-block;
  width: 3px;
  height: 3px;
  margin-left: 4px;
  background-color: white;
  border-radius: 50%;
  opacity: 0;
  animation: fade 1.5s infinite;
}

.animated-dot-white:nth-child(1) {
  animation-delay: 0s;
}

.animated-dot-white:nth-child(2) {
  animation-delay: 0.3s;
}

.animated-dot-white:nth-child(3) {
  animation-delay: 0.6s;
}


@keyframes fade {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}


/* LoadingSpinner.css */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-spinner {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #4FD975; /* Blue */
  border-radius: 50%;
  width: 35px;
  height: 35px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


body {
  height:100vh !important;
}

/* styles.css */

.feed-menu {
  position: absolute;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  padding: 10px;
  justify-content: space-around;
  left: 0;
  background-color: rgb(79, 217, 117); /* Add background for visibility */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add shadow */
  top:50px;
  width:100%;
}

.feed-menu.hidden {
  display: none;
}

.feed-option {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
}

.feed-option:hover {
  background-color: #f0f0f0;
}

.feed-option.selected {
  background-color: #d0e6ff; /* Highlight color for selected option */
  color: #004085;
  font-weight: 600;
}

.select-feed-option {
  margin-left: 8px;
  font-family: "Quicksand", sans-serif;
  font-style: normal;
  font-weight: 500;
}

.feed-menu-container {
  max-width:129.41px;
}

@media (max-width: 817px) {
  .feed-menu-container {
    max-width:100% !important;
  }
}
/* PublishContent.css */

/* Override SimpleBar's scrollbar visibility */
#publish-content .simplebar-scrollbar::before {
  opacity: 1 !important; /* Always show scrollbar thumb */
}

#publish-content .simplebar-scrollbar.simplebar-visible::before {
  opacity: 1 !important;
}

/* Customize scrollbar thumb */
#publish-content .simplebar-track.simplebar-vertical .simplebar-scrollbar::before {
  background-color: #888; /* Scrollbar thumb color */
}

/* Customize scrollbar track */
#publish-content .simplebar-track.simplebar-vertical {
  width: 12px; /* Scrollbar width */
}

/* Hover effect */
#publish-content .simplebar-scrollbar:hover::before {
  background-color: #555;
}

/* Optional: Make scrollbar thicker */
#publish-content .simplebar-track.simplebar-vertical {
  width: 16px;
}

/* Optional: Change thumb color to blue */
#publish-content .simplebar-scrollbar::before {
  background-color: #007BFF;
}

.code-box {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #3f3d3d;
}

.code-box-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  font-weight: bold;
}

.copy-button {
  background-color: #6371f6;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
}

.copy-button:hover {
  background-color: #525edb;
}

.code-box-content {
  font-family: quicksand;
  background-color: #212123;
  padding: 10px;
  border-radius: 3px;
  overflow-x: auto;
  color:white;
}


ul#code-wizard-convo {
  list-style: none;
  padding: 0;
  width:100%;
}

li.assistant {
  margin: 10px 0;
}

li.code-block {
  margin: 10px 0;
}

.code-box {
  background-color:#3f3d3d;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  margin:10px;
}

.code-box-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  margin-bottom: 5px;
}

.code-box-content {
  white-space: pre-wrap;
  font-family: quicksand;
  overflow-x: auto;
  color:white;
}

.copy-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}

.copy-button:hover {
  background-color: #0056b3;
}

.hidden {
  display:none;
}

.loading-dots span {
  animation: blink 1.5s infinite;
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.5s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 1s;
}

@keyframes blink {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.auth-modal {
  max-height: 678px; /* Default for desktop */
  overflow-y: scroll; /* Enables scrolling */
  width: 100%; /* Adjust width as needed */
  border-radius: 10px; /* Optional styling */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Optional shadow for modals */
  position: relative; /* Ensures the scrollbar hack is contained within the modal */
}

/* Scrollbar container for persistent scrollbar */
.auth-modal::after {
  content: ""; /* Pseudo-element to force scrollbar */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 0px; /* Same as the scrollbar width */
  background: white; /* Matches scrollbar thumb color */
  pointer-events: none; /* Allows interaction with the real scrollbar */
}

/* WebKit Scrollbar Styles */
.auth-modal::-webkit-scrollbar {
  width: 0px; /* Set scrollbar width */
}

.auth-modal::-webkit-scrollbar-track {
  background: transparent; /* Transparent scrollbar track */
}

.auth-modal::-webkit-scrollbar-thumb {
  background: white; /* White scrollbar thumb */
  border-radius: 5px; /* Rounded scrollbar corners */
}

.auth-modal::-webkit-scrollbar-thumb:hover {
  background: #e0e0e0; /* Slightly darker thumb on hover */
}

/* For tablets (e.g., 768px wide) */
@media (max-width: 768px) {
  .auth-modal {
    max-height: 620px; /* Adjust as needed */
  }
}

/* For larger devices or landscape mode (1024px wide or higher) */
@media (min-width: 1024px) {
  .auth-modal {
    max-height: 678px; /* Desktop value */
  }
}

/* For medium phones (e.g., 414px wide) */
@media (max-width: 414px) and (max-height: 736px) {
  .auth-modal {
    max-height: 600px; /* Adjust as needed */
  }
}

/* For iPhone 375x667 and similar resolutions */
@media (max-width: 375px) and (max-height: 667px) {
  .auth-modal {
    max-height: 555px; /* Specific height for iPhone 375x667 */
  }
}

/* For small phones (320px wide, e.g., iPhone SE) */
@media (max-width: 320px) {
  .auth-modal {
    max-height: 500px; /* Adjust as needed */
  }
}

.auth-modal-wrapper {
  overflow: visible; /* Allow child elements to scroll */
}
.custom-scrollbar {
  pointer-events: none; /* Does not block normal scroll interactions */
}

.auth-modal {
  max-height: 100%; /* Constrained height */
  overflow-y: scroll; /* Enables vertical scrolling */
}


.custom-scrollbar {
  position: absolute;
  top: 10px;
  right: 7px;
  width: 10px; /* Adjust scrollbar width */
  background: white; /* Scrollbar color */
  border-radius: 5px; /* Optional rounded corners */
  pointer-events: all; /* Enable dragging */
  z-index: 1; /* Ensure it appears above content */
  visibility: hidden; /* Always visible */
  transition: transform 0.1s linear, height 0.1s linear; /* Smooth scrollbar movement */
  cursor: grab; /* Indicates draggable behavior */
}

.custom-scrollbar:active {
  cursor: grabbing;
}


/* Media Query: For tablets (e.g., 768px wide) */
@media (max-width: 768px) {
  .auth-modal-wrapper {
    max-height: 620px; /* Adjust for tablets */
  }
  .auth-modal {
    max-height: 620px;
  }
}

/* Media Query: For iPhone 375x667 */
@media (max-width: 375px) and (max-height: 667px) {
  .auth-modal-wrapper {
    max-height: 555px; /* Adjust for iPhone */
  }
  .auth-modal {
    max-height: 555px;
  }
}

/* Media Query: Small phones (e.g., iPhone SE) */
@media (max-width: 320px) {
  .auth-modal-wrapper {
    max-height: 500px; /* Adjust for small phones */
  }
  .auth-modal {
    max-height: 500px;
  }
}

.auth-modal-wrapper {
  position: relative;
  width: 100%;
  max-height: 678px;
  overflow: hidden;
}





.custom-scrollbar:active {
  cursor: grabbing;
}



/* Spinner CSS */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader {
  margin-right: 5px;
  border: 2px solid #f3f3f3; 
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spin 1s linear infinite;
}
