.sidebarMenu {
  width: 77px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: #1c1c1c;
  border-right: 2px solid #323232;
  padding-top: 18px;
  
  margin-top: 64px;
}

.tab {
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 14px;
}

.tab img {
  width: 100%;
  height: auto;
}

.selected {
  background-color: #323232;
  border-radius: 7px;
  padding: 7px;
}

.container {
  display: flex;
}

.sidebarMenu {
  /* width: 78px; */
  /* Other styles */
}

.mainContent {
  flex-grow: 1;
  min-width: 0; /* Ensures it can shrink, but you might want a specific min-width */
}



.slidePanel {
  width: 300px;
    background-color: #1c1c1c;
    position: absolute;
    bottom: 0;
    top: 64px;
    bottom: 0;
    left: 76px;
    height: calc(100vh - 64px);

}

.closeButton {
  right: 19px;
    position: absolute;
    top: 33px;
    height: 20px;
    width: 20px;
    cursor: pointer;
    z-index: 1000;
}

/* SidebarMenu.module.css */
.detailsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.detailsInnerContainer {
  background-color: rgb(63, 63, 62);
  padding: 20px;
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100vh;
}

.detailsTitle {
  color: white;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}

.closeButton {
  display: block;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  background-size: contain;
  height: 20px;
  width: 20px;
  margin-top: 15px;
  margin-right: 10px;
}

.iconContainer {
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  flex-direction: column;
  top: 200px;
  margin-left: 30px;
}

.icon {
  display: flex;
  margin-bottom: 10px;
  font-size: 70px;
  position: relative;
  bottom: 20px;
}

.errorMessage {
  position: relative;
  font-family: "Quicksand";
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 243px;
  font-size: 16px;
  left: 0;
  text-align: center;
}

/* Add these styles to SidebarMenu.module.css */
.spinner {
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 3px solid rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
}

.spinnerInner {
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.slidePanelContent {
  opacity: 1;
  transition: opacity .5s ease-out;
}

.slidePanelContent.loading {
  opacity: 0;
}

.desktopContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #1c1c1c;
  width: 100%;
  position: relative;
}

.desktopOverlayPanel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  background: #1c1c1c;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.desktopButtonContainer {
  display: flex;
  justify-content: space-between;
  padding: 0 0px;
  font-family: quicksand;
  color: rgb(28, 28, 28);
  font-size: 10px;
  font-weight: bold;
  margin-top:45px;
}

.desktopContentArea {
  overflow-y: auto;
  margin-top: 25px;
  font-family: 'quicksand';
  padding-bottom: 57px;
}

.toggleButton, .ellipsisButton {
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 24px;
  z-index: 1050;
  margin-right: 3px;
  margin-top: 0px;
}

.toggleButton {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #4FD975;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlayList {
  transition: all 0.3s ease-in-out;
}

ul {
  list-style-type: none;
  padding: 0;
}




/* Media query for viewport widths less than 768px */
@media (max-width: 767px) {
  .panelOpen {
    margin-left: 0 !important; /* Adjust as needed for mobile resolutions */
  }
  .container {
    display:none !important;
  }
}




/* Adjust for the panel to slide out to the left */
/* .panel-exit {
  transform: translateX(0);
} */

/* .panel-exit-active {
  transform: translateX(-100%);
} */
