.box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.object {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.outline {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 8px solid #B5A4A4;
    position: absolute;
}

.outline.animate {
    animation: pulse 3s ease-out infinite;
}

.delayed {
    animation-delay: 1.5s;
}

.button {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: #50CDDD;
    box-shadow: 0 0 80px #0084F9;
    position: absolute;
}

#circlein {
    width: 100px;
    height: 100px;
    background: #6BD6E1;
    box-shadow: 0 -2px 15px #E0FF94;
}

@keyframes pulse {
    0% {
        transform: scale(0);
        opacity: 0;
        border: 65px solid #0B3082;
    }
    50% {
        border-color: solid #A3FFC2;		
        opacity: 0.8;
    }
    90% {
        transform: scale(3.2);
        opacity: 0.2;
        border: 3px solid #2E3CFF;
    }
    100% {
        transform: scale(3.3);
        opacity: 0;
        border: 1px solid #7A89FF;
    }
}
