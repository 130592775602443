.firebaseui-idp-facebook,
.firebaseui-idp-facebook:hover,
.mdl-button.firebaseui-idp-facebook:active,
.mdl-button.firebaseui-idp-facebook:focus {
  background-color: #3b5998 !important;
  overflow: inherit !important;
  position: relative !important;
  font-family: IBMPlexSans, sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: 0.5px !important;
  line-height: 32px !important;
  color: #fff !important;
  display: inline-block !important;
  border: none !important;
  text-align: center;
  /* background: #0079d3; */
  text-transform: uppercase !important;
  cursor: pointer !important;
  line-height: unset !important;
  height: 50px !important;
  /* min-height: 35px; */

  padding: 5px 10px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  display: flex !important;
  justify-content: none !important;
  align-items: center !important;
}

.firebaseui-idp-google,
.firebaseui-idp-google:hover,
.mdl-button.firebaseui-idp-google:active,
.mdl-button.firebaseui-idp-google:focus {
  background-color: #f06263 !important;
  overflow: inherit !important;
  position: relative !important;
  font-family: IBMPlexSans, sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: 0.5px !important;
  line-height: 32px !important;
  height: 37px !important;
  color: #222222 !important;
  display: inline-block !important;
  border: none !important;
  text-align: center;
  /* background: #0079d3; */
  text-transform: uppercase !important;
  cursor: pointer !important;
  line-height: unset !important;
  min-height: 35px !important;
  width: 100% !important;
  /* min-width: 155px; */
  padding: 5px 10px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  display: flex !important;
  align-items: center !important;
  justify-content: none !important;
}

.firebaseui-idp-password {
  background-color: #f06263 !important;
  height: 50px !important;
  display: flex !important;
  justify-content: none !important;
  align-items: center !important;
  padding: 5px 10px !important;
}

.firebaseui-idp-text {
  text-transform: uppercase !important;
}

.firebaseui-container {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.firebaseui-card-content {
  padding: 0 !important;
}

.firebaseui-idp-list {
  margin-bottom: 0 !important;
}

.firebaseui-relative-wrapper {
  /* padding-left: 20px !important;
  padding-right: 20px !important; */
}

.firebaseui-card-content {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.firebaseui-card-header {
  border-bottom: 0px solid #e0e0e0 !important;
  margin-bottom: 16px;
  padding: 16px 20px 0 !important;
}
@media (max-width: 480px) {
  .firebaseui-card-header {
    border-bottom: 0px solid #e0e0e0 !important;
    margin-bottom: 16px;
    padding: 16px 20px 0 !important;
  }
}
.firebaseui-text {
  color: white !important;
  direction: ltr;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  margin-bottom: 20px !important;
}
.firebaseui-idp-facebook,
.firebaseui-idp-facebook:hover,
.mdl-button.firebaseui-idp-facebook:active,
.mdl-button.firebaseui-idp-facebook:focus {
  height: 55px !important;
}

.firebaseui-idp-google,
.firebaseui-idp-google:hover,
.mdl-button.firebaseui-idp-google:active,
.mdl-button.firebaseui-idp-google:focus {
  height: 37px !important;
  padding: 5px 10px !important;
  justify-content: none !important;
}
.firebaseui-idp-password,
.firebaseui-idp-password:hover,
.mdl-button.firebaseui-idp-password:active,
.mdl-button.firebaseui-idp-password:focus {
  background-color: #db4437;
  height: 55px !important;
  font-weight: 800 !important;
}

.PrivateNotchedOutline-root-8 {
  /* top: -12px !important; */
}
@media (max-width: 368px) {
  .firebaseui-idp-text.firebaseui-idp-text-long {
    display: none !important;
  }

  .firebaseui-idp-text.firebaseui-idp-text-short {
    display: block !important;
  }

  .signIn {
    font-size: 16px !important;
  }

  #profile-wrap {
    position: relative;
    border-radius: 50%;
    width: 30% !important;
    background: white;
    justify-content: center;
    align-items: center;
  }

  .signup-screen-label {
    font-size: 17px !important;
  }
}
.firebaseui-idp-button {
  border-radius: 4px !important;
}
.firebaseui-subtitle,
.firebaseui-title {
  color: #fff !important;
  font-family: "Arial", sans-serif !important;
  font-size: 18px !important;
}
.firebaseui-card-actions {
  position: relative !important;
  top: 30px !important;
}

.firebaseui-text-input-error {
  margin-top: 15px !important;
}
.firebaseui-textfield.mdl-textfield,
.firebaseui-input {
  color: white !important;
}
.firebaseui-input-floating-button,
.firebaseui-id-password-toggle,
.firebaseui-input-toggle-on,
.firebaseui-input-toggle-blur {
  margin-right: 10px !important;
  color: #9c9c9c !important;
}

.firebaseui-idp-button {
  width: 100% !important;
}

#firebaseui_container {
  margin-bottom: 40px !important;
  /* max-width: 261px !important; */
  /* padding: 20px !important; */
  width: 100% !important;
}
.mdl-textfield {
  color: white !important;
  border: 1px solid #545454 !important;
  height: 64px !important;
  overflow: hidden !important;
  transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  border-radius: 4px !important;
  background-color: #2c2c2c !important;
}

.login-screen-label {
  color: #1bb243;
  font-weight: bold;
  margin: 24px;
  font-size: 20px;
}

.main-section-login-box {
  background-color: #1c1c1c !important; /* margin: auto; */
  border: 1px solid #dde0eb;
  border-radius: 6px;
  /* display: -webkit-flex; */
  /* display: flex; */
  -webkit-flex-direction: column;
  flex-direction: column;
  font-family: "Source Sans Pro";
  color: #80848c;
  font-size: 12px;
  /* position: relative; */
  width: 100% !important;
}

.mdl-textfield__input,
.firebaseui-input,
.firebaseui-id-email {
  height: 62px !important;
  width: 100% !important;
  background: #2c2c2c !important;
  border: 0px !important;
}

.firebaseui-textfield {
  color: white;
  border: 1px solid #545454 !important;
  height: 64px !important;
  overflow: hidden !important;
  transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  border-radius: 4px !important;
  background-color: #2c2c2c !important;
  line-height: 1.7em !important;
  letter-spacing: 0.00938em !important;
}

.mdl-textfield__label:after {
  background-color: #40ffe8 !important;
  bottom: 20px !important;
  content: "" !important;
  height: 2px !important;
  left: 45% !important;
  position: absolute !important;
  transition-duration: 0 !important;
  transition-timing-function: cubic-bezier(0, 0, 0, 0) !important;
  visibility: hidden !important;
  width: 10px !important;
}

.mdl-textfield__input,
.firebaseui-input,
.firebaseui-id-email {
  height: 48px !important;
  width: 100% !important;
  padding-left: 12px !important;
  background-color: #2c2c2c !important;
  border: 0px !important;
  caret-color: #9c9c9c !important;
}

.mdl-textfield__label,
.firebaseui-label {
  padding-left: 12px !important;
  color: #9c9c9c !important;
  font-weight: 800 !important;
}

.firebaseui-idp-list > .firebaseui-list-item {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

/* .MuiInputBase-multiline,
.MuiFilledInput-multiline {
  height: calc(100vh / 6.9) !important;
} */

.mdl-progress,
.mdl-js-progress,
.mdl-progress__indeterminate,
.frebaseui-busy-indicator,
.firebaseui-id-busy-in {
  display: none !important;
}

.firebaseui-idp-text.firebaseui-idp-text-long {
  display: none !important;
}

.firebaseui-title {
  visibility: hidden !important;
}

.firebaseui-container {
  max-width: 100% !important;
}

.firebaseui-idp-button,
.firebaseui-tenant-button {
  direction: ltr;
  font-weight: 500;
  height: auto;
  line-height: normal;
  max-width: 100% !important;
  border-radius: 20px !important;
}

.MuiFormControl-root {
  display: block !important;
}
.firebaseui-idp-icon {
  display: none !important;
}

.firebaseui-idp-github,
firebaseui-id-idp-button {
  background-color: #6371f6 !important;
}
