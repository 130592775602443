.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.relativePosition {
  position: relative;
}

.roomMainPage {
  height: -webkit-fill-available;
  overflow: hidden;
}

.loadingScreen {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: #222222;
  z-index: 100000;
  justify-content: center;
}

.loadingContent {
  display: flex;
  flex-direction: column;
  height: 270px;
  justify-content: center;
  align-items: center;
}

.loadingImage {
  background: #3f3f3e;
  position: relative;
  font-size: 20px;
  background: #222222;
  /* background-image: url(/loading.png); */
  background-size: 100%;
  height: 74px;
  width: 100%;
  max-width: 170px;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
}

.loadingText {
  color: white;
  font-family: quicksand;
  font-size: 20px;
  margin-bottom: 20px;
}

.rootContainer {
  bottom: 0px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.flexDisplay {
  display: flex;
  flex-direction: row;
  height: 100vh; /* Adjust based on your layout needs */
}

